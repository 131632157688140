import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { animated } from 'react-spring';
import Skeleton from 'react-loading-skeleton';
import mixpanel from 'mixpanel-browser';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import NetworkManager from '../classes/NetworkManager';

import { Container, Button, SecondaryButton, ErrorText } from './styledComponents';
import PostDetails, { MetaDataItem, DetailsSkeleton } from './postDetails';
import Modal from '../components/modal';
import InputField from '../components/inputField';
import TextArea from '../components/textArea';
import Icon from '../components/icon';
import BookSpine from '../assets/spine.svg';

const StyledContainer = styled(animated(Container))`
    padding: 60px 0;
    border-bottom: solid 2px var(--primary-highlight);
    display: flex;
    flex: 1 1 0;

    &:last-of-type {
        border-bottom: none;
    }

    @media (max-width: 700px) {
        padding: 30px 0;

        &:first-of-type {
            padding-top: 50px;
        }
    }
`;

const SmallStyledContainer = styled(Container)`
    padding: 20px 9px;
    border-bottom: solid 2px var(--primary-highlight);
    display: flex;
    flex: 1 1 0;

    &:last-of-type {
        border-bottom: none;
    }

    @media (max-width: 700px) {
        padding: 30px 0;

        &:first-of-type {
            padding-top: 50px;
        }
    }
`;

const Chapters = styled.p`
    color: var(--accent);
    display: block;
    font-weight: 600;
    font-size: 14px;
`;

const TitleContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
`;

const Title = styled.h2`
    font-size: 36px;
    font-weight: 600;
    margin: 10px 0;
    cursor: ${({ clickable }) => clickable ? 'pointer' : 'unset'};

    @media (max-width: 700px) {
        font-size: 24px;
    }
`;

const SmallTitle = styled.h2`
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
    color: var(--text);

    @media (max-width: 700px) {
        font-size: 16px;
    }
`;

const Synopsis = styled.p`
    line-height: 1.6;
    font-size: 18px;
    flex: 1 1 0;

    @media (max-width: 700px) {
        font-size: 16px;
    }
`;

const BookCoverContainer = styled.div`
    position: relative;
    overflow: hidden;
    margin-right: 30px;
    width: 150px;
    height: 220px;
    border-radius: 10px;
    box-shadow: var(--shadow);
    background: ${({ background, hasImage }) => {
        if (hasImage) {
            return `url(${background})`;
        } else if (background) {
            return `#${background}`;
        } else {
            return 'var(--accent-highlight)';
        }
    }};
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ clickable }) => clickable ? 'pointer' : 'unset'};

    p {
        font-weight: 600;
        font-size: 26px;
        font-family: Serif;
    }

    @media (max-width: 700px) {
        width: 100px;
        height: 150px;
        border-radius: 5px;
        margin-right: 15px;
    }
`;

const SmallBookCoverContainer = styled.div`
    position: relative;
    overflow: hidden;
    margin-right: 16px;
    width: 70px;
    height: 100px;
    border-radius: 5px;
    box-shadow: var(--shadow);
    background: ${({ background, hasImage }) => {
        if (hasImage) {
            return `url(${background})`;
        } else if (background) {
            return `#${background}`;
        } else {
            return 'var(--accent-highlight)';
        }
    }};
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-weight: 600;
        font-size: 20px;
        font-family: Serif;
        color: var(--text);
    }
`;

const BookDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 0;

    .react-loading-skeleton {
        margin-bottom: 5px;
    }
`;

const Menu = styled.div`
    position: absolute;
    top: 20px;
    right: 0;
    
    img {
        cursor: pointer;
    }
`;

const ModalItem = styled.div`
    padding: 10px 15px;
    font-weight: 600;
    color: var(--accent);
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--background-highlight);
    }

    img {
        margin-right: 10px;
    }
`;

const Delete = styled.span`
    color: red;
`;

const DeleteButton = styled(Button)`
    background-color: transparent;
    border-color: red;
    color: red;

    img {
        filter: none;
    }
`;

const OptionsContainer = styled.div`
    
`;

const OptionsTitle = styled.h3`
    font-weight: 600;
    font-size: 20px;
    padding: 24px 24px 10px 24px;
    margin: 10px 0;
`;

const OptionsModalItem = styled(ModalItem)`
    padding-left: 24px;
    padding-right: 24px;

    &:last-of-type {
        padding-bottom: 24px;
    }
`;

const ModalSubheading = styled.p`
    padding: 0 24px 0 24px;
`;

const BookSpineImg = styled.img`
    position: absolute;
    height: 100%;
    left: 0;
`;

const CreateBookForm = styled.form`
    padding: 32px;

    article {
        padding: 15px 0px 0px 0px;
    }

    @media (max-width: 700px) {
        padding: 20px
    }
`;

const CreateBookButton = styled(Button)`
`;

const ButtonContainer = styled.article`
    display: flex;
    align-items: center;
    padding: 15px 24px 24px 24px;

    button {
        height: 40px;
    }
`;

const DraggablesContainer = styled.div``;

const DraggableContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 18px 24px;
    background: white;
    border-radius: 10px;
    box-shadow: ${({ isDragging }) => isDragging ? 'var(--shadow)' : 'none'};

    img {
        margin-right: 20px;
    }
`;

const Chapter = styled.p`
    color: var(--accent);
    display: block;
    font-weight: 600;
    font-size: 14px;
`;

const ChapterTitle = styled.h3`
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
    padding-right: 35px;

    @media (max-width: 700px) {
        font-size: 18px;
    }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
//   background: isDragging ? "lightgreen" : "grey",
  position: 'static',

  // styles we need to apply on draggables
  ...draggableStyle
});

export const CondensedBookSkeleton = () => {
    const [delay, setDelay] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDelay(false);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    });

    return delay ? null : (
        <StyledContainer>
            <BookCoverContainer>
                <BookSpineImg src={BookSpine} />
            </BookCoverContainer>
            <BookDetails>
                <TitleContainer>
                    <Skeleton height={36} width={400} />
                </TitleContainer>
                <Skeleton height={22} count={3} width={600} />
                <DetailsSkeleton />
            </BookDetails>
        </StyledContainer>
    );
};

const CondensedBook = ({ id, authorPenName, authorId, authorSubscription, authorPicture, title, synopsis, genres, types, creationDate, chapters, chapterIds, creationDateTimeStamp, deleteBook, coverImageUrl, showMenu, isPublic, clickable, y, ...otherProps }) => {
    const history = useHistory();
    const [showMenuModal, setShowMenuModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [publicToggle, setPublicToggle] = useState(isPublic);
    const [showEditBookModal, setShowEditBookModal] = useState(false);
    const [showEditChapterOrderModal, setShowEditChapterOrderModal] = useState(false);
    const [richChapters, setRichChapters] = useState([]);

    const [newBookError, setNewBookError] = useState('');
    const [newBookTitle, setNewBookTitle] = useState(title);
    const [newBookSynopsis, setNewBookSynopsis] = useState(synopsis);

    const nm = new NetworkManager();

    const togglePublicStatus = () => {
        if (publicToggle) {
            nm.setBookToPrivate(id).then(() => setPublicToggle(false));
        } else {
            nm.setBookToPublic(id).then(() => setPublicToggle(true));
        }
    };

    const handleEditBookSubmit = (e) => {
        e.preventDefault();
        if (newBookTitle.length === 0) {
            setNewBookError('Your book must have a title');
        } else {
            nm.editBook({ id, title: newBookTitle, synopsis: newBookSynopsis }).then(() => {
                setShowEditBookModal(false);
            });
        }
    };

    const handleReorderBooks = () => {
        nm.updateBookPostOrder(id, richChapters).then(() => {
            setShowEditChapterOrderModal(false);
            setRichChapters([]);
        });
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            richChapters,
            result.source.index,
            result.destination.index
        );

        setRichChapters(items);
    };

    return (
        <StyledContainer
            style={{
                ...otherProps,
                // transform: y && y.interpolate(y => `translate3d(0, ${y}, 0)`)
            }}
        >
            {showDeleteModal && ReactDOM.createPortal(
                <Modal mWidth="450" mHeight="450" close={() => setShowMenuModal(false)}>
                    <OptionsContainer>
                        <OptionsTitle>Are you sure?</OptionsTitle>
                        <ModalSubheading>This is cannot be undone.</ModalSubheading>
                        <ButtonContainer>
                            <SecondaryButton onClick={() => setShowDeleteModal(false)}>Cancel</SecondaryButton>
                            <DeleteButton onClick={() => {
                                deleteBook(id);
                                setShowDeleteModal(false);
                            }}><Icon icon="delete" />Delete</DeleteButton>
                        </ButtonContainer>
                    </OptionsContainer>
                </Modal>,
                document.getElementById('root')
            )}
            {showMenuModal && ReactDOM.createPortal(
                <Modal mWidth="250" mHeight="450" close={() => setShowMenuModal(false)}>
                    <OptionsContainer>
                        <OptionsTitle>Options</OptionsTitle>
                        <OptionsModalItem onClick={() => {
                            setShowMenuModal(false);
                            setShowEditBookModal(true);
                        }}><Icon icon="book" />Edit book</OptionsModalItem>
                        { genres && genres.length > 0 && <OptionsModalItem onClick={() => {
                            nm.getBookPosts({ chapterIds }).then((res) => {
                                setRichChapters(res);
                                setShowMenuModal(false);
                                setShowEditChapterOrderModal(true);
                            });
                        }}><Icon icon="menu" />Edit chapter order</OptionsModalItem> }
                        <OptionsModalItem onClick={() =>{
                            togglePublicStatus();
                        }}><Icon icon={publicToggle ? 'eye' : 'eyeClosed'} />{publicToggle ? 'Change to private' : 'Change to public'}</OptionsModalItem>
                        <OptionsModalItem onClick={() => {
                            setShowMenuModal(false);
                            setShowDeleteModal(true);
                        }}><Icon icon="delete" /><Delete>Delete</Delete></OptionsModalItem>
                    </OptionsContainer>
                </Modal>,
                document.getElementById('root')
            )}
            {showEditBookModal && ReactDOM.createPortal(
                <Modal mWidth="550">
                    <CreateBookForm>
                        <InputField label="Book title" placeholder="Enter the book title" type="text" onChange={setNewBookTitle} value={newBookTitle} />
                        <TextArea label="Synopsis" placeholder="Enter the synopsis" type="text" onChange={setNewBookSynopsis} value={newBookSynopsis} />
                        { newBookError && <ErrorText><Icon icon="warning" />{newBookError}</ErrorText> }
                        <ButtonContainer>
                            <SecondaryButton onClick={() => setShowEditBookModal(false)}>Cancel</SecondaryButton>
                            <CreateBookButton onClick={handleEditBookSubmit} type="submit">Save</CreateBookButton>
                        </ButtonContainer>
                    </CreateBookForm>
                </Modal>,
                document.getElementById('root')
            )}
            {showEditChapterOrderModal && ReactDOM.createPortal(
                <Modal mWidth="550" noTransition>
                    <OptionsContainer>
                        <OptionsTitle>Change chapter order</OptionsTitle>
                    </OptionsContainer>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <DraggablesContainer
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {richChapters.sort((a, b) => a.bookPosition - b.bookPosition).map((richChapter, index) => (
                                        <Draggable key={richChapter.id} draggableId={richChapter.id} index={index}>
                                            {(provided, snapshot) => (
                                                <DraggableContainer
                                                    isDragging={snapshot.isDragging}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style,
                                                    )}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Icon icon="draggable" />
                                                    <div>
                                                        <Chapter>Chapter {index + 1} of {richChapter.bookTitle}</Chapter>
                                                        <ChapterTitle>{richChapter.title}</ChapterTitle>
                                                    </div>
                                                </DraggableContainer>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </DraggablesContainer>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <ButtonContainer>
                        <SecondaryButton onClick={() => setShowEditChapterOrderModal(false)}>Cancel</SecondaryButton>
                        <CreateBookButton onClick={handleReorderBooks} type="submit">Save</CreateBookButton>
                    </ButtonContainer>
                </Modal>,
                document.getElementById('root')
            )}
            { !coverImageUrl && <BookCoverContainer background={genres && genres.length > 0 && genres[0].color} onClick={() => {
                mixpanel.track('View book', { id });
                history.push(`/read/book/${id}`);
            }} clickable={clickable}>
                <BookSpineImg src={BookSpine} />
                <p>{title.split('')[0]}</p>
            </BookCoverContainer> }
            { coverImageUrl && <BookCoverContainer background={coverImageUrl} hasImage onClick={() => history.push(`/read/book/${id}`)} clickable={clickable}>
                <BookSpineImg src={BookSpine} />
            </BookCoverContainer> }
            <BookDetails>
                { chapters && <Chapters>{chapters} Chapters</Chapters> }
                <TitleContainer>
                    <Title onClick={() => history.push(`/read/book/${id}`)} clickable={clickable}>{newBookTitle || title}</Title>
                    { showMenu && <Menu>
                        <Icon icon="options" size={25} onClick={() => setShowMenuModal(true)} />
                    </Menu> }
                </TitleContainer>
                <Synopsis>{newBookSynopsis || synopsis}</Synopsis>
                <PostDetails metaItems={[
                    genres && genres.length > 0 && <MetaDataItem key={genres[0].name}>{genres[0].name}</MetaDataItem>,
                    types && types.length > 0 && <MetaDataItem key={types[0].pluralName}>{types[0].pluralName}</MetaDataItem>,
                    creationDateTimeStamp
                        ? <MetaDataItem key={creationDateTimeStamp}>{formatDistanceToNow(creationDateTimeStamp)} ago</MetaDataItem>
                        : <MetaDataItem key={creationDate}>{formatDistanceToNow(creationDate)} ago</MetaDataItem>
                ]} author={{ authorPenName, authorId, authorPicture }} />
            </BookDetails>
        </StyledContainer>
    );
};

export const SmallCondensedBook = ({ title, genres, types, creationDate, chapters, coverImageUrl }) => {
    return (
        <SmallStyledContainer>
            { !coverImageUrl && <SmallBookCoverContainer background={genres && genres.length > 0 && genres[0].color}>
                <BookSpineImg src={BookSpine} />
                <p>{title.split('')[0]}</p>
            </SmallBookCoverContainer> }
            { coverImageUrl && <SmallBookCoverContainer background={coverImageUrl} hasImage /> }
            <BookDetails>
                { chapters && <Chapters>{chapters} Chapters</Chapters> }
                <TitleContainer>
                    <SmallTitle>{title}</SmallTitle>
                </TitleContainer>
                <PostDetails metaItems={[
                    genres && <MetaDataItem key={genres[0].name}>{genres[0].name}</MetaDataItem>,
                    types && <MetaDataItem key={types[0].pluralName}>{types[0].pluralName}</MetaDataItem>,
                    <MetaDataItem key={creationDate}>{formatDistanceToNow(creationDate)} ago</MetaDataItem>
                ]} />
            </BookDetails>
        </SmallStyledContainer>
    );
};

export default CondensedBook;
