import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { useStore } from '../store';
import NetworkManager from '../classes/NetworkManager';

import Icon from './icon';

const Container = styled.div`
    background-color: var(--background);
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 10px 20px;
    box-sizing: border-box;
    // border-top: 1px solid var(--light-text);
    box-shadow: 0 0 15px 0 rgba(0,0,0,.15);
`;

const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;

    &:last-child {
        margin-right: 0;
    }
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ active }) => active ? 'var(--accent)' : 'var(--light-text)'};
    text-decoration: none;
    font-weight: 600;
    align-items: center;
    font-size: 12px;
    width: 66px;
    position: relative;

    img {
        filter: ${({ active }) => active ? 'auto' : 'invert(50%)'};
    }
`;

const NotificationCount = styled.p`
    background-color: red;
    font-size: 12px;
    font-weight: 800;
    position: absolute;
    top: -5px;
    right: 16px;
    color: var(--white);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
`;

const BottomNavBar = ({ hideText }) => {
    const { state } = useStore();
    const { pathname } = useLocation();
    const [notifications, setNotifications] = useState([]);
    const [notificationsRequested, setNotificationsRequested] = useState(false);

    useEffect(() => {
        const nm = new NetworkManager();
        if (notifications.length === 0 && !notificationsRequested) {
            nm.getNotifications({ user: state.user }).then((notificationList) => {
                setNotificationsRequested(true);
                setNotifications(notificationList);
            });
        }
    });

    return (
        <Container>
            <InnerContainer>
                <StyledLink to="/play" active={pathname === '/play' ? 1 : 0}><Icon icon="controller" size={26} />{!hideText && 'Play'}</StyledLink>
                <StyledLink to="/read" active={pathname === '/read' ? 1 : 0}><Icon icon="bookmark" size={14} />{ !hideText && 'Read'}</StyledLink>
                {/* <StyledLink to="/publish" active={pathname === '/publish' ? 1 : 0}><Icon icon="book" size={14} />{ !hideText && 'Publish'}</StyledLink> */}
                <StyledLink to="/write" active={pathname === '/write' ? 1 : 0}><Icon icon="pentip" />{ !hideText && 'Write'}</StyledLink>
                <StyledLink to="/notifications" active={pathname === '/notifications' ? 1 : 0}>
                    <Icon icon="bell" />
                    { notifications.filter((n) => !n.read).length > 0 && <NotificationCount>{notifications.filter((n) => !n.read).length}</NotificationCount> }
                    Notifications
                </StyledLink>
            </InnerContainer>
        </Container>
    );
};

export default BottomNavBar;
