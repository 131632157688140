import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useTrail } from 'react-spring';
import { Helmet } from 'react-helmet';
import { useStore } from '../store';

import NetworkManager from '../classes/NetworkManager';

import { Container, HeadingContainer, Heading, Button } from '../components/styledComponents';
import Modal from '../components/modal';

import GameTile from '../components/gameTile';
import Illustration from '../components/illustration';

const StyledHeadingContainer = styled(HeadingContainer)`
    display: block;
`;

const Subheading = styled.p`
    margin-top: 20px;
    font-size: 18px;
    line-height: 1.5;
`;

const GameGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 700px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const ModalContent = styled.div`
    padding: 40px;
    text-align: center;

    div {
        margin: 0;
    }

    img {
        width: 6rem;
        margin: 10px 0 0 0;
    }

    @media (min-width: 700px) {
        div {
            margin: 20px 0 10px 0;
        }

        img {
            width: 10rem;
            margin: 0;
        }
    }
`;

const ModalHeading = styled.h1`
    font-size: 1.4rem;
    font-weight: 600;

    @media (min-width: 700px) {
        font-size: 1.8rem;
    }
`;

const ModalMessage = styled.p`
    margin: 10px 0 30px;
    line-height: 1.4;
`;

const lessThanOneMinAgo = (date) => {
    const MIN = 60 * 60;
    const aMinAgo = Date.now() - MIN;

    return date > aMinAgo;
}

const Play = () => {
    const { state } = useStore();
    const [games, setGames] = useState([]);
    const [gamesRequested, setGamesRequested] = useState(false);
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [showMigrationModal, setShowMigrationModal] = useState(false);
    const [dismissedOnboarding, setDismissedOnboarding] = useState(false);

    const nm = new NetworkManager();

    const config = { mass: 1, tension: 120, friction: 14 };
    const gameTrail = useTrail(games.length, {
        config,
        from: { opacity: 0, y: '100%' },
        to: { opacity: 1, y: '0%' }
    });

    useEffect(() => {
        if (games.length === 0 && !gamesRequested) {
            nm.getGames().then((gamesList) => {
                setGamesRequested(true);
                setGames(gamesList);
            });
        }
        if (!dismissedOnboarding && nm.getUserDetails() && lessThanOneMinAgo(new Date(nm.getUserDetails().metadata.creationTime))) {
            setShowOnboardingModal(true);
            // nm.importContent({ user: state.user });
        }
    });

    return (
        <Container>
            <Helmet><title>Play</title></Helmet>
            {showOnboardingModal && ReactDOM.createPortal(
                <Modal mWidth="450" mHeight="700">
                    <ModalContent>
                        <ModalHeading>Welcome {state.user.penName}!</ModalHeading>
                        <Illustration illustration="idea" />
                        <ModalMessage>Thank you for joining the Orton community. If you are migrating your work then it will be with you in a minute or two, if your content doesn't migrate within an hour then please get in touch with our team.</ModalMessage>
                        <Button onClick={() => {
                            setDismissedOnboarding(true);
                            setShowOnboardingModal(false);
                        }}>Get started</Button>
                    </ModalContent>
                </Modal>,
                document.getElementById('root')
            )}
            <StyledHeadingContainer>
                <Heading>Play</Heading>
                <Subheading>Have fun and get better at writing. Our games are specifically designed to help motivation, focus, and creativity.</Subheading>
            </StyledHeadingContainer>
            <GameGrid>
                {games.length > 0 && gameTrail.map(({ ...styleProps }, i) => <GameTile key={games[i].id} {...games[i]} {...styleProps} />)}
            </GameGrid>
        </Container>
    );
};

export default Play;
