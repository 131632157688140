import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Container, HeadingContainer, Heading, StyledLink } from '../components/styledComponents';

const StyledHeadingContainer = styled(HeadingContainer)`
    display: block;
`;

const Subheading = styled.p`
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.5;
`;

const NoMatch = () => {
    return (
        <Container>
            <StyledHeadingContainer>
                <Heading>404</Heading>
                <Subheading>Sorry. We can't find the resource you're looking for.</Subheading>
                <StyledLink to="/read">Continue to Read</StyledLink>
            </StyledHeadingContainer>
        </Container>
    );
};

export default NoMatch;
