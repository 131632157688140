import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Icon from '../components/icon';

const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 25%;
    width: ${({ small }) => small ? '35px' : '50px'};
    height: ${({ small }) => small ? '35px' : '50px'};
    background-color: var(--accent);
    color: var(--background);
`;

const ProfileImage = styled.img`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 25%;
    width: ${({ small }) => small ? '35px' : '50px'};
    height: ${({ small }) => small ? '35px' : '50px'};
    background-color: var(--accent);
    color: var(--background);
    object-fit: cover;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    position: relative;
    display: block;
`;

const Initial = styled.p`
    font-weight: 600;
`;

const ProSealContainer = styled.div`
    position: absolute;
    bottom: -.5rem;
    right: -.4rem;
`;

const Wrapper = styled.div`
    position: relative;
`;

const Avatar = ({ small, initial, picture, authorId, subscription, disableLink }) => (
    !disableLink ? <StyledLink to={`/profile/${authorId}`}>
        { picture ?
        <ProfileImage src={picture} small={small} /> :
        <Container small={small}>
            <Initial>{initial}</Initial>
        </Container> }
        { subscription === 2 && <ProSealContainer>
            <Icon icon="proSeal" size={22} />
        </ProSealContainer> }
    </StyledLink> :
    <Wrapper>
        { picture ?
        <ProfileImage src={picture} small={small} /> :
        <Container small={small}>
            <Initial>{initial}</Initial>
        </Container> }
        { subscription === 2 && <ProSealContainer>
            <Icon icon="proSeal" size={22} />
        </ProSealContainer> }
    </Wrapper>
);

export default Avatar;
