import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { useStore } from '../store';
import NetworkManager from '../classes/NetworkManager';

import { HideOnMobile } from '../components/styledComponents';
import Avatar from './avatar';
import Icon from './icon';

const Container = styled.div`
    display: flex;
    align-items: center;

    &:last-child {
        margin-right: 0;
    }
`;

const StyledLink = styled(Link)`
    display: inline-flex;
    margin-right: 50px;
    color: ${({ active }) => active ? 'var(--accent)' : 'var(--light-text)'};
    text-decoration: none;
    font-weight: 600;
    align-items: center;
    position: relative;

    img {
        margin-right: 10px;
        filter: ${({ active }) => active ? 'auto' : 'invert(50%)'};
    }
`;

const NotificationCount = styled.p`
    background-color: red;
    font-size: 12px;
    font-weight: 800;
    position: absolute;
    top: -5px;
    left: 8px;
    color: var(--white);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
`;

const NavBar = () => {
    const { state } = useStore();
    const location = useLocation();
    const [notifications, setNotifications] = useState([]);
    const [notificationsRequested, setNotificationsRequested] = useState(false);

    useEffect(() => {
        const nm = new NetworkManager();
        if (notifications.length === 0 && !notificationsRequested) {
            nm.getNotifications({ user: state.user }).then((notificationList) => {
                setNotificationsRequested(true);
                setNotifications(notificationList);
            });
        }
    });

    return (
        <Container>
            <HideOnMobile><StyledLink to="/play" active={location.pathname === '/play' ? 1 : 0}><Icon icon="controller" size={26} />Play</StyledLink></HideOnMobile>
            <HideOnMobile><StyledLink to="/read" active={location.pathname === '/read' ? 1 : 0}><Icon icon="bookmark" size={14} />Read</StyledLink></HideOnMobile>
            {/* <HideOnMobile><StyledLink to="/publish" active={location.pathname === '/publish' ? 1 : 0}><Icon icon="book" size={14} />Publish</StyledLink></HideOnMobile> */}
            <HideOnMobile><StyledLink to="/write" active={location.pathname === '/write' ? 1 : 0}><Icon icon="pentip" />Write</StyledLink></HideOnMobile>
            <HideOnMobile>
                <StyledLink to="/notifications" active={location.pathname === '/notifications' ? 1 : 0}>
                    <Icon icon="bell" />
                    { notifications.filter((n) => !n.read).length > 0 && <NotificationCount>{notifications.filter((n) => !n.read).length}</NotificationCount> }
                    Notifications
                </StyledLink>
            </HideOnMobile>
            <Avatar authorId={state.user.id} initial={state.user.penName.split('')[0]} subscription={state.user.subscription} picture={state.user.picture} />
        </Container>
    );
};

export default NavBar;
