import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTrail } from 'react-spring';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';

import { Container, HeadingContainer, Heading, LinkSecondaryButton } from '../components/styledComponents';

import CondensedPost, { CondensedPostSkeleton } from '../components/condensedPost';
import CondensedBook, { CondensedBookSkeleton } from '../components/condensedBook';
import Toggle, { ToggleItem } from '../components/toggle';
import Dropdown, { DropdownHolder } from '../components/dropdown';
import Icon from '../components/icon';
import Illustration from '../components/illustration';

const SearchButton = styled(LinkSecondaryButton)`
    img {
        filter: none;
    }
`;

const FiltersContainer = styled.div`
    padding: 40px 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 700px) {
        flex-direction: column;
        padding: 10px 0;

        & > div {
            margin-top: 10px;
        }
    }
`;

const DropdownContainer = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 700px) {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: .5rem;
    }
`;

const Read = () => {
    const [posts, setPosts] = useState([]);
    const [books, setBooks] = useState([]);
    const [genres, setGenres] = useState([]);
    const [types, setTypes] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [typeToggle, setTypeToggle] = useState('chapters');
    const [feedToggle, setFeedToggle] = useState('discover');
    const [postsRequested, setPostsRequested] = useState(false);
    const [booksRequested, setBooksRequested] = useState(false);
    const [typesAndGenresRequested, setTypesAndGenresRequested] = useState(false);

    const nm = new NetworkManager();

    const config = { mass: 1, tension: 120, friction: 14 };
    const postTrail = useTrail(posts.length, {
        config,
        from: { opacity: 0, y: '100%' },
        to: { opacity: 1, y: '0%' }
    });
    const bookTrail = useTrail(books.length, {
        config,
        from: { opacity: 0, y: '100%' },
        to: { opacity: 1, y: '0%' }
    });

    useEffect(() => {
        if (typeToggle === 'chapters' && feedToggle === 'discover') {
            setPosts([]);
            nm.getPosts({ excludeGames: true, genre: selectedGenre === 'All' ? null : selectedGenre, type: selectedType === 'All' ? null : selectedType }).then((postList) => {
                setPostsRequested(true);
                setPosts(postList || []);
            });
        }
        if ((genres.length === 0 || types.length === 0) && !typesAndGenresRequested) {
            nm.getGenres().then((genreList) => {
                setGenres(genreList);
                return nm.getTypes();
            }).then((typeList) => {
                setTypesAndGenresRequested(true);
                setTypes(typeList);
            });
        }
        if (typeToggle === 'books' && feedToggle === 'discover') {
            setBooks([]);
            nm.getBooks({}).then((bookList) => {
                setBooksRequested(true);
                setBooks(bookList || []);
            });
        }
        if (feedToggle === 'following' && typeToggle === 'chapters') {
            setPosts([]);
            setPostsRequested(false);
            nm.getFollowingPosts(selectedGenre, selectedType).then((postList) => {
                setPostsRequested(true);
                setPosts(postList || []);
            });
        }
        if (feedToggle === 'following' && typeToggle === 'books') {
            setBooks([]);
            setBooksRequested(false);
            nm.getFollowingBooks(selectedGenre, selectedType).then((bookList) => {
                setBooksRequested(true);
                setBooks(bookList || []);
            });
        }
        if (feedToggle === 'bookmarks') {
            setPosts([]);
            setPostsRequested(false);
            nm.getBookmarks().then((postList) => {
                setPostsRequested(true);
                setPosts(postList || []);
            });
        }
    }, [feedToggle, typeToggle, selectedType, selectedGenre]);

    const handleFilterChange = (selection, changeType) => {
        if (changeType === 'type') {
            setSelectedType(selection);
            setPostsRequested(false);
            nm.getPosts({ excludeGames: true, genre: selectedGenre, type: selection }).then((postList) => {
                setPostsRequested(true);
                setPosts(postList || []);
            });
        } else if (changeType === 'genre') {
            setSelectedGenre(selection);
            setPostsRequested(false);
            nm.getPosts({ excludeGames: true, genre: selection, type: selectedType }).then((postList) => {
                setPostsRequested(true);
                setPosts(postList || []);
            });
        }
    };

    return (
        <Container>
            <Helmet><title>Read</title></Helmet>
            <HeadingContainer>
                <Heading>Read</Heading>
                <SearchButton to="/search"><Icon icon="search" />Search</SearchButton>
            </HeadingContainer>
            <FiltersContainer>
                <Toggle>
                    <ToggleItem active={typeToggle === 'chapters'} onClick={() => setTypeToggle('chapters')}>Writing</ToggleItem>
                    <ToggleItem active={typeToggle === 'books'} onClick={() => setTypeToggle('books')}>Books</ToggleItem>
                </Toggle>
                <Toggle>
                    <ToggleItem active={feedToggle === 'discover'} onClick={() => setFeedToggle('discover')}>Discover</ToggleItem>
                    <ToggleItem active={feedToggle === 'following'} onClick={() => setFeedToggle('following')}>Following</ToggleItem>
                    <ToggleItem active={feedToggle === 'bookmarks'} onClick={() => setFeedToggle('bookmarks')}>Bookmarks</ToggleItem>
                </Toggle>
                <DropdownContainer>
                    <DropdownHolder>
                        <Dropdown defaultItem="Genre" setOption={(selection) => handleFilterChange(selection, 'genre')} option={selectedGenre} options={['All', ...genres.map((genre) => genre.name)]} />
                    </DropdownHolder>
                    <DropdownHolder>
                        <Dropdown defaultItem="Type" setOption={(selection) => handleFilterChange(selection, 'type')} option={selectedType} options={['All', ...types.map((type) => type.pluralName)]} />
                    </DropdownHolder>
                </DropdownContainer>
            </FiltersContainer>
            {typeToggle === 'chapters' && posts.length > 0 && postTrail.map(({ ...styleProps }, i) => <CondensedPost key={posts[i].id} {...posts[i]} {...styleProps} />)}
            {typeToggle === 'books' && feedToggle !== 'bookmarks' && books.length > 0 && bookTrail.map(({ ...styleProps }, i) => <CondensedBook key={books[i].id} {...books[i]} {...styleProps} clickable />)}

            {typeToggle === 'chapters' && !postsRequested && Array(3).fill().map((i, k) => <CondensedPostSkeleton key={k} />)}
            {typeToggle === 'books' && !booksRequested && Array(3).fill().map((i, k) => <CondensedBookSkeleton key={k} />)}

            {typeToggle === 'chapters' && posts.length === 0 && postsRequested && feedToggle === 'discover' && <Illustration illustration="review" title="No writing" text="There is no writing for your specified filters." />}
            {typeToggle === 'chapters' && posts.length === 0 && postsRequested && feedToggle === 'following' && <Illustration illustration="review" title="No writing" text="None of the authors you follow have any writing." />}
            {typeToggle === 'books' && books.length === 0 && booksRequested && feedToggle === 'following' && <Illustration illustration="bookmarks" title="No books" text="None of the authors you follow have created any books." />}
            {posts.length === 0 && postsRequested && feedToggle === 'bookmarks' && typeToggle === 'chapters' && <Illustration size={100} illustration="bookmarks" title="No bookmarks" text="You do not have any writing bookmarked." />}
            {feedToggle === 'bookmarks' && typeToggle === 'books' && <Illustration illustration="bookmarks" title="No bookmarked books" text="You can't bookmark books yet." />}
        </Container>
    );
};

export default Read;
