import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.article`
    max-width: 760px;
    margin: 0 auto;
    padding: 0 10px 100px;
    box-sizing: border-box;
`;

export const HeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
`;

export const Heading = styled.h1`
    font-size: 50px;
    font-weight: 600;

    @media (max-width: 700px) {
        font-size: 32px;
    }
`;

export const Button = styled.button`
    border: 1px solid var(--accent);
    border-radius: 8px;
    background-color: var(--accent);
    color: var(--white);
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Whitney', Helvetica, Arial, sans-serif;
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    min-width: 120px;
    min-height: 34px;
    outline: none;
    transition: .3s ease-in-out;

    img {
        margin-right: 10px;
        filter: var(--filter-button);
    }

    &:hover {
        filter: brightness(0.9);
    }

    &:last-of-type {
        margin-right: 0;
    }
`;

export const SecondaryButton = styled.button`
    border: 1px solid var(--accent);
    border-radius: 8px;
    background-color: transparent;
    color: var(--accent);
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Whitney', Helvetica, Arial, sans-serif;
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    min-width: 120px;
    min-height: 34px;
    transition: .3s ease-in-out;

    img {
        margin-right: 10px;
        filter: var(--filter-button);
    }

    &:hover {
        filter: brightness(0.9);
    }

    &:last-of-type {
        margin-right: 0;
    }
`;

export const LinkButton = styled(Link)`
    border: 1px solid var(--accent);
    border-radius: 8px;
    background-color: var(--accent);
    color: var(--white);
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Whitney', Helvetica, Arial, sans-serif;
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    min-height: 34px;
    transition: .3s ease-in-out;

    img {
        margin-right: 10px;
        filter: var(--filter-button);
    }

    &:hover {
        filter: brightness(0.9);
    }

    &:last-of-type {
        margin-right: 0;
    }
`;

export const LinkSecondaryButton = styled(Link)`
    border: 1px solid var(--accent);
    border-radius: 8px;
    background-color: transparent;
    color: var(--accent);
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Whitney', Helvetica, Arial, sans-serif;
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    min-height: 34px;
    box-sizing: border-box;
    transition: .3s ease-in-out;

    img {
        margin-right: 10px;
        filter: var(--filter-button);
    }

    &:hover {
        filter: brightness(0.9);
    }

    &:last-of-type {
        margin-right: 0;
    }
`;

export const StyledLink = styled(Link)`
    font-weight: 600;
    color: var(--accent);
    text-decoration: none;
`;

export const ExternalLink = styled.a`
    font-weight: 600;
    color: var(--accent);
    text-decoration: none;
`;

export const HideOnMobile = styled.div`
    text-align: center;

    @media (max-width: 700px) {
        display: none;
    }
`;

export const HideOnDesktop = styled.div`
    text-align: center;

    @media (min-width: 700px) {
        display: none;
    }
`;

export const ErrorText = styled.p`
    color: red;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 20px 0 10px;

    img {
        margin-right: 5px;
    }
`;