import React, { useState, useEffect } from 'react';
import { useTrail } from 'react-spring';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';

import { Container, HeadingContainer, Heading } from '../components/styledComponents';
import Author, { AuthorSkeleton } from '../components/author';
import Illustration from '../components/illustration';

const Following = () => {
    const { authorId } = useParams();
    const [following, setFollowing] = useState([]);
    const [followingRequested, setFollowingRequested] = useState(false);

    const config = { mass: 1, tension: 120, friction: 14 };
    const followingTrail = useTrail(following.length, {
        config,
        from: { opacity: 0, y: '100%' },
        to: { opacity: 1, y: '0%' }
    });

    useEffect(() => {
        const nm = new NetworkManager();
        if (following.length === 0 && !followingRequested) {
            nm.getFollowing(authorId).then((followingList) => {
                setFollowingRequested(true);
                setFollowing(followingList.filter(f => f !== null));
            });
        }
    });

    return (
        <Container>
            <Helmet><title>Following</title></Helmet>
            <HeadingContainer>
                <Heading>Following</Heading>
            </HeadingContainer>
            {!followingRequested && Array(3).fill().map((i, k) => <AuthorSkeleton key={k} />)}
            {following.length > 0 && followingRequested && followingTrail.map(({ ...styleProps }, i) => (
                <React.Fragment key={following[i].id}>
                    <Author {...following[i]} {...styleProps} />
                </React.Fragment>
            ))}
            {following.length === 0 && followingRequested && <Illustration illustration="audio" title="Not following anyone" text="You're not following anyone at the moment." />}
        </Container>
    );
};

export default Following;
