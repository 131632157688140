import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    border-radius: .8rem;
    background: url(${({ background }) => background});
    box-shadow: var(--shadow);
    box-sizing: border-box;
    background-size: cover;
    cursor: pointer;
    width: 100%;
    margin: 10px 0 40px;
`;

const ColorLayer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    border-radius: .8rem;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: ${({ color }) => color ? `rgba(${color.r},${color.g},${color.b},.8)` : 'var(--primary-highlight)'};
    color: ${({ color }) => color ? 'var(--white)' : 'var(--text)'};
`;

const Benefit = styled.p`
    font-size: 12px;
    font-weight: 600;
`;

const Title = styled.h2`
    display: block;
    font-size: 24px;
    margin-top: .5rem;
`;

const Photo = styled.img`
    border-radius: 10px;
    max-width: 300px;
    margin-top: 20px;
`;

const Credits = styled.p`
    font-size: 14px;
    margin-top: .5rem;
    font-weight: 600;
    line-height: 1.2;
    color: var(--white);

    b {
        font-weight: 600;

        a {
            text-decoration: none;
            color: var(--white);
        }
    }
`;

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const ChapterGameTile = ({ name, color, imageUrl, flashWord, photo, scenario }) => {
    return (
        <Container background={imageUrl}>
            <ColorLayer color={hexToRgb(color)}>
                <div>
                    <Benefit>Made with {name}</Benefit>
                    { flashWord && <Title>Word: {flashWord}</Title> }
                    { photo && <Photo src={photo.response.urls.regular} alt={photo.response.alt_description} /> }
                    { photo && <Credits>Photo by <b><a href={photo.response.user.links.html}>{photo.response.user.name}</a> on <b>Unsplash</b></b></Credits> }
                </div>
            </ColorLayer>
        </Container>
    );
};

export default ChapterGameTile;
