class Genre {
    id = null;
    name = null;
    adjective = null;
    color = null;

    constructor({ id, name, adjective, color }) {
        this.id = id;
        this.name = name;
        this.adjective = adjective || null;
        this.color = color;
    }
};

export default Genre;