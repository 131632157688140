class Book {
    id = null;
    creationDate = null;
    title = null;
    synopsis = null;
    genres = null;
    types = null;
    authorId = null;
    authorPenName = null;
    authroPicture = null;
    authorSubscription = null;
    chapterIds = null;
    coverImageUrl = null;
    isPublic = null;

    constructor({
        id,
        creationDate,
        title,
        synopsis,
        genres,
        types,
        authorId,
        authorPenName,
        authorPicture,
        authorSubscription,
        chapterIds,
        coverImageUrl,
        public: isPublic
    }) {
        this.id = id;
        this.creationDate = creationDate;
        this.title = title;
        this.synopsis = synopsis;
        this.genres = genres;
        this.types = types;
        this.authorId = authorId;
        this.authorPenName = authorPenName;
        this.authorPicture = authorPicture;
        this.authorSubscription = authorSubscription;
        this.chapterIds = chapterIds;
        this.coverImageUrl = coverImageUrl;
        this.isPublic = isPublic;
    }
};

export default Book;