import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import algoliasearch from 'algoliasearch/lite';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';

import { Container, HeadingContainer, Heading, LinkSecondaryButton } from '../components/styledComponents';

import CondensedPost from '../components/condensedPost';
import CondensedBook from '../components/condensedBook';
import Author from '../components/author';
import Toggle, { ToggleItem } from '../components/toggle';
import Icon from '../components/icon';
import Illustration from '../components/illustration';

const client = algoliasearch('3CBLLN7K21', '876b5200dcac249655eecff2365571b7');
const indexPosts = client.initIndex('prod_POSTS');
const indexBooks = client.initIndex('prod_BOOKS');
const indexAuthors = client.initIndex('prod_AUTHORS');

const StyledHeadingContainer = styled(HeadingContainer)`
    flex-direction: column;
    align-items: flex-start;
`;

const FiltersContainer = styled.div`
    padding: 40px 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 700px) {
        flex-direction: column;
        padding: 10px 0;

        & > div {
            margin-top: 10px;
        }
    }
`;

const SearchField = styled.input`
    margin-top: 80px;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 30px;
    border-bottom: 2px solid var(--primary-highlight);
    padding-bottom: 30px;
    width: 100%;
    color: var(--text);

    &::placeholder {
        color: var(--placeholder);
    }
`;

const Search = () => {
    const [posts, setPosts] = useState([]);
    const [books, setBooks] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [searchPhase, setSearchPhase] = useState('');
    const [feedToggle, setFeedToggle] = useState('chapters');

    const handleSearchChange = (e) => {
        indexPosts.search(e.target.value).then(({ hits }) => {
            setPosts(hits);
        });
        indexBooks.search(e.target.value).then(({ hits }) => {
            setBooks(hits);
        });
        indexAuthors.search(e.target.value).then(({ hits }) => {
            setAuthors(hits);
        });
        setSearchPhase(e.target.value);
    };

    return (
        <Container>
            <Helmet><title>Search</title></Helmet>
            <StyledHeadingContainer>
                <Heading>Search</Heading>
                <SearchField placeholder="Your search phrase..." onChange={handleSearchChange} value={searchPhase} />
            </StyledHeadingContainer>
            <FiltersContainer>
                <Toggle>
                    <ToggleItem active={feedToggle === 'chapters'} onClick={() => setFeedToggle('chapters')}>Writing</ToggleItem>
                    <ToggleItem active={feedToggle === 'books'} onClick={() => setFeedToggle('books')}>Books</ToggleItem>
                    <ToggleItem active={feedToggle === 'authors'} onClick={() => setFeedToggle('authors')}>Authors</ToggleItem>
                </Toggle>
            </FiltersContainer>
            {searchPhase.length === 0 && <Illustration illustration="review" title="No search phrase" text="Type something into the field above to find what you are looking for." />}
            {feedToggle === 'chapters' && searchPhase.length > 0 && posts.map((post) => <CondensedPost key={post.id} {...post} />)}
            {feedToggle === 'chapters' && posts.length === 0 && searchPhase.length > 0 && <Illustration illustration="review" title="No writing" text="There is no writing matching your specified search." />}
            {feedToggle === 'books' && searchPhase.length > 0 && books.map((book) => (<CondensedBook key={book.id} {...book} clickable />))}
            {feedToggle === 'books' && books.length === 0 && searchPhase.length > 0 && <Illustration illustration="bookmarks" title="No books" text="There are no books matching your specified search." />}
            {feedToggle === 'authors' && searchPhase.length > 0 && authors.map((author) => (<Author key={author.id} {...author} />))}
            {feedToggle === 'authors' && authors.length === 0 && searchPhase.length > 0 && <Illustration illustration="collaboration" title="No authors" text="There are no authors matching your specified search." />}
        </Container>
    );
};

export default Search;
