import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

import { Container, Button, SecondaryButton } from './styledComponents';
import PostDetails, { MetaDataItem } from './postDetails';
import Modal, { ModalFooter } from '../components/modal';
import Icon from '../components/icon';

const StyledContainer = styled(Container)`
    padding: 60px 0;
    border-bottom: solid 2px var(--primary-highlight);
    display: flex;
    flex: 1 1 0;

    &:last-of-type {
        border-bottom: none;
    }

    @media (max-width: 700px) {
        padding: 30px 0;

        &:first-of-type {
            padding-top: 50px;
        }
    }
`;

const SmallStyledContainer = styled(Container)`
    padding: 20px 0;
    border-bottom: solid 2px var(--primary-highlight);
    display: flex;
    flex: 1 1 0;

    &:last-of-type {
        border-bottom: none;
    }

    @media (max-width: 700px) {
        padding: 30px 0;

        &:first-of-type {
            padding-top: 50px;
        }
    }
`;


const Chapters = styled.p`
    color: var(--accent);
    display: block;
    font-weight: 600;
    font-size: 14px;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled.h2`
    font-size: 26px;
    font-weight: 600;
    margin: 10px 0;
    cursor: pointer;

    @media (max-width: 700px) {
        font-size: 24px;
    }
`;

const SmallTitle = styled.h2`
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
    cursor: pointer;
    color: var(--text);

    @media (max-width: 700px) {
        font-size: 16px;
    }
`;

const Synopsis = styled.p`
    line-height: 1.6;
    font-size: 18px;
    flex: 1 1 0;

    @media (max-width: 700px) {
        font-size: 16px;
    }
`;

const BookCoverContainer = styled.div`
    margin-right: 30px;
    width: 150px;
    height: 220px;
    border-radius: 10px;
    background: var(--accent-highlight);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-weight: 600;
        font-size: 26px;
        padding: 0 10px;
        font-family: Serif;
    }

    @media (max-width: 700px) {
        width: 100px;
        height: 150px;
        border-radius: 5px;
        margin-right: 15px;
    }
`;

const SmallBookCoverContainer = styled.div`
    margin-right: 16px;
    width: 70px;
    height: 100px;
    border-radius: 5px;
    background: var(--accent-highlight);
`;

const BookDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 0;
`;

const Menu = styled.div`
    position: absolute;
    top: 20px;
    right: 0;
    
    img {
        cursor: pointer;
    }
`;

const ModalItem = styled.div`
    padding: 10px 15px;
    font-weight: 600;
    color: var(--accent);
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--background-highlight);
    }

    img {
        margin-right: 10px;
    }
`;

const Delete = styled.span`
    color: red;
`;

const DeleteButton = styled(Button)`
    background-color: transparent;
    border-color: red;
    color: red;

    img {
        filter: none;
    }
`;

const OptionsContainer = styled.div`
    
`;

const OptionsTitle = styled.h3`
    font-weight: 600;
    font-size: 20px;
    padding: 10px 15px;
    margin: 10px 0;
`;

const ModalSubheading = styled.p`
    padding: 0 15px 10px;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Tracker = styled.aside`
    background-color: var(--primary-highlight);
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
`;

const Breadcrumbs = styled.div`
    display: flex;
    margin-bottom: 12px;
    align-items: center;
`;

const BreadcrumbBranch = styled.div`
    height: 5px;
    background-color: ${({ completed }) => completed ? 'var(--accent)' : 'var(--light-text)'};
    flex: 1 1 0;
`;

const BreadcrumbMarker = styled.div`
    border-radius: 50%;
    background-color: ${({ completed }) => completed ? 'var(--accent)' : 'var(--light-text)'};
    width: 10px;
    height: 10px;
    transform: scale(1.2);
`;

const BreadcrumbTitles = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BreadcrumbTitle = styled.p`
    font-weight: 600;
    color: ${({ completed }) => completed ? 'var(--accent)' : 'var(--light-text)'};
`;

const titleMap = {
    1: 'Review your content',
    2: `You've approved your content`,
    3: 'We are creating your cover',
    4: 'Review your cover',
    5: 'You rejected your cover',
    6: 'You approved your cover',
    7: `We're uploading your book`,
    8: 'Your book is now published!',
    9: `Sorry we've rejected your book`
};

const descriptionMap = {
    1: 'Review and approve the format of your writing to move on to the cover design.',
    2: 'Check that your cover is how you want it to be.',
    3: 'You can find it on Amazon, Google Play & Apple Books.'
};

const PublishedBook = ({ id, title, creationDate, genre, type, authorId, authorPenName, authorSubscription, adult, backCoverImageUrl, frontCoverImageUrl, documentUrl, state, rejectionReason }) => {
    const history = useHistory();

    return (
        <StyledContainer>
            <BookCoverContainer>
                <p>{state === 1 && title.split('')[0]}</p>
            </BookCoverContainer>
            <BookDetails>
                <TitleContainer>
                    <Title>{titleMap[state]}</Title>
                </TitleContainer>
                <Synopsis>{descriptionMap[state]}</Synopsis>
                <Tracker>
                    <Breadcrumbs>
                        <BreadcrumbMarker completed />
                        <BreadcrumbBranch completed={state === 2} />
                        <BreadcrumbMarker completed={state === 3} />
                        <BreadcrumbBranch completed={state === 3} />
                        <BreadcrumbMarker />
                    </Breadcrumbs>
                    <BreadcrumbTitles>
                        <BreadcrumbTitle completed={state === 1}>Content</BreadcrumbTitle>
                        <BreadcrumbTitle completed={state === 2} >Cover</BreadcrumbTitle>
                        <BreadcrumbTitle>Publish</BreadcrumbTitle>
                    </BreadcrumbTitles>
                </Tracker>
            </BookDetails>
        </StyledContainer>
    );
};

export default PublishedBook;
