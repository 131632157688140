class CommunicationPreferences {
    email = null;
    push = null;
    pushTokens = [];

    constructor({ email, push, pushTokens }) {
        this.email = email;
        this.push = push;
        this.pushTokens = pushTokens;
    }
};

export default CommunicationPreferences;