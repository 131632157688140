import React from 'react';
import styled from 'styled-components';
import { Toggle as ReactToggle } from 'react-toggle-component';

const Toggle = styled.div`
    display: inline-flex;
    flex-direction: row;
    background-color: var(--primary-highlight);
    border-radius: 6px;
    margin-right: 10px;

    &:last-of-type {
        margin-right: 0;
    }

    @media (max-width: 700px) {
        margin-right: 0;
    }
`;

export const ToggleItem = styled.div`
    padding: 11px 18px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
    transition: .1s ease;
    background-color: ${({ active }) => active ? 'var(--background);' : 'transparent;'}
    border: ${({ active }) => active ? '1px solid var(--text);' : '1px solid transparent;'}

    @media (max-width: 700px) {
        flex: 1 1 0;
        text-align: center;
    }
`;

const ToggleLabel = styled.label`
    display: block;
    margin-top: 20px;
    width: 100%;
    background-color: var(--primary-highlight);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    padding: 20px 24px;
    box-sizing: border-box;
    align-items: center;
    line-height: 1.4;

    span {
        margin-left: 10px;
    }
`;

const ReToggle = styled(ReactToggle)`
    .react-toggle--checked .react-toggle-track {
        background-color: var(--accent);
    }
`;

export const RToggle = ({ text, htmlFor, onToggle, checked }) => (
    <ToggleLabel htmlFor={htmlFor}>
        {text}
        <ReToggle
            name="dark-mode-toggle"
            radius="16px"
            width="40px"
            radiusBackground="16px"
            knobRadius="50%"
            checked={checked}
            onToggle={onToggle}
            backgroundColor="var(--background)"
            borderColor="var(--accent)"
            knobColor="var(--accent)"
        />
    </ToggleLabel>
);

export default Toggle;
