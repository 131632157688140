import React from 'react';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { animated } from 'react-spring';
import mixpanel from 'mixpanel-browser';

import Avatar from './avatar';
import NetworkManager from '../classes/NetworkManager';

const StyledContainer = styled(animated.div)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1.2rem;
    background-color: ${({ isRead }) => isRead ? 'transparent' : 'var(--accent-highlight)'};
    border-radius: .8rem;
    cursor: pointer;
    margin-bottom: 2px;

    &:last-of-type {
        border-bottom: none;
    }
`;

const ContentContainer = styled.div`
    display: inline-flex;
    padding-left: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const Content = styled.h2`
    display: block;
    font-weight: 400;
    font-size: 16px;
`;

const Time = styled.p`
    font-weight: 600;
    font-size: 12px;
    color: var(--light-text);
    margin-top: .2rem;
`;

const ContentWrapper = styled.span`
`;

const Bold = styled.span`
    font-weight: 600;
`;

const Notification = ({ authorId, authorPenName, authorPicture, authorSubscription, message, creationDate, id, read, recipientId, type, postId, postTitle, annotationId, ...otherProps }) => {
    const history = useHistory();
    const nm = new NetworkManager();

    const getNotification = () => {
        switch(type) {
            case 'newFollower':
                return <ContentWrapper><Bold>{authorPenName}</Bold> followed you</ContentWrapper>;
            case 'postLike':
                return <ContentWrapper><Bold>{authorPenName}</Bold> liked "{postTitle}"</ContentWrapper>;
            case 'comment':
                return <ContentWrapper><Bold>{authorPenName}</Bold> commented on "{postTitle}"</ContentWrapper>;
            case 'commentLike':
                return <ContentWrapper><Bold>{authorPenName}</Bold> liked your comment</ContentWrapper>;
            case 'annotation':
                return <ContentWrapper><Bold>{authorPenName}</Bold> annotated "{postTitle}": <Bold>"{message}"</Bold></ContentWrapper>;
            case 'annotationLike':
                return <ContentWrapper><Bold>{authorPenName}</Bold> liked your annotation: "{message}"</ContentWrapper>;
            case 'newPost':
                return <ContentWrapper><Bold>{authorPenName}</Bold> just posted something new: "{postTitle}"</ContentWrapper>;
            default:
                return null;
        }
    };

    const getNotificationClick = () => {
        switch(type) {
            case 'newFollower':
                return () => {
                    nm.markNotificationAsRead(id).then(() => {
                        history.push(`/profile/${authorId}`);
                    });
                };
            case 'postLike':
                return () => {
                    nm.markNotificationAsRead(id).then(() => {
                        history.push(`/read/${postId}`);
                    });
                };
            case 'comment':
                return () => {
                    nm.markNotificationAsRead(id).then(() => {
                        history.push(`/read/${postId}?annotation=${annotationId}`);
                    });
                };
            case 'commentLike':
                return () => {
                    nm.markNotificationAsRead(id).then(() => {
                        history.push(`/read/${postId}?annotation=${annotationId}`);
                    });
                };
            case 'annotation':
                return () => {
                    nm.markNotificationAsRead(id).then(() => {
                        history.push(`/read/${postId}?annotation=${annotationId}`);
                    });
                };
            case 'annotationLike':
                return () => {
                    nm.markNotificationAsRead(id).then(() => {
                        history.push(`/read/${postId}?annotation=${annotationId}`);
                    });
                };
            case 'newPost':
                return () => {
                    nm.markNotificationAsRead(id).then(() => {
                        history.push(`/read/${postId}`);
                    });
                };
            default:
                return () => {};
        }
    };

    return (
        <StyledContainer
            style={{
                ...otherProps,
                // transform: y && y.interpolate(y => `translate3d(0, ${y}, 0)`)
            }}
            isRead={read}
            onClick={() => {
                mixpanel.track('View notification', { id });
                getNotificationClick()();
            }}
        >
            { authorPenName && <Avatar small initial={authorPenName.split('')[0]} picture={authorPicture} /> }
            <ContentContainer>
                <Content>{getNotification()}</Content>
                { creationDate && <Time>{formatDistanceToNow(new Date(creationDate))} ago</Time> }
            </ContentContainer>
        </StyledContainer>
    );
};

export default Notification;
