import { createGlobalStyle } from 'styled-components';

import WhitneyBookEot from './fonts/hinted-Whitney-Book.eot';
import WhitneyBookWoff from './fonts/hinted-Whitney-Book.woff';
import WhitneyBookTtf from './fonts/hinted-Whitney-Book.ttf';
import WhitneySemiBoldEot from './fonts/hinted-Whitney-SemiBold.eot';
import WhitneySemiBoldWoff from './fonts/hinted-Whitney-SemiBold.woff';
import WhitneySemiBoldTtf from './fonts/hinted-Whitney-SemiBold.ttf';
import WhitneyBlackEot from './fonts/hinted-Whitney-Black.eot';
import WhitneyBlackWoff from './fonts/hinted-Whitney-Black.woff';
import WhitneyBlackTtf from './fonts/hinted-Whitney-Black.ttf';

export const GlobalStyle = createGlobalStyle`
    :root {
        --text: ${({ theme }) => theme.text};
        --background: ${({ theme }) => theme.background};
        --background-highlight: ${({ theme }) => theme.backgroundHighlight};
        --modal-background: ${({ theme }) => `rgba(${theme.backgroundRGB}, .8)`};
        --primary: #5ce1d0;
        --accent: #1c65d2;
        --primary-highlight: rgba(92, 255, 208, .1);
        --accent-highlight: rgba(28, 101, 210, .1);
        --placeholder: ${({ theme }) => `rgba(${theme.textRGB}, .8)`};
        --button-text: ${({ theme }) => theme.background};
        --light-text: #757575;
        --white: #fff;
        --subscribe: #7c3d95;
        --subscribe-light: #C47EDF;
        --subscribe-pro: #1C0C22;
        --subscribe-pro-light: #322438;
        --shadow: 0 4px 15px 0 rgba(0,0,0,.1);
        --filter: brightness(0) invert(${({ theme }) => theme.invert});
        --filter-button: brightness(0) invert(${({ theme }) => theme.invertButton});
        --annotation-highlight: ${({ theme }) => theme.annotationHighlight};
    }

    @font-face {
        font-family: 'Whitney';
        src: url(${WhitneyBookEot});
        src: local('Whitney Book'), local('Whitney-Book'),
            url(${WhitneyBookWoff}) format('woff'),
            url(${WhitneyBookTtf}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Whitney';
        src: url(${WhitneySemiBoldEot});
        src: local('Whitney SemiBold'), local('Whitney-SemiBold'),
            url(${WhitneySemiBoldWoff}) format('woff'),
            url(${WhitneySemiBoldTtf}) format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Whitney';
        src: url(${WhitneyBlackEot});
        src: local('Whitney Black'), local('Whitney-Black'),
            url(${WhitneyBlackWoff}) format('woff'),
            url(${WhitneyBlackTtf}) format('truetype');
        font-weight: 900;
        font-style: normal;
    }

    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Whitney', Helvetica, Arial, sans-serif; }

    /* HTML5 display-role reset for older browsers */

    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block; }

    body {
    line-height: 1;
    font-family: 'Whitney', Helvetica, Arial, sans-serif; transition: all 0.50s linear; background: var(--background); color: var(--text); }

    h1, h2, h3, h4, h5, h6, p, input, textarea {
    font-family: 'Whitney', Helvetica, Arial, sans-serif; }

    textarea { box-sizing: border-box; }

    ol, ul {
    list-style: none; }

    blockquote, q {
    quotes: none; }

    blockquote {
    &:before, &:after {
        content: '';
        content: none; } }

    q {
    &:before, &:after {
        content: '';
        content: none; } }

    table {
    border-collapse: collapse;
    border-spacing: 0; }
`;