class Type {
    id = null;
    singularName = null;
    pluralName = null;

    constructor({ id, singularName, pluralName }) {
        this.id = id;
        this.singularName = singularName;
        this.pluralName = pluralName;
    }
};

export default Type;