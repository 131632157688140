class Notification {
    id = null;
    creationDate = null;
    read = null;
    authorId = null;
    authorPenName = null;
    authorSubscription = null;
    recipientId = null;
    type = null
    read = null;
    postId = null;
    postTitle = null;
    annotationId = null;
    message = null;

    constructor({ id, creationDate, authorId, authorPenName, authorSubscription, message, recipientId, type, read, postId, postTitle, annotationId }) {
        this.id = id;
        this.creationDate = creationDate;
        this.authorId = authorId;
        this.authorPenName = authorPenName;
        this.authorSubscription = authorSubscription;
        this.recipientId = recipientId;
        this.type = type;
        this.read = read;
        this.postId = postId;
        this.postTitle = postTitle;
        this.annotationId = annotationId;
        this.message = message;
    }
};

export default Notification;