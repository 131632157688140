import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    padding: 20px 24px;
    background-color: var(--primary-highlight);
    border-radius: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
    line-height: normal;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (max-width: 700px) {
        padding: 10px 14px;
    }
`;

const StyledLabel = styled.label`
    font-weight: 600;
    font-size: 18px;

    @media (max-width: 700px) {
        font-size: 16px;
    }
`;

const StyledInput = styled.textarea`
    width: 100%;
    background: 0 0;
    border: none;
    font-size: 18px;
    margin-top: 16px;
    padding: 0;
    outline: none;
    height: 100px;
    color: var(--text);
    resize: vertical;

    &::placeholder {
        color: var(--placeholder);
    }

    @media (max-width: 700px) {
        font-size: 16px;
        margin-top: 10px;
    }
`;

const TextArea = ({ label, placeholder, onChange, value, innerRef }) => (
    <Container>
        <StyledLabel>{label}</StyledLabel>
        <StyledInput placeholder={placeholder} onChange={(e) => onChange(e.target.value)} value={value} />
    </Container>
);

export default TextArea;
