import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';

import { Container, HeadingContainer } from '../components/styledComponents';

import CondensedBook from '../components/condensedBook';
import CondensedPost from '../components/condensedPost';
import Illustration from '../components/illustration';

const StyledContainer = styled(Container)`
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
`;

const Book = () => {
    const { bookId } = useParams();
    const history = useHistory();
    const [book, setBook] = useState({});
    const [posts, setPosts] = useState([]);
    const [bookRequested, setBookRequested] = useState(false);
    const [postsRequested, setPostsRequested] = useState(false);

    useEffect(() => {
        const nm = new NetworkManager();
        if (!bookRequested) {
            nm.getBook(bookId).then((doc) => {
                setBookRequested(true);
                setBook(doc);
            }).catch(() => {
                history.push('/404');
            });
        }
        if (posts.length === 0 && !postsRequested && bookRequested && book && book.id && book.chapterIds.length > 0) {
            nm.getBookPosts({ chapterIds: book.chapterIds }).then((postList) => {
                setPostsRequested(true);
                setPosts(postList);
            });
        }
    });

    return (
        <StyledContainer>
            { book && <Helmet><title>{book.title}</title></Helmet> }
            <HeadingContainer>
                { book && book.id && <CondensedBook {...book} /> }
            </HeadingContainer>
            {posts && posts.length > 0
                ? posts.sort((a, b) => a.bookPosition - b.bookPosition).map((post) => <CondensedPost key={post.id} {...post} />)
                : <Illustration illustration="review" title="No chapters" text="This book has no chapters yet." />}
        </StyledContainer>
    );
};

export default Book;
