import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';
import { useStore } from '../store';

import { Container, Heading, Button, StyledLink, HideOnDesktop, HideOnMobile } from '../components/styledComponents';
import InputField from '../components/inputField';
import GoogleButton from '../components/googleButton';

const Fullscreen = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 700px) {
        height: auto;
    }
`;

const SmallerContainer = styled(Container)`
    max-width: 540px;
    width: 100%;
    padding: 50px 10px 0;

    @media (min-width: 700px) {
        padding-top: 0;
    }
`;

const StyledHeading = styled(Heading)`
    margin-bottom: 30px;

    @media (max-width: 700px) {
        font-size: 36px;
    }
`;

const InformationalBanner = styled.div`
    padding: 20px;
    background-color: var(--accent-highlight);
    border-radius: 10px;
    margin-bottom: 20px;
`;

const Subheading = styled.p`
    font-size: 18px;
    line-height: 1.5;
`;

const Form = styled.form`
    margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
    width: 100%;
    font-size: 18px;
    min-height: 60px;
    margin-top: 20px;
`;

const FloatingLink = styled(StyledLink)`
    position: absolute;
    top: 34px;
    right: 24px;
`;

const FieldContainer = styled.div`
    position: relative;
`;

const ForgottenPasswordLink = styled(StyledLink)`
    position: absolute;
    top: 20px;
    right: 20px;

    @media (max-width: 700px) {
        top: 12px;
    }
`;

const SignIn = () => {
    const nm = new NetworkManager();
    const { dispatch } = useStore();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true);

    let loadingBuffer;

    useEffect(() => {
        loadingBuffer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => {
            clearTimeout(loadingBuffer);
        };
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        nm.login(email, password)
            .then((user) => {
                dispatch({ type: 'add_user', user });
            });
    };

    return (
        loading
            ? <Fullscreen></Fullscreen>
            : <Fullscreen>
                <Helmet><title>Sign in</title></Helmet>
                <HideOnMobile>
                    <FloatingLink to="/signup">Don't have an account? Sign up</FloatingLink>
                </HideOnMobile>
                <SmallerContainer>
                    <StyledHeading>Welcome back</StyledHeading>
                    <InformationalBanner>
                        <Subheading>Welcome to the new Orton. We have re-built Orton from the ground up to fix the issues you have had and get Orton ready for some big updates. Due to this you will need to <StyledLink to="/signup">sign up</StyledLink> again. Sign up with the same email address you used in the old Orton and your work will be automatically transferred over to the new Orton. We hope you love it.</Subheading>
                    </InformationalBanner>
                    <GoogleButton handleClick={() => {
                        nm.signInWithGoogle()
                            .then((user) => dispatch({ type: 'add_user', user }))
                    }} />
                    <Form onSubmit={handleSubmit}>
                        <InputField label="Email" placeholder="Enter your email address" type="email" onChange={setEmail} />
                        <FieldContainer>
                            <ForgottenPasswordLink to="/forgotten-password">Forgotten your password?</ForgottenPasswordLink>
                            <InputField label="Password" placeholder="Enter a password" type="password" onChange={setPassword} />
                        </FieldContainer>
                        <StyledButton type="submit">Continue with email</StyledButton>
                    </Form>
                    <HideOnDesktop style={{ marginBottom: '40px' }}>
                        <StyledLink to="/signup">Don't have an account? Sign up</StyledLink>
                    </HideOnDesktop>
                </SmallerContainer>
            </Fullscreen>
    );
};

export default SignIn;
