import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import Avatar from './avatar';

const Container = styled(animated.div)`
    display: flex;
    align-items: center;
    border-bottom: solid 2px var(--primary-highlight);
    padding: 20px 0;
    cursor: pointer;

    &:last-of-type {
        border-bottom: none;
    }
`;

const AuthorName = styled.h3`
    font-weight: 600;
    font-size: 18px;
    margin-left: 20px;
`;

export const AuthorSkeleton = () => {
    const [delay, setDelay] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDelay(false);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    });

    return delay ? null : (
        <Container>
            <Skeleton height={50} width={50} />
            <AuthorName><Skeleton height={18} width={100} /></AuthorName>
        </Container>
    );
};

const Author = ({ id, bio, name, penName, followers, following, subscription, email, likes, bookmarks, communicationPreferences, picture, ...otherProps }) => {
    const history = useHistory();
    return (
        <Container             
            style={{
                ...otherProps
            }}
            onClick={() => history.push(`/profile/${id}`)}
        >
            <Avatar initial={penName.split('')[0]} authorId={id} subscription={subscription} picture={picture} />
            <AuthorName>{penName}</AuthorName>
        </Container>
    );
};

export default Author;
