export const lightTheme = {
    text: '#191919',
    textRGB: '0,0,0',
    background: '#fff',
    backgroundHighlight: '#f1f1f1',
    backgroundRGB: '255,255,255',
    invert: '10%',
    invertButton: '100%',
    annotationHighlight: 'rgba(28, 101, 210, .1)'
};

export const darkTheme = {
    text: '#fff',
    textRGB: '255,255,255',
    background: '#191919',
    backgroundHighlight: '#222222',
    backgroundRGB: '25,25,25',
    invert: '100%',
    invertButton: '100%',
    annotationHighlight: 'rgba(28, 101, 210, .2)'
};