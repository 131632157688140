import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { addMinutes, addSeconds } from 'date-fns';

import { LinkButton } from '../components/styledComponents';

const Container = styled.div`
    border-radius: .8rem;
    background: url(${({ background }) => background});
    box-shadow: var(--shadow);
    box-sizing: border-box;
    background-size: cover;
    cursor: pointer;
    width: 100%;
    margin-top: 30px;
`;

const ColorLayer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    border-radius: .8rem;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: ${({ color }) => color ? `rgba(${color.r},${color.g},${color.b},.8)` : 'var(--primary-highlight)'};
    color: ${({ color }) => color ? 'var(--white)' : 'var(--text)'};

    @media (max-width: 700px) {
        padding: 1.6rem 1rem;
    }
`;

const Benefit = styled.p`
    font-size: 12px;
    font-weight: 600;
`;

const Title = styled.h2`
    display: block;
    font-weight: 600;
    font-size: 32px;
    margin-top: .5rem;
`;

const SmallTitle = styled.h2`
    display: block;
    font-weight: 600;
    font-size: 24px;
    margin-top: .5rem;
`;

const Photo = styled.img`
    border-radius: 10px;
    max-width: 300px;
    margin-top: 20px;
`;

const Credits = styled.p`
    font-size: 14px;
    margin-top: .5rem;
    font-weight: 600;
    line-height: 1.2;
    color: var(--white);

    b {
        font-weight: 600;

        a {
            text-decoration: none;
            color: var(--white);
        }
    }
`;

const TimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const Timer = styled.h3`
    font-size: 32px;
    font-weight: 600;
    min-width: 100px;
`;

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const NewGameTile = ({ name, color, imageUrl, limit, type, word, scenario, photo, setDisableEditing }) => {
    const [minutes, setMinutes] = useState('');
    const [seconds, setSeconds] = useState('');
    const [gameEnd, setGameEnd] = useState(false);
    const [end] = useState(addSeconds(addMinutes(new Date().getTime(), 10), 2));

    let timer;

    useEffect(() => {
        if (!timer && !gameEnd) {
            timer = setInterval(() => {
                const now = new Date().getTime();
                const diff = end - now;

                if (Math.floor(diff) <= 0) {
                    setMinutes('00');
                    setSeconds('00');
                    clearInterval(timer);
                    setGameEnd(true);
                    setDisableEditing(true);
                } else {
                    setMinutes(String(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0'));
                    setSeconds(String(Math.floor((diff % (1000 * 60)) / 1000)).padStart(2, '0'));
                }
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [end]);

    return (
        <Container background={imageUrl}>
            <ColorLayer color={hexToRgb(color)}>
                <div>
                    <Benefit>{name}</Benefit>
                    { word && <Title>{word.charAt(0).toUpperCase() + word.slice(1)}</Title> }
                    { scenario && <SmallTitle>{scenario}</SmallTitle> }
                    { photo && <Photo src={photo.response.urls.regular} alt={photo.response.alt_description} /> }
                    { photo && <Credits>Photo by <b><a href={photo.response.user.links.html}>{photo.response.user.name}</a> on <b>Unsplash</b></b></Credits> }
                </div>
                { limit && <TimerContainer>
                    <Benefit>Time remaining:</Benefit>
                    <Timer>{minutes}:{seconds}</Timer>
                </TimerContainer> }
            </ColorLayer>
        </Container>
    );
};

export default NewGameTile;
