import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
    background-color: var(--annotation-highlight);
    cursor: pointer;
    border-radius: 10px;
    padding: 4px 0 4.5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const Annotation = ({ id, clickHandler, annotation }) => (
    <StyledSpan id={id} onClick={() => clickHandler(annotation)}>{annotation.annotatedText}</StyledSpan>
);

export default Annotation;