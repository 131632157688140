import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import NetworkManager from '../classes/NetworkManager';
import { useStore } from '../store';

import { Container, Heading, Button, StyledLink, ExternalLink, HideOnDesktop, HideOnMobile } from '../components/styledComponents';
import InputField from '../components/inputField';
import GoogleButton from '../components/googleButton';

const Fullscreen = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 700px) {
        height: auto;
    }
`;

const InformationalBanner = styled.div`
    padding: 20px;
    background-color: var(--accent-highlight);
    border-radius: 10px;
    margin-bottom: 20px;
`;

const Subheading = styled.p`
    font-size: 18px;
    line-height: 1.5;
`;

const SmallerContainer = styled(Container)`
    max-width: 540px;
    width: 100%;
    padding: 50px 10px 0;

    @media (min-width: 700px) {
        padding-top: 0;
    }
`;

const StyledHeading = styled(Heading)`
    margin-bottom: 30px;

    @media (max-width: 700px) {
        font-size: 36px;
    }
`;

const Form = styled.form`

`;

const StyledButton = styled(Button)`
    width: 100%;
    font-size: 18px;
    min-height: 60px;
    margin-top: 20px;
`;

const Disclaimer = styled.p`
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    width: 100%;
    margin: 10px 0 30px;
`;

const FloatingLink = styled(StyledLink)`
    position: absolute;
    top: 34px;
    right: 24px;
`;

const SignUp = () => {
    const location = useLocation();
    const nm = new NetworkManager();
    const { dispatch } = useStore();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        nm.signUp(name, email, password)
            .then((user) => {
                dispatch({ type: 'add_user', user });
            });
    };

    useEffect(() => {
        const prePopEmail = new URLSearchParams(location.search).get('email');
        if (prePopEmail) {
            setEmail(prePopEmail);
        }
    }, []);

    return (
        <Fullscreen>
            <Helmet><title>Sign up</title></Helmet>
            <HideOnMobile>
                <FloatingLink to="/signin">Already have an account? Sign in</FloatingLink>
            </HideOnMobile>
            <SmallerContainer>
                <StyledHeading>Sign up</StyledHeading>
                    <InformationalBanner>
                        <Subheading>Sign up with the same email address you used in the old Orton and your work will be automatically transferred over to the new Orton. We hope you love it.</Subheading>
                    </InformationalBanner>
                <GoogleButton handleClick={() => {
                    nm.signUpWithGoogle()
                        .then((user) => dispatch({ type: 'add_user', user }))
                }} text="Sign up with Google" />
                <Form onSubmit={handleSubmit}>
                    <InputField label="Name" placeholder="Enter your name" onChange={setName} value={name} />
                    <InputField label="Email" placeholder="Enter your email address" type="email" onChange={setEmail} value={email} />
                    <InputField label="Password" placeholder="Enter a password" type="password" onChange={setPassword} value={password} />
                    <StyledButton type="submit">Sign up with email</StyledButton>
                    <Disclaimer>By signing up you agree to our <ExternalLink target="_blank" href="https://www.iubenda.com/privacy-policy/7821898">privacy policy</ExternalLink> and <ExternalLink target="_blank" href="https://my.orton.io/terms-and-conditions">terms of service</ExternalLink>.</Disclaimer>
                </Form>
                <HideOnDesktop style={{ marginBottom: '40px' }}>
                    <StyledLink to="/signin">Already have an account? Sign in</StyledLink>
                </HideOnDesktop>
            </SmallerContainer>
        </Fullscreen>
    );
};

export default SignUp;
