import React from 'react';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';

import { Container } from '../components/styledComponents';
import PostDetails, { MetaDataItem } from '../components/postDetails';
import Icon from '../components/icon';

const StyledContainer = styled(Container)`
    padding-bottom: 30px;
    border-bottom: px solid var(--primary-highlight);
`;

const Text = styled.p`
    font-size : 16px;
    margin-top: 20px;
`;

const Reply = ({ id, creationDate, message, authorId, authorPenName, authorPicture, authorSubscription, postId, likeIds, likes, annotationId }) => {
    return (
        <StyledContainer>
            <PostDetails metaItems={[
                <MetaDataItem key={creationDate}>{formatDistanceToNow(new Date(creationDate))} ago</MetaDataItem>
            ]} author={{ authorPenName, authorId, authorPicture }} />
            <Text>{message}</Text>
        </StyledContainer>
    );
};

export default Reply;