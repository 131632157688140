import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';
import { useStore } from '../store';

import { Container, HeadingContainer, Heading, Button, SecondaryButton, LinkButton, ErrorText } from '../components/styledComponents';

import CondensedPost, { CondensedPostSkeleton } from '../components/condensedPost';
import CondensedBook, { CondensedBookSkeleton } from '../components/condensedBook';
import Toggle, { ToggleItem } from '../components/toggle';
import Icon from '../components/icon';
import Modal, { ModalFooter } from '../components/modal';
import InputField from '../components/inputField';
import TextArea from '../components/textArea';
import Illustration from '../components/illustration';

const StyledHeadingContainer = styled(HeadingContainer)`
    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;

        div {
            margin-top: 20px;
        }
    }
`;

const StyledLinkButton = styled(LinkButton)`
    padding: 0 1rem;

    &:last-of-type {
        margin-right: 8px;
    }
`;

const StyledButton = styled(Button)`
    padding: 0 1rem;
`;

const FiltersContainer = styled.div`
    padding: 40px 0;

    @media (max-width: 700px) {
        padding: 10px 0;
    }
`;

const CreateBookForm = styled.form`
    padding: 32px;

    @media (max-width: 700px) {
        padding: 20px
    }
`;

const CreateBookButton = styled(Button)`
`;

const ButtonContainer = styled.div`
    text-align: right;
`;

const trunc = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...'
};

const Write = () => {
    const { state } = useStore();
    const [posts, setPosts] = useState([]);
    const [books, setBooks] = useState([]);
    const [typeToggle, setTypeToggle] = useState('chapters');
    const [postsRequested, setPostsRequested] = useState(false);
    const [booksRequested, setBooksRequested] = useState(false);
    const [showCreateBookModal, setShowCreateBookModal] = useState(false);
    const [showBookModal, setShowBookModal] = useState(false);
    const [selectedBook, setSelectedBook] = useState(null);

    const [newBookError, setNewBookError] = useState('');
    const [newBookTitle, setNewBookTitle] = useState('');
    const [newBookSynopsis, setNewBookSynopsis] = useState('');

    const nm = new NetworkManager();

    useEffect(() => {
        if (typeToggle === 'chapters' && posts.length === 0 && !postsRequested) {
            nm.getPosts({ user: state.user, publicPosts: false }).then((postList) => {
                setPostsRequested(true);
                setPosts(postList || []);
            });
        }
        if (books.length === 0 && !booksRequested) {
            nm.getBooks({ user: state.user }).then((bookList) => {
                setBooksRequested(true);
                setBooks(bookList || []);
            });
        }
    });

    const handleBookSubmit = (e) => {
        e.preventDefault();
        if (newBookTitle.length === 0) {
            setNewBookError('Your book must have a title');
        } else {
            nm.createBook({ user: state.user, title: newBookTitle, synopsis: newBookSynopsis }).then((newBook) => {
                const newBooks = [...books];
                newBooks.unshift(newBook);
                setBooks(newBooks);
                setShowCreateBookModal(false);
            });
        }
    };

    const handleDeletePost = (id) => {
        nm.deletePost(id)
            .then(() => {
                const index = posts.findIndex(post => post.id === id);
                const newPosts = [...posts];
                newPosts.splice(index, 1);
                setPosts(newPosts);
            });
    };

    const handleDeleteBook = (id) => {
        nm.deleteBook(id)
            .then(() => {
                const index = books.findIndex(book => book.id === id);
                const newBooks = [...books];
                newBooks.splice(index, 1);
                setBooks(newBooks);
            });
    };

    return (
        <Container>
            <Helmet><title>Write</title></Helmet>
            {showCreateBookModal && ReactDOM.createPortal(
                <Modal mWidth="550">
                    <CreateBookForm>
                        <InputField label="Book title" placeholder="Enter the book title" type="text" onChange={setNewBookTitle} />
                        <TextArea label="Synopsis" placeholder="Enter the synopsis" type="text" onChange={setNewBookSynopsis} />
                        { newBookError && <ErrorText><Icon icon="warning" />{newBookError}</ErrorText> }
                        <ButtonContainer>
                            <SecondaryButton onClick={() => setShowCreateBookModal(false)}>Cancel</SecondaryButton>
                            <CreateBookButton onClick={handleBookSubmit} type="submit">Create book</CreateBookButton>
                        </ButtonContainer>
                    </CreateBookForm>
                </Modal>,
                document.getElementById('root')
            )}
            <StyledHeadingContainer>
                <Heading>Write</Heading>
                <div>
                    <StyledLinkButton to="/new"><Icon icon="pentip" />New writing</StyledLinkButton>
                    <StyledButton onClick={() => setShowCreateBookModal(true)}><Icon icon="book" size={14} />New book</StyledButton>
                </div>
            </StyledHeadingContainer>
            <FiltersContainer>
                <Toggle>
                    <ToggleItem active={typeToggle === 'chapters'} onClick={() => setTypeToggle('chapters')}>Writing</ToggleItem>
                    <ToggleItem active={typeToggle === 'books'} onClick={() => setTypeToggle('books')}>Books</ToggleItem>
                </Toggle>
            </FiltersContainer>
            {typeToggle === 'chapters' && posts && posts.length > 0 && posts.map((post) => <CondensedPost key={post.id} {...post} showMenu deletePost={handleDeletePost} books={books} />)}
            {typeToggle === 'chapters' && posts && posts.length === 0 && postsRequested && <Illustration illustration="review" title="No writing" text="You haven't added any writing yet." />}
            {typeToggle === 'books' && books && books.length > 0 && books.map((book) => (<CondensedBook key={book.id} {...book} showMenu deleteBook={handleDeleteBook} refreshBooks={() => setBooksRequested(false)} clickable />))}
            {typeToggle === 'books' && books && books.length === 0 && booksRequested && <Illustration size={100} illustration="bookmarks" title="No books" text="You haven't added any books yet." />}

            {typeToggle === 'chapters' && !postsRequested && Array(3).fill().map((i, k) => <CondensedPostSkeleton key={k} />)}
            {typeToggle === 'books' && !booksRequested && Array(3).fill().map((i, k) => <CondensedBookSkeleton key={k} />)}
        </Container>
    );
};

export default Write;
