import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import Avatar from './avatar';

const PostDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 26px;
`;

const PostDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Author = styled.h3`
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 3px;
`;

const MetaData = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li + li::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin: auto 6px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--light-text);
    }
`;

const AvatarContainer = styled.div`
    margin-right: 10px;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: var(--text);
`;

export const MetaDataItem = styled.li`
    color: var(--light-text);
    font-size: 12px;
    font-weight: 600;
    display: flex;

    &:first-of-type {
        margin-left: 0;
    }
`;

export const DetailsSkeleton = () => (
    <PostDetailsContainer>
        <AvatarContainer>
            <Skeleton height={35} width={35} />
        </AvatarContainer>
        <PostDetails>
            <Skeleton height={14} width={150} />
            <Skeleton height={12} width={200} />
        </PostDetails>
    </PostDetailsContainer>
);

const Details = ({ metaItems, author }) => {
    return (
        <PostDetailsContainer>
            { author && <AvatarContainer>
                <Avatar small initial={(author && author.authorPenName) ? author.authorPenName.split('')[0] : ''} authorId={author.authorId} subscription={author.subscription} picture={author.authorPicture} />
            </AvatarContainer> }
            <PostDetails>
                { author && <StyledLink to={`/profile/${author.authorId}`}><Author>{author.authorPenName}</Author></StyledLink> }
                <MetaData>
                    {metaItems}
                </MetaData>
            </PostDetails>
        </PostDetailsContainer>
    );
};

export default Details;
