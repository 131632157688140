import React, { useState } from 'react';
import styled from 'styled-components';

import Icon from './icon';

const DropdownContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    background-color: var(--background);
    border-radius: 6px;
    border: 1px solid var(--text);
    position: absolute;
    width: 150px;
    max-height: 240px;
    overflow-y: scroll;
    z-index: 20;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 700px) {
        width: 46%;
    }
`;

const DropdownItem = styled.div`
    padding: 10px 24px 10px 18px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    max-width: 150px;
    font-family: 'Whitney', Helvetica, Arial, sans-serif;
    border-radius: 6px;
    background-color: ${({ active }) => active ? 'var(--background);' : 'transparent;'}
    border: ${({ active }) => active ? '1px solid var(--text);' : '1px solid transparent;'}
    box-sizing: border-box;
    
    &:hover {
        background-color: var(--primary-highlight);
    }

    @media (max-width: 700px) {
        width: 100%;
    }
`;

const IconContainer = styled.div`
    position: absolute;
    right: .6rem;
    top: .45rem;
    cursor: pointer;
    
    img {
        transition: .3s ease;
        filter: var(--filter);
        transform: ${({ showOptions }) => showOptions ? 'rotate(-180deg)' : 'none'};
    }
`;

export const DropdownHolder = styled.div`
    display: flex;
    width: 150px;
    margin-right: 10px;

    &:last-of-type {
        margin-right: 0;
        flex: 1 1 0;
    }

    @media (max-width: 700px) {
        width: auto;
        margin-right: 0;

        &:last-of-type {
            flex: auto;
        }
    }
`;

const Dropdown = ({ defaultItem, options = [], setOption, option }) => {
    const [showOptions, setShowOptions] = useState(false);

    return (
        <DropdownContainer onClick={() => setShowOptions(!showOptions)}>
            <IconContainer showOptions={showOptions}><Icon icon="chevron" /></IconContainer>
            <DropdownItem>{option || defaultItem}</DropdownItem>
            {showOptions && options.map((item) => <DropdownItem key={item} onClick={() => setOption(item)}>{item}</DropdownItem>)}
        </DropdownContainer>
    );
};

export default Dropdown;
