import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

import Icon from '../components/icon';

const StyledToast = styled(animated.div)`
    position: relative;
    border-radius: 10px;
    background: #ffd166;
    box-shadow: var(--shadow);
    position: fixed;
    right: 100px;
    bottom: 20px;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    z-index: 999;
    max-width: 70%;

    &::-webkit-scrollbar {
        display: none;
    }

    img {
        margin-right: 8px;
        filter: brightness(0);
    }
`;

const Text = styled.p`
    font-size: 18px;
    font-weight: 600;
    color: #191919;
`;

const Toast = ({ message }) => {
    const [toastVisible] = useState(true);
    const transitions = useTransition(toastVisible, null, {
        from: { opacity: 0, transform: "translateY(50px)" },
        enter: { opacity: 1, transform: "translateY(0px)" },
        leave: { opacity: 0, transform: "translateY(50px)" }
    });

    return (
        transitions.map(({ item, key, props: style }) => (
            item && <StyledToast key={key} style={style}>
                <Icon icon="warning" size={22} />
                <Text>{message}</Text>
            </StyledToast>
        ))
    );
};

export default Toast;