let themeFunction;
let getThemeFunction;

export const setThemeFunction = (hook) => themeFunction = hook;
export const setTheme = (theme) => themeFunction(theme);
export const setGetThemeFunction = (hook) => getThemeFunction = hook;
export const getTheme = () => getThemeFunction();

export const trunc = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...'
};
