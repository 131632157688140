import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { Editor, EditorState, convertFromRaw, CompositeDecorator, Modifier } from 'draft-js';
import { Helmet } from 'react-helmet';

import 'draft-js/dist/Draft.css';

import NetworkManager from '../classes/NetworkManager';
import { useStore } from '../store';

import { Container, Button, SecondaryButton, ErrorText } from '../components/styledComponents';
import Avatar from '../components/avatar';
import ChapterGameTile from '../components/chapterGameTile';
import PostDetails, { MetaDataItem } from '../components/postDetails';
import TextArea from '../components/textArea';
import Comment from '../components/comment';
import Reply from '../components/reply';
import Modal, { ModalButtonContainer, ModalFooter } from '../components/modal';
import Annotation from '../components/annotation';
import Follow from '../components/follow';
import Icon from '../components/icon';
import Toast from '../components/toast';

const StyledContainer = styled(Container)`
    margin-top: 40px;
    padding-bottom: 80px;

    & > .DraftEditor-root {
        font-size: 18px;
        margin-bottom: 50px;
        line-height: 1.56;
        color: transparent;
        text-shadow: 0 0 0 var(--text);

        @media (max-width: 700px) {
            font-size: 16px;
        }
    }

    .public-DraftStyleDefault-block {
        margin-bottom: 20px;

        span {
            &::selection {
                color: var(--text);
                background: var(--annotation-highlight);
            }
            &::-moz-selection {
                color: var(--text);
                background: var(--annotation-highlight);
            }
        }
    }
`;

const Title = styled.h1`
    width: 100%;
    font-size: 48px;
    padding-right: 30px;
    display: block;
    margin: 20px 0;
    font-weight: 600;
    color: var(--text);
    box-sizing: border-box;

    @media (max-width: 700px) {
        font-size: 30px;
    }
`;

const AuthorContainer = styled.aside`
    margin-bottom: 50px;

    button {
        margin-top: 20px;
    }
`;

const AuthorDetails = styled.div`
    margin-left: 20px;
`;

const AuthorPenName = styled.h4`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
`;

const Content = styled.p`
    line-height: 38px;
    margin: -5px auto 80px;
    display: block;
    font-size: 18px;
    line-height: 1.56;
    text-align: left;
    cursor: text;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    width: 100%;
    white-space: pre-wrap;
`;

const Form = styled.form`
    margin-bottom: 80px;

    button {
        display: inline-block;
        float: right;
        margin-top: 10px;
    }
`;

const ModalContent = styled.div`
    padding: 20px 20px 10px;
`;

const SelectedAnnotationText = styled.p`
    background-color: var(--annotation-highlight);
    padding: 20px 24px;
    border-radius: 5px;
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.4;
`;

const SAModalFooter = styled(ModalFooter)`
    flex-direction: column;
`;

const ButtonGroup = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SocialButtons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;

    & > div {
        margin-right: .4rem;
    }
`;

const Chapter = styled.p`
    color: var(--accent);
    display: block;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
`;

const compare = (a, b) => a.startId - b.startId;

const computeReadTime = (content) => {
  const matches = content.match(/\S+/g);
  const words = matches ? matches.length : 0;
  const time = Math.ceil(words / 200);
  return `${time} ${time == 1 ? 'min' : 'mins'}`;
};

const isSelectionOverlapping = (annotations, startOffset, endOffset, block) => {
    let overlapping = false;

    annotations.forEach((annotation) => {
        const { startId, endId, blockId } = annotation;
        if (blockId === block) {
            if (startOffset < startId && endOffset > endId) {
                overlapping = true;
            }
            if (startOffset < startId && endOffset > startId) {
                overlapping = true;
            }
            if (startOffset < endId && endOffset > endId) {
                overlapping = true;
            }
            if (startOffset > startId && endOffset < endId) {
                overlapping = true;
            }
        }
    });

    return overlapping;
};

const getEntityStrategy = (mutability) => {
    return (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                if (entityKey === null) {
                    return false;
                }
                return contentState.getEntity(entityKey).getMutability() === mutability;
            },
            callback
        );
    };
};

const StyledAnnotationSpan = styled.span`
    background-color: var(--annotation-highlight);
    border-radius: 4px;
    padding: 4px 0;
    cursor: pointer;
`;

const editorStyleMap = {
    'BOLD': {
        fontWeight: 600
    }
};

const ReadChapter = () => {
    const { chapter } = useParams();
    const [location, setLocation] = useState(new URLSearchParams(useLocation().search));
    const history = useHistory();
    const { state } = useStore();
    const [post, setPost] = useState(null);
    const [author, setAuthor] = useState(null);
    const [annotations, setAnnotations] = useState([]);
    const [comments, setComments] = useState([]);
    const [replies, setReplies] = useState([]);
    const [postRequested, setPostRequested] = useState(false);
    const [authorRequested, setAuthorRequested] = useState(false);
    const [annotationsRequested, setAnnotationsRequested] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [newAnnotation, setNewAnnotation] = useState(null);
    const [newAnnotationMessage, setNewAnnotationMessage] = useState('');
    const [newAnnotationReply, setNewAnnotationReply] = useState('');
    const [showNewAnnotationModal, setShowNewAnnotationModal] = useState(false);
    const [viewAnnotation, setViewAnnotation] = useState(null);
    const [viewComment, setViewComment] = useState(null);
    const [selectedAnnotation, setSelectedAnnotation] = useState(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [newCommentError, setNewCommentError] = useState('');
    const [newAnnotationError, setNewAnnotationError] = useState('');
    const [showNewAnnotationWarning, setShowNewAnnotationWarning] = useState(null);
    const [isGrammarlyDisabled, setIsGrammarlyDisabled] = useState(false);
    const MAX_ANNOTATION_LENGTH = 100;

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const nm = new NetworkManager();
    let toastTimer;
    let grammarlyObserver;

    const editorRef = useRef();

    const AnnotationSpan = ({ entityKey, children }) => (
        <StyledAnnotationSpan
            onClick={() => {
                const annotationId = editorState.getCurrentContent().getEntity(entityKey).getData().id;
                setViewAnnotation(annotationId);
                nm.getAnnotationReplies(chapter, annotationId)
                    .then((replyList) => {
                        setReplies(replyList);
                    });
            }}>
            {children}
        </StyledAnnotationSpan>);

    const decorator = new CompositeDecorator([{
        strategy: getEntityStrategy('IMMUTABLE'),
        component: AnnotationSpan
    }]);

    const submitComment = (e) => {
        e.preventDefault();
        if (newComment.length === 0) {
            setNewCommentError('Your comment is empty');
        } else {
            nm.createComment(post.id, newComment, state.user)
                .then((comment) => {
                    const newCommentsList = [...comments];
                    newCommentsList.unshift(comment);
                    setComments(newCommentsList);
                    setNewComment('');
                });
        }
    };

    const submitAnnotation = (e) => {
        e.preventDefault();
        if (newAnnotationMessage.length === 0) {
            setNewAnnotationError('Your annotation is empty');
        } else {
            nm.createAnnotation(post.id, newAnnotation, newAnnotationMessage, state.user)
                .then((annotation) => {
                    const newAnnotationList = [...annotations];
                    newAnnotationList.unshift(annotation);
                    setAnnotations(newAnnotationList);
                    setShowNewAnnotationModal(false);
                    setNewAnnotation(null);
                    setPost(null);
                    setPostRequested(false);
                    setAnnotations([]);
                    setAnnotationsRequested(false);
                    setNewAnnotationMessage('');
                    // setEditorState(() => EditorState.createWithContent(
                    //     editorState.getCurrentContent().createEntity('ANNOTATION', 'IMMUTABLE', { id: annotation.id }),
                    //     decorator
                    // ));
                });
        }
    };

    const submitReply = (e, annotation) => {
        e.preventDefault();
        if (newAnnotationReply.length === 0) {
            setNewAnnotationError('Your reply is empty');
        } else {
            const isComment = comments.filter(c => c.id === annotation.id).length > 0;
            nm.createReply(post.id, annotation.id, newAnnotationReply, state.user)
                .then((newReply) => {
                    const newReplyList = [...replies];
                    newReplyList.push(newReply);
                    setReplies(newReplyList);
                    setNewAnnotationReply('');

                    if (isComment) {
                        const newCommentsList = [...comments];
                        newCommentsList[newCommentsList.indexOf(annotation)].commentCount = newCommentsList[newCommentsList.indexOf(annotation)].commentCount + 1;
                        setComments(newCommentsList);
                    }
                });
        }
    };

    const onChange = (newEditorState) => {
        detectNewAnnotation(newEditorState);
        setEditorState(newEditorState);
    };

    const detectNewAnnotation = (newEditorState) => {
        const selection = newEditorState.getSelection();
        const anchorKey = selection.getAnchorKey();
        const startKey = selection.getStartKey();
        const endKey = selection.getEndKey();
        const currentContent = newEditorState.getCurrentContent();
        const currentBlock = currentContent.getBlockForKey(anchorKey);
        const start = selection.getStartOffset();
        const end = selection.getEndOffset();
        const selectedText = currentBlock.getText().slice(start, end);

        if (selectedText.length >= MAX_ANNOTATION_LENGTH) {
            if (toastTimer) { toastTimer.clearTimeout(); }
            setShowNewAnnotationWarning('The text you are trying to annotate is too long.');
            toastTimer = setTimeout(() => {
                setShowNewAnnotationWarning(null);
            }, 8000);
        } else if (isSelectionOverlapping(annotations, start, end, startKey) && start !== end) {
            if (toastTimer) { toastTimer.clearTimeout(); }
            setShowNewAnnotationWarning('The text you are trying to annotate overlaps an existing annotation.');
            toastTimer = setTimeout(() => {
                setShowNewAnnotationWarning(null);
            }, 8000);
        } else if (!isMouseDown && startKey === endKey && selectedText.length > 0) {
            setNewAnnotation({ blockId: startKey, start, end, selectedText });
            setShowNewAnnotationModal(true);
        }
    };

    useEffect(() => {
        // editorRef.current && ReactDOM.findDOMNode(editorRef.current).setAttribute('contenteditable', 'false');

        document.body.onmousedown = () => { 
            setIsMouseDown(true);
        }
        document.body.onmouseup = () => {
            setIsMouseDown(false);
        }

        if (!isGrammarlyDisabled && !grammarlyObserver) {
            grammarlyObserver = setInterval(() => {
                const gsr = document.querySelector('[data-grammarly-shadow-root="true"]');
                if (gsr && grammarlyObserver) {
                    setIsGrammarlyDisabled(true);
                    clearInterval(grammarlyObserver);
                    gsr.style.display = 'none';
                }
            }, 1000);
        }

        if (!post && !postRequested) {
            nm.getPost(chapter).then((doc) => {
                setPostRequested(true);
                setPost(doc);
                if (doc.richContent && annotations.length === 0 && !annotationsRequested) {
                    nm.getAnnotations(chapter).then((docs) => {
                        setAnnotationsRequested(true);
                        const annos = docs.filter((doc) => !!doc.annotatedText);
                        setAnnotations(annos);
                        setComments(docs.filter((doc) => !doc.annotatedText));
                        const content = doc.richContent.blocks.map((block) => {
                            const entityRanges = [];
                            annos.filter((anno) => anno.blockId === block.key).forEach((anno) => {
                                entityRanges.push({ offset: anno.startId, length: anno.annotatedText.length, key: anno.id });
                            });
                            return { ...block, entityRanges };
                        });
                        const entityMap = annos.reduce((acc, curr) => {
                            return { ...acc, [curr.id]: { type: 'ANNOTATION', mutability: 'IMMUTABLE', data: { id: curr.id } } };
                        }, {});
                        setEditorState(() => EditorState.createWithContent(convertFromRaw({ blocks: content, entityMap }), decorator));
                    });
                }
            }).catch(() => {
                history.push('/404');
            });
        }
        if (post && !author && !authorRequested) {
            nm.getAuthor(post.authorId).then((doc) => {
                setAuthorRequested(true);
                setAuthor(doc);
            });
        }

        const annoParam = location.get('annotation');
        if (annoParam && post && annotations.length > 0) {
            if (annotations.filter((a) => a.id === annoParam).length > 0) {
                const annoEl = document.querySelector(`div[data-offset-key='${annotations.filter((a) => a.id === annoParam)[0].blockId}-0-0']`);
                setTimeout(() => {
                    if (annoEl) {
                        annoEl.scrollIntoView();
                    }
                }, 1000);
                setViewAnnotation(annoParam);
                nm.getAnnotationReplies(chapter, annoParam)
                    .then((replyList) => {
                        setReplies(replyList);
                        setLocation(new URLSearchParams());
                    });
            } else {
                history.push('/404');
            }
        }

        const commentParam = location.get('comment');
        if (commentParam && post && comments.length > 0) {
            if (comments.filter((a) => a.id === commentParam).length > 0) {
                setViewAnnotation(commentParam);
                nm.getAnnotationReplies(chapter, commentParam)
                    .then((replyList) => {
                        setReplies(replyList);
                        setLocation(new URLSearchParams());
                    });
            } else {
                history.push('/404');
            }
        }
    }, [post, author, annotations, comments, replies, newAnnotation, viewAnnotation, postRequested, authorRequested, annotationsRequested]);

    // const ReplyArea = () => {
    //     const [scrolled, setScrolled] = useState(false);
    //     const replyAreaRef = useRef();
    //     useEffect(() => {
    //         if (!scrolled) {
    //             // replyAreaRef.current.scrollIntoView({ behavior: 'smooth' });
    //             // replyAreaRef.current.focus();
    //             setScrolled(true);
    //         }
    //     });
    //     return <TextArea label="Reply" innerRef={replyAreaRef} placeholder="Enter your reply..." onChange={setNewAnnotationReply} value={newAnnotationReply} />;
    // };

    return (
        <StyledContainer>
            { post && <Helmet>
                <title>{post.title}</title>
                <meta name="description" content={post.preview} />
                <meta property="og:title" content={post.title} />
                <meta property="og:description" content={post.preview} />
                <meta property="og:image" content="https://my.orton.io/static/media/Logo.8289ba56.svg" />
                <meta property="og:url" content={`https://my.orton.io/read/${post.id}`} />
                <meta name="twitter:title" content={post.title} />
                <meta name="twitter:description" content={post.preview} />
                <meta name="twitter:image" content="https://my.orton.io/static/media/Logo.8289ba56.svg" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href={`https://my.orton.io/read/${post.id}`} />
            </Helmet> }
            {showNewAnnotationWarning && ReactDOM.createPortal(
                <Toast message={showNewAnnotationWarning} />,
                document.getElementById('root')
            )}
            {showNewAnnotationModal && ReactDOM.createPortal(
                <Modal mWidth="550" mHeight="550" close={() => {
                    setShowNewAnnotationModal(false);
                    setNewAnnotation(null);
                    setNewAnnotationError('');
                }}>
                    <ModalContent>
                        <SelectedAnnotationText>"{newAnnotation.selectedText}"</SelectedAnnotationText>
                        <TextArea label="Annotation" placeholder="Enter you annotation message..." onChange={setNewAnnotationMessage} value={newAnnotationMessage} />
                    </ModalContent>
                    <ModalFooter>
                        <ModalButtonContainer>
                            <SecondaryButton onClick={() => {
                                setShowNewAnnotationModal(false);
                                setNewAnnotation(null);
                                setNewAnnotationError('');
                            }}>Cancel</SecondaryButton>
                            <Button onClick={submitAnnotation}>Annotate</Button>
                        </ModalButtonContainer>
                    </ModalFooter>
                </Modal>,
                document.getElementById('root')
            )}
            {viewAnnotation && ReactDOM.createPortal(
                <Modal mWidth="550" mHeight="550" close={() => {
                    setViewAnnotation(null);
                    setReplies([]);
                    setNewAnnotationError('');
                }}>
                    <ModalContent>
                        { viewAnnotation
                            && annotations.filter(a => a.id === viewAnnotation).length > 0 && annotations.filter(a => a.id === viewAnnotation)[0].annotatedText
                            && <SelectedAnnotationText>"{annotations.filter(a => a.id === viewAnnotation)[0].annotatedText}"</SelectedAnnotationText> }
                        <Reply {...annotations.filter(a => a.id === viewAnnotation)[0]} />
                        {replies.length > 0 && replies.map((reply) => <Reply key={reply.id} {...reply} />)}
                        { newAnnotationError && <ErrorText><Icon icon="warning" />{newAnnotationError}</ErrorText> }
                    </ModalContent>
                    <ModalFooter>
                        <TextArea label="Reply" placeholder="Enter your reply..." onChange={setNewAnnotationReply} value={newAnnotationReply} />
                        <ModalButtonContainer>
                            <SecondaryButton onClick={() => {
                                setViewAnnotation(null);
                                setReplies([]);
                                setNewAnnotationError('');
                            }}>Cancel</SecondaryButton>
                            <Button onClick={(e) => submitReply(e, annotations.filter(a => a.id === viewAnnotation)[0])}>Reply</Button>
                        </ModalButtonContainer>
                    </ModalFooter>
                </Modal>,
                document.getElementById('root')
            )}
            {viewComment && ReactDOM.createPortal(
                <Modal mWidth="550" mHeight="550" close={() => {
                    setViewComment(null);
                    setReplies([]);
                }}>
                    <ModalContent>
                        { viewComment
                            && comments.filter(a => a.id === viewComment).length > 0
                            && <Reply {...comments.filter(a => a.id === viewComment)[0]} /> }
                        {replies.length > 0 && replies.map((reply) => <Reply key={reply.id} {...reply} />)}
                        {/* { newCommentError && <ErrorText><Icon icon="warning" />{newCommentError}</ErrorText> } */}
                    </ModalContent>
                    <ModalFooter>
                        <TextArea label="Reply" placeholder="Enter your reply..." onChange={setNewAnnotationReply} value={newAnnotationReply} />
                        <ModalButtonContainer>
                            <SecondaryButton onClick={() => {
                                setViewComment(null);
                                setReplies([]);
                            }}>Cancel</SecondaryButton>
                            <Button onClick={(e) => submitReply(e, comments.filter(a => a.id === viewComment)[0])}>Reply</Button>
                        </ModalButtonContainer>
                    </ModalFooter>
                </Modal>,
                document.getElementById('root')
            )}
            {selectedAnnotation && ReactDOM.createPortal(
                <Modal mWidth="550" mHeight="750" close={() => {
                    setShowNewAnnotationModal(false);
                    setNewAnnotationError('');
                }}>
                    <ModalContent>
                        <SelectedAnnotationText>"{selectedAnnotation.annotatedText}"</SelectedAnnotationText>
                    </ModalContent>
                    <SAModalFooter>
                        <TextArea label="Reply" placeholder="Enter your annotation reply..." onChange={setNewAnnotationMessage} value={newComment} />
                        { newAnnotationError && <ErrorText><Icon icon="warning" />{newAnnotationError}</ErrorText> }
                        <ButtonGroup>
                            <SecondaryButton onClick={() => {
                                setSelectedAnnotation(null);
                                setNewAnnotationError('');
                            }}>Cancel</SecondaryButton>
                            <Button onClick={submitAnnotation}>Save</Button>
                        </ButtonGroup>
                    </SAModalFooter>
                </Modal>,
                document.getElementById('root')
            )}
            { state.user && post && post.id && <AuthorContainer>
                    <PostDetails metaItems={[
                        <MetaDataItem key={post.genre.name || 'genre'}>{post.genre.name}</MetaDataItem>,
                        <MetaDataItem key={post.type.pluralName || 'type'}>{post.type.singularName}</MetaDataItem>,
                        <MetaDataItem key={computeReadTime(post.content)}>{computeReadTime(post.content)} read</MetaDataItem>,
                        <MetaDataItem key={post.creationDate}>{formatDistanceToNow(post.creationDate)} ago</MetaDataItem>
                    ]} author={{ authorPenName: post.authorPenName, authorId: post.authorId, authorPicture: post.authorPicture }} />
                { state.user.id !== post.authorId && <Follow authorId={post.authorId} authorPenName={post.authorPenName} /> }
                {/* <SocialButtons>
                    <div className="fb-share-button" data-href={window.location.href} data-layout="button_count" data-size="small"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php" className="fb-xfbml-parse-ignore">Share</a></div>
                    <a className="twitter-share-button"
                        href="https://twitter.com/intent/tweet?text=Check%20out%20this%20story"
                        data-size="small">
                        Tweet
                    </a>
                </SocialButtons> */}
            </AuthorContainer> }
            { post && post.game && <ChapterGameTile {...post.game} /> }
            { post && post.bookPosition > 0 && post.bookTitle && <Chapter onClick={() => history.push(`/read/book/${post.bookId}`)}>Chapter {post.bookPosition} of {post.bookTitle}</Chapter> }
            <Title>{post && post.title}</Title>
            { post && !post.richContent && <Content id="content">{post && post.content}</Content> }
            { post && post.richContent && <Editor
                editorState={editorState}
                customStyleMap={editorStyleMap}
                onChange={onChange}
                keyBindingFn={() => 'not-handled-command'}
                editorRef={editorRef}
            /> }
            <Form onSubmit={submitComment}>
                <TextArea label="Comment" placeholder="Let the author know what you think of this chapter..." onChange={setNewComment} value={newComment} />
                { newCommentError && <ErrorText><Icon icon="warning" />{newCommentError}</ErrorText> }
                <Button>Comment</Button>
            </Form>
            {comments.map((comment) => <Comment key={comment.id} {...comment} handleReplies={() => {
                    setViewComment(comment.id);
                    nm.getAnnotationReplies(chapter, comment.id)
                        .then((replyList) => {
                            setReplies(replyList);
                        });
                }}
            />)}
        </StyledContainer>
    );
};

export default ReadChapter;
