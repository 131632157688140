import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

const ModalBackground = styled.div`
    background: var(--modal-background);
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    top: 0;
    left: 0;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
`;

const StyledModal = styled(animated.div)`
    position: relative;
    border-radius: 20px;
    background: var(--background);
    box-shadow: var(--shadow);
    width: 100%;
    max-width: ${({ mwidth }) => mwidth ? `${mwidth}px` : 'inherit'};
    max-height: ${({ mheight }) => mheight ? `${mheight}px` : 'inherit'};
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ModalFooter = styled.div`
    background-color: var(--background);
    padding: 10px 20px 20px;
    position: sticky;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModalButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const Modal = ({ children, mWidth, mHeight, close, noTransition }) => {
    const [modalVisible, setModalVisible] = useState(true);
    const transitions = useTransition(modalVisible, null, {
        from: { opacity: 0, transform: "translateY(50px)" },
        enter: { opacity: 1, transform: "translateY(0px)" },
        leave: { opacity: 0, transform: "translateY(50px)" }
    });

    useEffect(() => {
        document.body.style = 'overflow-y:hidden;height:100vh;';
        document.body.style.top = `-${window.scrollY}px`;

        return () => {
            // const scrollY = document.body.style.top;
            document.body.style = 'overflow-y:auto;height:auto;';
            document.body.style.position = '';
            document.body.style.top = '';
            // window.scrollTo(0, parseInt(scrollY || '0') * -1);
        };
    });

    return (
        <ModalBackground onClick={close}>
            { transitions.map(({ item, key, props: style }) => (
                item && <StyledModal key={key} style={noTransition ? {} : style} mwidth={mWidth} mheight={mHeight} onClick={e => e.stopPropagation()}>
                    {children}
                </StyledModal>
            )) }
        </ModalBackground>
    );
};

export default Modal;