import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';

import { Container, Heading, HideOnDesktop, HideOnMobile, Button, StyledLink } from '../components/styledComponents';
import InputField from '../components/inputField';

const Fullscreen = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const SmallerContainer = styled(Container)`
    max-width: 540px;
    width: 100%;
    padding: 0 10px;
`;

const StyledHeading = styled(Heading)`
    margin-bottom: 30px;

    @media (max-width: 700px) {
        font-size: 36px;
    }
`;

const FloatingLink = styled(StyledLink)`
    position: absolute;
    top: 34px;
    right: 24px;
`;

const SentText = styled.p`
    font-weight: 600;
    color: var(--accent);
    line-height: 1.2;
`;

const Form = styled.form`
    margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
    width: 100%;
    font-size: 18px;
    min-height: 60px;
    margin-top: 20px;
`;

const ForgottenPassword = () => {
    const nm = new NetworkManager();
    const [email, setEmail] = useState('');
    const [resetPasswordSent, setResetPasswordSent] = useState(false);

    return (
        <Fullscreen>
            <Helmet><title>Forgotten password</title></Helmet>
            <HideOnMobile>
                <FloatingLink to="/signin">Remember your password? Sign in</FloatingLink>
            </HideOnMobile>
            <SmallerContainer>
                <StyledHeading>Forgotten password</StyledHeading>
                { resetPasswordSent && <SentText>Reset password email sent to {email}. It may appear in your spam folder.</SentText> }
                { !resetPasswordSent && <Form onSubmit={(e) => {
                        e.preventDefault();
                        nm.resetPassword(email).then(() => setResetPasswordSent(true))
                    }}>
                    <InputField label="Email" placeholder="Enter your email address" type="email" onChange={setEmail} />
                    <StyledButton type="submit">Get reset email</StyledButton>
                </Form> }
                <HideOnDesktop style={{ marginBottom: '40px' }}>
                    <StyledLink to="/signin">Remember your password? Sign in</StyledLink>
                </HideOnDesktop>
            </SmallerContainer>
        </Fullscreen>
    );
};

export default ForgottenPassword;
