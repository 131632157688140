import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { animated } from 'react-spring';
import Skeleton from 'react-loading-skeleton';
import mixpanel from 'mixpanel-browser';

import NetworkManager from '../classes/NetworkManager';
import { trunc } from '../globalFunctions';

import { Container, Button, SecondaryButton } from './styledComponents';
import PostDetails, { MetaDataItem, DetailsSkeleton } from './postDetails';
import Icon from '../components/icon';
import Modal, { ModalFooter } from '../components/modal';
import { SmallCondensedBook } from '../components/condensedBook';

const StyledContainer = styled(animated(Container))`
    padding: 60px 0;
    border-bottom: solid 2px var(--primary-highlight);
    width: 100%;
    overflow-x: hidden;

    &:last-of-type {
        border-bottom: none;
    }

    @media (max-width: 700px) {
        padding: 30px 0;

        &:first-of-type {
            padding-top: 60px;
        }
    }
`;

const Chapter = styled.p`
    color: var(--accent);
    display: block;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
`;

const TitleContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
`;

const Title = styled.h2`
    font-size: 36px;
    font-weight: 600;
    margin: 10px 0;
    cursor: pointer;
    padding-right: 35px;

    @media (max-width: 700px) {
        font-size: 24px;
    }
`;

const Preview = styled.p`
    line-height: 1.6;
    font-size: 18px;
    flex: 1 1 0;

    @media (max-width: 700px) {
        font-size: 16px;
    }
`;

const Menu = styled.div`
    position: absolute;
    top: 16px;
    right: 0;
    
    img {
        cursor: pointer;
    }

    @media (max-width: 700px) {
        top: 12px;
    }
`;

const ModalItem = styled.div`
    padding: 10px 15px;
    font-weight: 600;
    color: var(--accent);
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--background-highlight);
    }

    img {
        margin-right: 10px;
    }
`;

const Delete = styled.span`
    color: red;
`;

const DeleteButton = styled(Button)`
    background-color: transparent;
    border-color: red;
    color: red;

    img {
        filter: none;
    }
`;

const OptionsContainer = styled.div`
    
`;

const OptionsTitle = styled.h3`
    font-weight: 600;
    font-size: 20px;
    padding: 24px 24px 10px 24px;
    margin: 10px 0;
`;

const OptionsModalItem = styled(ModalItem)`
    padding-left: 24px;
    padding-right: 24px;

    &:last-of-type {
        padding-bottom: 24px;
    }
`;

const ModalSubheading = styled.p`
    padding: 0 24px 0 24px;
`;

const BookModalItem = styled(ModalItem)`

`;

const ModalSelected = styled.p`
    font-size: 14px;
    font-weight: 600;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 15px 24px 24px 24px;

    button {
        height: 40px;
    }
`;

const NoBooks = styled.p`
    padding: 20px 24px;
    line-height: 1.5;
`;

export const CondensedPostSkeleton = () => {
    const [delay, setDelay] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDelay(false);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    });

    return delay ? null : (
        <StyledContainer>
            <TitleContainer>
                <Title>
                    <Skeleton height={36} width={300} />
                </Title>
            </TitleContainer>
            <Preview>
                <Skeleton count={3} />
            </Preview>
            <DetailsSkeleton />
        </StyledContainer>
    );
};

const CondensedPost = ({ id, bookId, content, bookPosition, bookTitle, authorPenName, authorId, authorPicture, title, preview, genre, type, creationDate, creationDateTimeStamp, showMenu, deletePost, books, y, isPublic,  ...otherProps }) => {
    const history = useHistory();
    const [showMenuModal, setShowMenuModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showBookModal, setShowBookModal] = useState(false);
    const [publicToggle, setPublicToggle] = useState(isPublic);
    const [clearBook, setClearBook] = useState(false);

    const nm = new NetworkManager();

    const togglePublicStatus = () => {
        if (publicToggle) {
            nm.setPostToPrivate(id).then(() => setPublicToggle(false));
        } else {
            nm.setPostToPublic(id).then(() => setPublicToggle(true));
        }
    };

    return (
        <StyledContainer
            style={{
                ...otherProps,
                // transform: y && y.interpolate(y => `translate3d(0, ${y}, 0)`)
            }}
        >
            {showDeleteModal && ReactDOM.createPortal(
                <Modal mWidth="450" mHeight="450" close={() => setShowMenuModal(false)}>
                    <OptionsContainer>
                        <OptionsTitle>Are you sure?</OptionsTitle>
                        <ModalSubheading>This is cannot be undone.</ModalSubheading>
                        <ButtonContainer>
                            <SecondaryButton onClick={() => setShowDeleteModal(false)}>Cancel</SecondaryButton>
                            <DeleteButton onClick={() => {
                                deletePost(id);
                                setShowDeleteModal(false);
                            }}><Icon icon="delete" />Delete</DeleteButton>
                        </ButtonContainer>
                    </OptionsContainer>
                </Modal>,
                document.getElementById('root')
            )}
            {showMenuModal && ReactDOM.createPortal(
                <Modal mWidth="250" mHeight="450" close={() => setShowMenuModal(false)}>
                    <OptionsContainer>
                        <OptionsTitle>Options</OptionsTitle>
                        { (!bookId || clearBook) && <OptionsModalItem onClick={() => {
                            setShowMenuModal(false);
                            setShowBookModal(true);
                        }}><Icon icon="book" />Add to book</OptionsModalItem> }
                        { bookId && !clearBook && <OptionsModalItem onClick={() => {
                            nm.removePostFromBook(bookId, id).then(() => {
                                setShowMenuModal(false);
                                setClearBook(true);
                            });
                        }}><Icon icon="book" />Remove from book</OptionsModalItem> }
                        <OptionsModalItem onClick={() =>{
                            togglePublicStatus();
                        }}><Icon icon={publicToggle ? 'eye' : 'eyeClosed'} />{publicToggle ? 'Change to private' : 'Change to public'}</OptionsModalItem>
                        <OptionsModalItem onClick={() => {
                            setShowMenuModal(false);
                            setShowDeleteModal(true);
                        }}><Icon icon="delete" /><Delete>Delete</Delete></OptionsModalItem>
                    </OptionsContainer>
                </Modal>,
                document.getElementById('root')
            )}
            {showBookModal && ReactDOM.createPortal(
                <Modal mWidth="400" mHeight="500" close={() => setShowBookModal(false)}>
                    {books.map((b) => <BookModalItem key={b.id} onClick={() => {
                        nm.addPostToBook(b, id)
                            .then(() => {
                                setShowBookModal(false);
                                history.push(`/read/book/${b.id}`);
                            });
                    }}>
                        <SmallCondensedBook {...b} />
                    </BookModalItem>)}
                    {books.length === 0 && <NoBooks>You have no books. Create a book before adding your writing.</NoBooks>}
                </Modal>,
                document.getElementById('root')
            )}
            { bookPosition > 0 && bookTitle && !clearBook && <Chapter onClick={() => history.push(`/read/book/${bookId}`)}>Chapter {bookPosition} of {bookTitle}</Chapter> }
            <TitleContainer>
                <Title onClick={() => {
                    mixpanel.track('View post', { id });
                    history.push(`/read/${id}`);
                }}>{title}</Title>
                { showMenu && <Menu>
                    <Icon icon="options" size={25} onClick={() => setShowMenuModal(true)} />
                </Menu> }
            </TitleContainer>
            <Preview>{preview || content.replace(/(<([^>]+)>)/ig, '').replace('&nbsp;', '').substring(0, 200)}...</Preview>
            <PostDetails metaItems={[
                <MetaDataItem key={genre.name}>{genre.name}</MetaDataItem>,
                <MetaDataItem key={type.singularName}>{type.singularName}</MetaDataItem>,
                creationDateTimeStamp
                    ? <MetaDataItem key={creationDateTimeStamp}>{formatDistanceToNow(creationDateTimeStamp)} ago</MetaDataItem>
                    : <MetaDataItem key={creationDate}>{formatDistanceToNow(creationDate)} ago</MetaDataItem>
            ]} author={{ authorPenName, authorId, authorPicture }} />
        </StyledContainer>
    );
};

export default CondensedPost;
