import React from 'react';
import styled from 'styled-components';

import Bookmarks from '../assets/illo_bookmarks.svg';
import Audio from '../assets/illo_audio.svg';
import Review from '../assets/illo_review.svg';
import Collaboration from '../assets/illo_collaboration.svg';
import Editor from '../assets/illo_editor.svg';
import Idea from '../assets/illo_idea.svg';

const illustrationMapping = {
    bookmarks: Bookmarks,
    audio: Audio,
    review: Review,
    collaboration: Collaboration,
    editor: Editor,
    idea: Idea
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ leftAligned }) => leftAligned ? 'flex-start' : 'center'};
    margin-top: 40px;
`;

const StyledIllustration = styled.img`
    width: ${({ illustration }) => illustration === 'bookmarks' ? '120' : '200'}px;
    margin-bottom: 20px;

    @media (max-width: 700px) {
       width: ${({ illustration }) => illustration === 'bookmarks' ? '80' : '150'}px;
    }
`;

const IllustrationTitle = styled.p`
    font-weight: 600;
    color: var(--text);
    margin-bottom: 10px;
    font-size: 18px;
`;

const IllustrationText = styled.p`
    color: var(--text);
    font-size: 18px;
    text-align: center;
    line-height: 1.2;
`;

const Illustration = ({ illustration, title, text, onClick, size, leftAligned }) => {
    return (
        <Container leftAligned={leftAligned}>
            <StyledIllustration src={illustrationMapping[illustration]} illustration={illustration} size={size} onClick={onClick} />
            { title && <IllustrationTitle>{title}</IllustrationTitle> }
            { text && <IllustrationText>{text}</IllustrationText> }
        </Container>
    );
};

export default Illustration;
