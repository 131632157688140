import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { loadStripe } from '@stripe/stripe-js';
import mixpanel from 'mixpanel-browser';
import { genKey } from 'draft-js';

import Author from './Author';
import Game from './Game';
import Post from './Post';
import Book from './Book';
import Genre from './Genre';
import Type from './Type';
import Annotation from './Annotation';
import Notification from './Notification';
import Comment from './Comment';
import CommunicationPreferences from './CommunicationPreferences';
import PublishedBook from './PublishedBook';
import Reply from './Reply';

const computeReadTime = (content) => {
  const matches = content.match(/\S+/g);
  const words = matches ? matches.length : 0;
  return Math.ceil(words / 200);
}

const firebaseConfig = {
    apiKey: "AIzaSyApmhiQUhHbh2q8z9QIO2kRUfwM3QiK0is",
    authDomain: "orton-627ee.firebaseapp.com",
    databaseURL: "https://orton-627ee.firebaseio.com",
    projectId: "orton-627ee",
    storageBucket: "orton-627ee.appspot.com",
    messagingSenderId: "47494267216",
    appId: "1:47494267216:web:5110001435cfb78679ac55",
    measurementId: "G-75BNT889XC"
};
  
const fb = firebase.initializeApp(firebaseConfig);

const db = fb.firestore();
const auth = fb.auth();
const funcs = fb.functions();
const storage = fb.storage();

export { db, auth };

class NetworkManager {
    setupAuthObserver = (dispatch) => {
        if (localStorage.getItem('ou')) {
            dispatch({ type: 'add_user', user: JSON.parse(localStorage.getItem('ou')) })
        }

        auth.onAuthStateChanged((user) => {
            if (user) {
                this.getAuthor(user.uid, true)
                    .then((user) => {
                        mixpanel.identify(user.id);
                        mixpanel.people.set_once({ '$name': user.name, '$email': user.email });
                        dispatch({ type: 'add_user', user });
                    });
            } else {
                dispatch({ type: 'empty_user' })
            }
        });
    }

    getUserDetails = () => auth.currentUser;

    resetPassword = (email) => {
        return auth.sendPasswordResetEmail(email)
            .catch(e => console.error(e));
    };
    
    login = (email, password) => {
        return auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => auth.signInWithEmailAndPassword(email, password))
            .then((res) => {
                const { user } = res;
                this.getAuthor(user.uid, true)
                    .then((author) => {
                        mixpanel.track('Sign in', { id: author.id });
                        return author;
                    });
            })
            .catch(e => console.error(e));
    };
    
    signUp = (name, email, password) => {
        return auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => auth.createUserWithEmailAndPassword(email, password))
            .then((res) => {
                const { user } = res;
                const communicationPreferences = new CommunicationPreferences({
                    email: true,
                    push: false,
                    pushTokens: null
                });

                const author = {
                    id: user.uid,
                    bio: null,
                    name: name,
                    penName: user.displayName || name,
                    followers: [],
                    following: [],
                    subscription: 0,
                    picture: null,
                    likes: [],
                    bookmarks: [],
                    communicationPreferences: Object.assign({}, communicationPreferences)
                };

                this.updateProfile(Object.assign({}, author));
                mixpanel.track('Sign up', { id: author.id });
                return author;
            }).catch(e => console.error(e));
    };

    signUpWithGoogle = () => {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        return auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => auth.signInWithPopup(googleProvider))
            .then((res) => {
                const { user } = res;
                const communicationPreferences = new CommunicationPreferences({
                    email: true,
                    push: false,
                    pushTokens: null
                });

                const author = {
                    id: user.uid,
                    bio: null,
                    name: user.displayName,
                    penName: user.displayName || `User${user.uid}`,
                    followers: [],
                    following: [],
                    subscription: 0,
                    picture: user.photoURL,
                    likes: [],
                    bookmarks: [],
                    communicationPreferences: Object.assign({}, communicationPreferences)
                };

                this.updateProfile(Object.assign({}, author));
                mixpanel.track('Sign up (Google)', { id: author.id });
                return author;
            }).catch((error) => {
                console.error(error.message)
            });
    };

    signInWithGoogle = () => {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        return auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => auth.signInWithPopup(googleProvider))
            .then((res) => {
                const { user } = res;
                return this.getAuthor(user.uid, true)
                    .then((author) => {
                        mixpanel.track('Sign in (Google)', { id: author.id });
                        return new Author(author);
                    });
            }).catch((error) => {
                console.error(error.message)
            });
    };
    
    logout = (id) => {
        return fb.auth().signOut()
            .then(() => {
                mixpanel.track('Sign out', { id });
            })
            .catch(e => console.error(e));
    };
    
    getGame = (gameType) => {
        db.collection('games')
            .where('type', gameType)
            .get()
            .then(({ documents }) => {
                return documents.map((document) => {
                    return new Game(document);
                });
            })
            .catch(e => console.error(e));
    };
    
    getGames = () => {
        return db.collection('games')
            .get()
            .then(({ docs }) => {
                return docs.map((document) => {
                    return new Game(document.data());
                });
            })
            .catch(e => console.error(e));
    };
    
    updateProfile = (author) => {
        // const newAuthor = { ...author };
        // delete newAuthor.email;
        db.collection('users')
            .doc(author.id)
            .set(author, { merge: true })
            .catch(e => console.error(e));
    };
    
    updateProfileDetails = (newDetails, user) => {
        if (newDetails.picture) {
            return storage.ref()
                .child(`profileImages/${user.id}/${user.id}.jpg`)
                .put(newDetails.picture)
                .then((imageSnapshot) => imageSnapshot.ref.getDownloadURL())
                .then((imageUrl) => {
                    return db.collection('users')
                        .doc(user.id)
                        .set({
                            picture: imageUrl,
                            bio: newDetails.bio,
                            penName: newDetails.penName
                        }, { merge: true });
                })
                .catch(e => console.error(e));
        } else {
            return db.collection('users')
                .doc(user.id)
                .set({
                    bio: newDetails.bio,
                    penName: newDetails.penName
                }, { merge: true });
        }
    };
    
    getAuthor = (authorId, userIsAuthor) => {
        return db.collection('users')
            .doc(authorId)
            .get()
            .then((res) => {
                if (userIsAuthor) {
                    return db.doc(`users/${authorId}/private/credentials`)
                        .get()
                        .then((credentials) => {
                            if (res.exists && credentials.exists) {
                                return new Author({ ...res.data(), email: credentials.data().email });
                            } else {
                                throw new Error('404');
                            }
                        });
                } else {
                    if (res.exists) {
                        return new Author(res.data());
                    } else {
                        throw new Error('404');
                    }
                }
            })
            .catch((e) => {
                console.error(e);
                throw e;
            });
    };
    
    createPost = ({ title, content, richContent, type, genre, bookId, bookTitle, user, game, bookPosition, publicPost, gameType }) => {
        const ref = db.collection('posts').doc();
        return ref
            .set({
                id: ref.id,
                title,
                content: content.replace(/(<([^>]+)>)/ig, ''),
                richContent,
                preview: content.substring(0, 200),
                creationDate: new Date(),
                genreId: genre.id,
                genre: Object.assign({}, genre),
                typeId: type.id,
                type: Object.assign({}, type),
                readTime: computeReadTime(content),
                readersCount: 0,
                annotationCount: 0,
                authorId: user.id,
                authorPenName: user.penName || null,
                authorPicture: user.picture || null,
                authorSubscription: user.subscription || 0,
                audioUrl: null,
                gameType: gameType || 0,
                gameId: game ? game.id : null,
                game: game || null,
                bookId: bookId || null,
                bookTitle: bookTitle || null,
                bookPosition: (typeof bookPosition == 'number' ? bookPosition : 0) + 1,
                likes: [],
                bookmarks: [],
                public: publicPost,
                version: 1,
                groupId: ref.id
            })
            .catch(e => console.error(e));
    };
    
    createAnnotation = (postId, annotation, message, user) => {
        const newAnnotation = db
            .collection('annotations')
            .doc();

        const annotationData = {
            id: newAnnotation.id,
            postId,
            creationDate: new Date(),
            message,
            blockId: annotation.blockId,
            startId: annotation.start,
            endId: annotation.end,
            annotatedText: annotation.selectedText,
            authorId: user.id,
            authorPenName: user.penName,
            authorSubscription: user.subscription,
            authorPicture: user.picture || null,
            commentCount: 0
        };

        return newAnnotation
            .set(annotationData)
            .then(() => {
                return Promise.resolve(new Annotation(annotationData));
            })
            .catch(e => console.error(e));
    };

    createReply = (postId, annotationId, message, user) => {
        const newReply = db
            .collection('comments')
            .doc();
        
        const replyData = {
            id: newReply.id,
            postId,
            creationDate: new Date(),
            message,
            authorId: user.id,
            authorPenName: user.penName,
            authorSubscription: user.subscription,
            authorPicture: user.picture || null,
            likeIds: [],
            likes: [],
            annotationId: annotationId
        };

        return newReply
            .set(replyData)
            .then(() => {
                return Promise.resolve(new Reply(replyData));
            })
            .catch(e => console.error(e));
    };
    
    createComment = (postId, message, user) => {
        const newComment = db
            .collection('annotations')
            .doc();

        const commentData = {
            id: newComment.id,
            postId,
            creationDate: new Date(),
            message,
            authorId: user.id,
            authorPenName: user.penName,
            authorSubscription: user.subscription,
            authorPicture: user.picture || null,
            commentCount: 0
        };

        return newComment
            .set(commentData)
            .then(() => {
                return Promise.resolve(new Annotation(commentData));
            })
            .catch(e => console.error(e));
    };
    
    getPost = (postId) => {
        return db.collection('posts')
            .doc(postId)
            .get()
            .then((document) => {
                const post = document.data();
                const creationDate = post.creationDate.toDate();
                return new Post(Object.assign({}, post, { creationDate }));
            })
            .catch((e) => {
                console.error(e);
                throw new Error('404');
            });
    };

    getAnnotations = (postId) => {
        return db
            .collection('annotations')
            .where('postId', '==', postId)
            .orderBy('creationDate', 'desc')
            .get()
            .then(({ docs }) => {
                return docs.map((document) => {
                    const annotation = document.data();
                    const creationDate = annotation.creationDate.toDate();
                    return new Annotation(Object.assign({}, annotation, { creationDate }));
                });
            })
            .catch(e => console.error(e));
    };
    
    getAnnotationReplies = (postId, annotationId) => {
        return db
            .collection('comments')
            .where('postId', '==', postId)
            .where('annotationId', '==', annotationId)
            .orderBy('creationDate', 'desc')
            .get()
            .then(({ docs }) => {
                return docs.map((document) => {
                    const reply = document.data();
                    const creationDate = reply.creationDate.toDate();
                    return new Reply(Object.assign({}, reply, { creationDate }));
                });
            })
            .catch(e => console.error(e));
    };
    
    createBook = ({ user, title, synopsis }) => {
        const ref = db.collection('books').doc();

        const newBook = {
            id: ref.id,
            creationDate: new Date(),
            title,
            synopsis,
            authorId: user.id,
            authorPenName: user.penName,
            authorSubscription: user.subscription || 0,
            authorPicture: user.picture || null,
            readTime: 0,
            public: true,
            chapterIds: []
        };

        return ref
            .set(newBook)
            .then(() => {
                return Promise.resolve(new Book(newBook));
            })
            .catch(e => console.error(e));
    };

    editBook = ({ id, title, synopsis }) => {
        const ref = db.collection('books').doc(id);

        return ref
            .update({
                title,
                synopsis
            })
            .catch(e => console.error(e));
    };
    
    getBook = (bookId) => {
        return db
            .collection('books')
            .where('id', '==', bookId)
            .get()
            .then(({ docs }) => {
                if (docs.length > 0) {
                    return docs.map((document) => {
                        const book = document.data();
                        const creationDate = book.creationDate.toDate();
                        return new Book(Object.assign({}, book, { creationDate }));
                    })[0];
                } else {
                    throw new Error('404');
                }
            })
            .catch((e) => {
                console.error(e);
                throw e;
            });
    };
    
    getBookPosts = ({ chapterIds }) => {
        const posts = chapterIds.map((chapterId) => {
            return db
                .collection('posts')
                .doc(chapterId)
                .get()
                .then((document) => {
                    const post = document.data();
                    const creationDate = post.creationDate.toDate();
                    return new Post(Object.assign({}, post, { creationDate }));
                })
                .catch(e => console.error(e));
        });

        return Promise.all(posts);
    };
    
    addPostToBook = (book, postId) => {
        return db.collection('books')
            .doc(book.id)
            .update({
                chapterIds: firebase.firestore.FieldValue.arrayUnion(postId)
            })
            .then(() => db.collection('posts').doc(postId).update({
                bookTitle: book.title,
                bookId: book.id,
                bookPosition: book.chapterIds.length + 1
            }))
            .catch(e => console.error(e));
    };
    
    removePostFromBook = (bookId, postId) => {
        return db.collection('books')
            .doc(bookId)
            .update({
                chapterIds: firebase.firestore.FieldValue.arrayRemove(postId)
            })
            .then(() => db.collection('posts').doc(postId).update({
                bookTitle: firebase.firestore.FieldValue.delete(),
                bookId: firebase.firestore.FieldValue.delete(),
                bookPosition: 1
            }))
            .catch(e => console.error(e));
    };
    
    getPosts = ({ gameId, genre, type, user, publicPosts = true, excludeGames }) => {
        let query = db.collection('posts');

        if (user && user.id) { query = query.where('authorId', '==', user.id); }
        if (gameId) { query = query.where('game.id', '==', gameId); }
        if (excludeGames) { query = query.where('gameType', '==', 0); }
        if (genre) { query = query.where('genre.name', '==', genre); }
        if (type) { query = query.where('type.pluralName', '==', type); }

        return query
            .orderBy('creationDate', 'desc')
            .limit(100)
            .get()
            .then(({ docs }) => {
                return docs.map((document) => {
                    const post = document.data();
                    const creationDate = post.creationDate.toDate();
                    return new Post(Object.assign({}, post, { creationDate }));
                });
            })
            .catch(e => console.error(e));
    };

    getFollowingPosts = (genreId, typeId) => {
        return funcs.httpsCallable('getFollowingPosts')({ genreId, typeId })
            .then(({ data }) => {
                return data.map((document) => {
                    const { _seconds, _nanoseconds } = document.creationDate;
                    const ts = new firebase.firestore.Timestamp(_seconds, _nanoseconds);
                    const creationDate = ts.toDate();
                    return new Post(Object.assign({}, document, { creationDate }));
                });
            })
            .catch(e => console.error(e));
    };

    getFollowingBooks = (genreId, typeId) => {
        return funcs.httpsCallable('getFollowingBooks')({ genreId, typeId })
            .then(({ data }) => {
                return data.map((document) => {
                    const { _seconds, _nanoseconds } = document.creationDate;
                    const ts = new firebase.firestore.Timestamp(_seconds, _nanoseconds);
                    const creationDate = ts.toDate();
                    return new Book(Object.assign({}, document, { creationDate }));
                });
            })
            .catch(e => console.error(e));
    };

    getBookmarks = () => {
        return funcs.httpsCallable('getBookmarks')()
            .then(({ data }) => {
                return data.map((document) => {
                    const { _seconds, _nanoseconds } = document.creationDate;
                    const ts = new firebase.firestore.Timestamp(_seconds, _nanoseconds);
                    const creationDate = ts.toDate();
                    return new Post(Object.assign({}, document, { creationDate }));
                });
            })
            .catch(e => console.error(e));
    };
    
    getBooks = ({ genre, type, user }) => {
        let query = db.collection('books');

        if (user && user.id) {
            query = query.where('authorId', '==', user.id);
        }

        return query
            .orderBy('creationDate', 'desc')
            .limit(100)
            .get()
            .then(({ docs }) => {
                return docs.map((document) => {
                    const book = document.data();
                    const creationDate = book.creationDate.toDate();
                    return new Book(Object.assign({}, book, { creationDate}));
                });
            })
            .catch(e => console.error(e));
    };

    getPublishedBooks = ({ user }) => {
        let query = db.collection('publishingBooks');

        if (user && user.id) {
            query = query.where('authorId', '==', user.id);
        }

        return query
            .orderBy('creationDate', 'desc')
            .limit(100)
            .get()
            .then(({ docs }) => {
                return docs.map((document) => {
                    const book = document.data();
                    const creationDate = book.creationDate.toDate();
                    return new PublishedBook(Object.assign({}, book, { creationDate}));
                });
            })
            .catch(e => console.error(e));
    };

    getAuthorsPosts = (authorId) => {

    };
    
    getAuthorsBooks = (authorId) => {

    };
    
    getNotifications = ({ user }) => {
        return db.collection('notifications')
            .where('recipientId', '==', user.id)
            .orderBy('creationDate', 'desc')
            .limit(50)
            .get()
            .then(({ docs }) => {
                return docs.map((document) => {
                    const notification = document.data();
                    const creationDate = notification.creationDate.toDate();
                    return new Notification(Object.assign({}, notification, { creationDate }));
                });
            })
            .catch(e => console.error(e));
    };
    
    addBookmark = (post) => {

    };
    
    removeBookmark = (post) => {

    };
    
    likePost = (post) => {

    };
    
    unlikePost = (post) => {

    };
    
    getGenres = () => {
        return db.collection('genres')
            .get()
            .then(({ docs }) => {
                return docs.map((document) => {
                    return new Genre(document.data());
                });
            })
            .catch(e => console.error(e));
    };
    
    getTypes = () => {
        return db.collection('types')
            .get()
            .then(({ docs }) => {
                return docs.map((document) => {
                    return new Type(document.data());
                });
            })
            .catch(e => console.error(e));
    };
    
    markNotificationAsRead = (notificationId) => {
        return db.collection('notifications')
            .doc(notificationId)
            .update({ read: true })
            .catch(e => console.error(e));
    };
    
    updateCommunicationPreferences = (email, push) => {

    };
    
    turnOnPushNotifications = () => {

    };
    
    setPushNotificationToken = (token) => {

    };

    setPostToPrivate = (postId) => {
        return db.collection('posts')
            .doc(postId)
            .update({ public: false })
            .catch(e => console.error(e));
    };

    setPostToPublic = (postId) => {
        return db.collection('posts')
            .doc(postId)
            .update({ public: true })
            .catch(e => console.error(e));
    };

    setBookToPrivate = (bookId) => {
        return db.collection('books')
            .doc(bookId)
            .update({ public: false })
            .catch(e => console.error(e));
    };

    setBookToPublic = (bookId) => {
        return db.collection('books')
            .doc(bookId)
            .update({ public: true })
            .catch(e => console.error(e));
    };
    
    updateBookPostOrder = (bookId, postOrder) => {
        const chapterIds = postOrder.map(post => post.id);
        return db.collection('books')
            .doc(bookId)
            .update({
                chapterIds
            }).then(() => {
                const promises = [];
                postOrder.forEach((post) => {
                    promises.push(
                        db.collection('posts')
                            .doc(post.id)
                            .update({
                                bookPosition: chapterIds.indexOf(post.id) + 1
                            })
                    );
                });

                return Promise.all(promises);
            })
            .catch(e => console.error(e));
    };
    
    deletePost = (postId) => {
        return db.collection('posts')
            .doc(postId)
            .delete()
            .catch(e => console.error(e));
    };
    
    deleteBook = (bookId) => {
        return db.collection('books')
            .doc(bookId)
            .delete()
            .catch(e => console.error(e));
    };
    
    followAuthor = (authorId, user) => {
        return db.collection('users')
            .doc(user.id)
            .update({
                following: firebase.firestore.FieldValue.arrayUnion(authorId)
            })
            .then(() => {
                return db.collection('users')
                    .doc(authorId)
                    .update({
                        followers: firebase.firestore.FieldValue.arrayUnion(user.id)
                    });
            })
            .catch(e => console.error(e));
    };
    
    unfollowAuthor = (authorId, user) => {
        return db.collection('users')
            .doc(user.id)
            .update({
                following: firebase.firestore.FieldValue.arrayRemove(authorId)
            })
            .then(() => {
                return db.collection('users')
                    .doc(authorId)
                    .update({
                        followers: firebase.firestore.FieldValue.arrayRemove(user.id)
                    });
            })
            .catch(e => console.error(e));
    };
    
    getFollowing = (authorId) => {
        return funcs.httpsCallable('getFollowing')({ authorId })
            .then(({ data }) => {
                return data;
            })
            .catch(e => console.error(e));
    };
    
    getFollowers = (authorId) => {
        return funcs.httpsCallable('getFollowers')({ authorId })
            .then(({ data }) => {
                return data;
            })
            .catch(e => console.error(e));
    };

    getFlashGame = () => {
        return funcs.httpsCallable('getFlashGame')()
            .then(({ data }) => {
                return data;
            }).catch(e => console.error(e));
    };

    getPhotoBoothGame = () => {
        return funcs.httpsCallable('getPhotoBoothGame')()
            .then(({ data }) => {
                return data;
            }).catch(e => console.error(e));
    };

    getCharacterActGame = () => {
        return funcs.httpsCallable('getCharacterActGame')()
            .then(({ data }) => {
                return data;
            }).catch(e => console.error(e));
    };

    getRemainingPlays = () => {
        return funcs.httpsCallable('getPlaysRemainingForGames')()
            .then(({ data }) => {
                return data;
            }).catch(e => console.error(e));
    };

    subscribeToPlus = async (userId) => {
        const doc = await db
            .collection('users')
            .doc(userId)
            .collection('checkout_sessions')
            .add({
                price: 'price_1IVEo5GITM3eFOq6aMC03B5A',
                success_url: window.location.origin,
                cancel_url: window.location.origin
            });

            doc.onSnapshot(async (snap) => {
                const { error, sessionId } = snap.data();

                if (error) {
                    alert(`An error occured: ${error.message}`);
                }

                if (sessionId) {
                    const stripe = await loadStripe('pk_live_GKQXUe0PXdwXZQSpM4wUHIvi');
                    stripe.redirectToCheckout({ sessionId });
                }
            });
    };

    subscribeToPro = async (userId) => {
        const doc = await db
            .collection('users')
            .doc(userId)
            .collection('checkout_sessions')
            .add({
                price: 'price_1H7S4OGITM3eFOq6MFuA2yVD',
                success_url: window.location.origin,
                cancel_url: window.location.origin
            });

            doc.onSnapshot(async (snap) => {
                const { error, sessionId } = snap.data();

                if (error) {
                    alert(`An error occured: ${error.message}`);
                }

                if (sessionId) {
                    const stripe = await loadStripe('pk_live_GKQXUe0PXdwXZQSpM4wUHIvi');
                    stripe.redirectToCheckout({ sessionId });
                }
            });
    };

    redirectToCustomerPortal = async () => {
        const functionRef = firebase
            .app()
            .functions('europe-west2')
            .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
        const { data } = await functionRef({ returnUrl: window.location.origin });
        window.location.assign(data.url);
    };
}

export default NetworkManager;
