import React, { useState } from 'react';
import styled from 'styled-components';

import NetworkManager from '../classes/NetworkManager';
import { useStore } from '../store';

import { Button, SecondaryButton } from '../components/styledComponents';

const Follow = ({ authorId, authorPenName }) => {
    const { state } = useStore();
    const [isFollowing, setIsFollowing] = useState(state.user.following.includes(authorId));

    const nm = new NetworkManager();

    const handleClick = () => {
        if (isFollowing) {
            nm.unfollowAuthor(authorId, state.user);
            setIsFollowing(false);
        } else {
            nm.followAuthor(authorId, state.user);
            setIsFollowing(true);
        }
    };

    if (isFollowing) {
        return <SecondaryButton onClick={handleClick}>Unfollow</SecondaryButton>;
    } else {
        return <SecondaryButton onClick={handleClick}>Follow</SecondaryButton>;
    }
};

export default Follow;
