const GameTypes = {
    flash: "flash",
    noBackspace: "noBackspace",
    photobooth: "photoFiction",
    characterAct: "characterAct"
}

class Game {
    id = null;
    type = null;
    name = null;
    benefit = null;
    description = null;
    color = null;
    imageUrl = null;

    constructor({ id, type, name, benefit, description, color, imageUrl }) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.benefit = benefit;
        this.description = description;
        this.color = color;
        this.imageUrl = imageUrl;
    }
};

export { GameTypes };

export default Game;