import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { useStore } from '../store';

import { HideOnDesktop } from './styledComponents';
import NavBar from './navbar';
import BottomNavBar from './bottomNavBar';

import Logo from '../assets/Logo.svg';
import Glyph from '../assets/Glyph.svg';

const StyledHeader = styled.header`
    display: flex;
    align-items: center;
    height: 80px;
`;

const Container = styled.div`
    width: 100%;
    max-width: 1220px;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
`;

const StyledLogo = styled(Link)`
    display: inline-flex;
    align-items: center;
`;

const LogoImage = styled.img`
    max-width: 150px;

    @media (max-width: 700px) {
        display: none;
    }
`;

const GlyphImage = styled.img`
    max-width: 36px;

    @media (min-width: 700px) {
        display: none;
    }
`;

const Header = () => {
    const { pathname } = useLocation();
    const { state } = useStore();
    
    return (
        ['signup', 'signin', 'forgotten-password'].includes(pathname.split('/')[1]) ? null : <StyledHeader>
            <Container>
                <StyledLogo to="/play">
                    <LogoImage src={Logo} alt="Orton logo" />
                    <GlyphImage src={Glyph} alt="Orton logo" />
                </StyledLogo>
                { state.user && <NavBar /> }
            </Container>
            { state.user && <HideOnDesktop><BottomNavBar /></HideOnDesktop> }
        </StyledHeader>
    );
};

export default Header;
