import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IntercomProvider } from 'react-use-intercom';

import NetworkManager from './classes/NetworkManager';
import { useStore } from './store';

import Header from './components/header';
import SignUp from './pages/signup';
import SignIn from './pages/signin';
import ForgottenPassword from './pages/forgottenPassword';
import Read from './pages/read';
import ReadChapter from './pages/readChapter';
import NewPost from './pages/newPost';
import Notifications from './pages/notifications';
import Write from './pages/write';
import Play from './pages/play';
import Profile from './pages/profile';
import Game from './pages/game';
import Book from './pages/book';
import Search from './pages/search';
import Publish from './pages/publish';
import Following from './pages/following';
import Followers from './pages/followers';
import NoMatch from './pages/noMatch';
import ForceQuit from './pages/forceQuit';

const INTERCOM_APP_ID = 'hnpjt595';

const AuthRoute = ({ children, user, publicRoute, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
            return <IntercomProvider
                appId={INTERCOM_APP_ID}
                autoBoot={true}
                autoBootProps={{
                    userId: user.id,
                    email: user.email,
                    name: user.name,
                }}
            >
                {children}
            </IntercomProvider>;
        } else if (publicRoute) {
            return children;
        } else {
            return <Redirect
                to={{
                pathname: '/signin',
                state: { from: location }
                }}
            />;
        }
      }}
    />
  );
};

const LocationObserver = () => {
    const location = useLocation();

    useEffect(() => {
        if (['/play', '/write', '/notifications'].includes(window.location.pathname)) {
            sessionStorage.removeItem('_sp');
        }
    }, [location]);

    return null;
};

const ScrollObserver = () => {
    const scrollListener = () => {
        let scroll = window.scrollY;
        sessionStorage.setItem('_sp', scroll);
    };

    let scrollTimer;

    useEffect(() => {
        window.addEventListener('scroll', scrollListener);

        scrollTimer = setTimeout(() => {
            const _sp = sessionStorage.getItem('_sp');
            if (_sp) {
                window.scrollTo(0, _sp);
            }
        }, 750);

        return () => {
            window.removeEventListener('scroll', scrollListener);

            if (scrollTimer) {
                window.clearTimeout(scrollTimer);
            }
        };
    });

    return null;
};

const AppRouter = () => {
    const { state, dispatch } = useStore();
    const [observer, setObserver] = useState(false);

    const user = state.user;

    useEffect(() => {
        const nm = new NetworkManager();

        if (!observer) {
            nm.setupAuthObserver(dispatch);
            setObserver(true);
        }
    }, [observer, user]);

    return observer && (
        <Router>
            <LocationObserver />
            <Helmet>
                <script type="text/javascript">
                    {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                    heap.load("986308533");`}
                </script>
            </Helmet>
            <Switch>
                <Route path="/force-quit" exact>
                    <ForceQuit />
                </Route>
                <Route path="/signup" exact>
                    { user ? <Redirect to="/play" /> : <SignUp /> }
                </Route>
                <Route path="/signin" exact>
                    { user ? <Redirect to="/play" /> : <SignIn /> }
                </Route>
                <Route path="/forgotten-password" exact>
                    { user ? <Redirect to="/play" /> : <ForgottenPassword /> }
                </Route>
                <AuthRoute path="/" exact>
                    <Redirect to="/play" />
                </AuthRoute>
                <AuthRoute path="/read" exact user={user}>
                    <ScrollObserver />
                    <Header />
                    <Read />
                </AuthRoute>
                <AuthRoute path="/read/:chapter" exact user={user}>
                    <Header />
                    <ReadChapter />
                </AuthRoute>
                <AuthRoute path="/read/book/:bookId" exact user={user}>
                    <Header />
                    <Book />
                </AuthRoute>
                <AuthRoute path="/new" exact user={user}>
                    <NewPost />
                </AuthRoute>
                <AuthRoute path="/new/:gameType" exact user={user}>
                    <NewPost />
                </AuthRoute>
                <AuthRoute path="/write" exact user={user}>
                    <ScrollObserver />
                    <Header />
                    <Write />
                </AuthRoute>
                <AuthRoute path="/notifications" exact user={user}>
                    <ScrollObserver />
                    <Header />
                    <Notifications />
                </AuthRoute>
                <AuthRoute path="/play" exact user={user}>
                    <Header />
                    <Play />
                </AuthRoute>
                <AuthRoute path="/play/:gameType" exact user={user}>
                    <ScrollObserver />
                    <Header />
                    <Game />
                </AuthRoute>
                <AuthRoute path="/profile" exact user={user}>
                    <Header />
                    <Profile />
                </AuthRoute>
                <AuthRoute path="/profile/:authorId" exact user={user} publicRoute>
                    <Header />
                    <Profile />
                </AuthRoute>
                <AuthRoute path="/search" exact user={user}>
                    <Header />
                    <Search />
                </AuthRoute>
                <AuthRoute path="/publish" exact user={user}>
                    <Header />
                    <Publish />
                </AuthRoute>
                <AuthRoute path="/following/:authorId" exact user={user}>
                    <ScrollObserver />
                    <Header />
                    <Following />
                </AuthRoute>
                <AuthRoute path="/followers/:authorId" exact user={user}>
                    <ScrollObserver />
                    <Header />
                    <Followers />
                </AuthRoute>
                <AuthRoute path="*" user={user}>
                    <Header />
                    <NoMatch />
                </AuthRoute>
            </Switch>
        </Router>
    );
};

export default AppRouter;
