import React, { useState } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

import { useStore } from '../store';

import { Button } from '../components/styledComponents';
import Icon from '../components/icon';

import OrtonPlusLogo from '../assets/img_logo_plus.png';
import OrtonProLogo from '../assets/img_logo_pro.png';
import ProImage from '../assets/img_pro.jpg';
import PlusImage from '../assets/img_plus.jpeg';
import NetworkManager from '../classes/NetworkManager';

const ModalBackground = styled.div`
    background: var(--modal-background);
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    top: 0;
    left: 0;
    padding: 10px;
    box-sizing: border-box;
`;

const StyledModal = styled(animated.div)`
    position: relative;
    border-radius: 20px;
    background: url(${({ subscriptionLevel }) => subscriptionLevel === 1 ? PlusImage : ProImage});
    background-position: right;
    background-size: cover;
    box-shadow: var(--shadow);
    width: 100%;
    max-width: ${({ mWidth }) => mWidth ? `${mWidth}px` : 'inherit'};
    max-height: ${({ mHeight }) => mHeight ? `${mHeight}px` : 'inherit'};
    overflow-y: scroll;
    color: var(--white);

    &::-webkit-scrollbar {
        display: none;
    }
`;

const ColorLayer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: .8rem;
    box-sizing: border-box;
    background-color: ${({ subscriptionLevel }) => subscriptionLevel === 1 ? `rgba(124, 61, 149, .8)` : `rgba(28, 12, 34, .85)`};
    color: var(--white);

    @media (max-width: 700px) {
        max-height: 500px;
        overflow-y: scroll;
    }
`;

const SubscriptionModalFooter = styled.div`
    background-color: ${({ subscriptionLevel }) => subscriptionLevel === 1 ? `var(--subscribe-light)` : `var(--subscribe-pro-light)`};
    padding: 10px 15px;
    position: sticky;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SubscriptionButton = styled(Button)`
    background-color: var(--white);
    color: var(--subscribe);
    border: var(--white);
    padding: 10px 46px;
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
        font-weight: 400;
        text-decoration: none;
        font-size: 14px;
    }
`;

const Logo = styled.img`
    max-width: 180px;
    margin: 30px 0;
    -webkit-transform: translate3d(0,0,0);
`;

const SubscriptionBenefits = styled.ul`

`;

const SubscriptionBenefit = styled.li`
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    padding: 16px 0;
    font-weight: 600;
    max-width: 300px;

    &:last-of-type {
        border-bottom: none;
        margin-bottom: 20px;
    }

    img {
        filter: brightness(0) invert(100%);
        margin-right: 20px;
    }

    @media (max-width: 700px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const Subheading = styled.p`
    max-width: 280px;
    text-align: center;
    line-height: 1.4;
`;

const Close = styled.div`
    position: absolute;
    left: 30px;
    top: 40px;
    transform: rotate(45deg);
    cursor: pointer;

    img {
        filter: brightness(0) invert(100%);
        margin-right: 20px;
    }

    @media (max-width: 700px) {
        left: 20px;
    }
`;

const Disclaimer = styled.p`
    max-width: 300px;
    color: var(--white);
    font-size: 12px;
    margin-top: 8px;
    line-height: 1.2;
    font-weight: 600;

    a {
        color: var(--white);
    }

    &:last-of-type {
        margin-bottom: 20px;
    }

    @media (max-width: 700px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const ProBenefits = () => (
    <SubscriptionBenefits>
        <SubscriptionBenefit>
            <Icon icon="checkCircle" />
            Publish a book on Amazon, Apple Books, Google Play, and in paperback
        </SubscriptionBenefit>
        <SubscriptionBenefit>
            <Icon icon="checkCircle" />
            All profits from your book sales go to you
        </SubscriptionBenefit>
        <SubscriptionBenefit>
            <Icon icon="checkCircle" />
            We'll create a beautiful book cover for your published book
        </SubscriptionBenefit>
        <SubscriptionBenefit>
            <Icon icon="checkCircle" />
            Play games as many times as you like
        </SubscriptionBenefit>
        <SubscriptionBenefit>
            <Icon icon="checkCircle" />
            Add book covers to your Orton books
        </SubscriptionBenefit>
        <Disclaimer>Read our <a href="https://www.orton.io/terms-and-conditions" target="_blank">Terms of Service</a> and <a href="https://www.iubenda.com/privacy-policy/7821898" target="_blank">Privacy Policy</a> for more information.</Disclaimer>
    </SubscriptionBenefits>
);

const PlusBenefits = () => (
    <SubscriptionBenefits>
        <SubscriptionBenefit>
            <Icon icon="checkCircle" />
            Play games as many times as you like
        </SubscriptionBenefit>
        <SubscriptionBenefit>
            <Icon icon="checkCircle" />
            Support the development of Orton
        </SubscriptionBenefit>
        <SubscriptionBenefit>
            <Icon icon="checkCircle" />
            Cancel your subscription at any time
        </SubscriptionBenefit>
        <Disclaimer>Read our <a href="https://www.orton.io/terms-and-conditions" target="_blank">Terms of Service</a> and <a href="https://www.iubenda.com/privacy-policy/7821898" target="_blank">Privacy Policy</a> for more information.</Disclaimer>
    </SubscriptionBenefits>
);

const Modal = ({ children, mWidth, mHeight, close, subheading, subscriptionLevel }) => {
    const { state } = useStore();
    const [modalVisible, setModalVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const nm = new NetworkManager();
    const transitions = useTransition(modalVisible, null, {
        from: { opacity: 0, transform: "translateY(50px)" },
        enter: { opacity: 1, transform: "translateY(0px)" },
        leave: { opacity: 0, transform: "translateY(50px)" }
    });

    const handleButtonClick = () => {
        setIsLoading(true);
        if (subscriptionLevel === 1) {
            nm.subscribeToPlus(state.user.id);
        } else if (subscriptionLevel === 2) {
            nm.subscribeToPro(state.user.id);
        }
    };

    return (
        <ModalBackground onClick={close}>
            { transitions.map(({ item, key, props: style }) => (
                item && <StyledModal key={key} style={style} mWidth={mWidth} mHeight={mHeight} onClick={e => e.stopPropagation()} subscriptionLevel={subscriptionLevel}>
                    <ColorLayer subscriptionLevel={subscriptionLevel}>
                        <Close onClick={close}><Icon icon="plusCircle" size="24" /></Close>
                        <Logo src={subscriptionLevel === 1 ? OrtonPlusLogo : OrtonProLogo} />
                        { subheading && <Subheading>{subheading}</Subheading> }
                        { subscriptionLevel === 1 && <PlusBenefits /> }
                        { subscriptionLevel === 2 && <ProBenefits /> }
                        <SubscriptionModalFooter subscriptionLevel={subscriptionLevel}>
                            <SubscriptionButton onClick={handleButtonClick}>
                                { !isLoading && <p>Subscribe</p> }
                                { subscriptionLevel === 1 && !isLoading && <i>£1.99/month</i> }
                                { subscriptionLevel === 2 && !isLoading && <i>£4.99/month</i> }
                                { isLoading && <Icon icon="loadingDark" size={23} /> }
                            </SubscriptionButton>
                        </SubscriptionModalFooter>
                    </ColorLayer>
                </StyledModal>
            ))}
        </ModalBackground>
    );
};

export default Modal;