class Annotation {
    id = null;
    postId = null;
    creationDate = null;
    startId = null;
    endId = null;
    blockId = null;
    annotatedText = null;
    message = null;
    commentCount = null;
    likes = [];
    authorId = null;
    authorPenName = null;
    authorSubscription = null;
    authorPicture = null;

    constructor({ id, postId, creationDate, startId, endId, blockId, annotatedText, message, commentCount, likes, authorId, authorPenName, authorSubscription, authorPicture }) {
        this.id = id;
        this.postId = postId;
        this.creationDate = creationDate;
        this.startId = startId;
        this.endId = endId;
        this.blockId = blockId;
        this.annotatedText = annotatedText;
        this.message = message;
        this.commentCount = commentCount;
        this.likes = likes;
        this.authorId = authorId;
        this.authorPenName = authorPenName;
        this.authorSubscription = authorSubscription;
        this.authorPicture = authorPicture;
    }
};

export default Annotation;