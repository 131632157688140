import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom'; 
import { animated } from 'react-spring';
import mixpanel from 'mixpanel-browser';
import { useStore } from '../store';

import NetworkManager from '../classes/NetworkManager';

import { Button, SecondaryButton } from '../components/styledComponents';
import SubscriptionModal from '../components/subscriptionModal';
import Icon from '../components/icon';

const Container = styled(animated.div)`
    border-radius: .8rem;
    background: url(${({ background }) => background});
    box-shadow: var(--shadow);
    box-sizing: border-box;
    background-size: cover;
    cursor: ${({ disableLink }) => disableLink ? 'auto' : 'pointer'};
    width: 100%;
    transition: ease-in-out .5s;

    @media (min-width: 700px) {
        &:hover {
            transform: scale(1.05);
        }
    }
`;

const ColorLayer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: .8rem;
    padding: 2rem;
    box-sizing: border-box;
    background-color: ${({ color }) => color ? `rgba(${color.r},${color.g},${color.b},.8)` : 'transparent'};
    color: ${({ color }) => color ? 'var(--white)' : 'var(--text)'};
`;

const Benefit = styled.p`
    font-size: 12px;
    font-weight: 600;
`;

const Title = styled.h2`
    display: block;
    font-weight: 600;
    font-size: 32px;
    margin-top: .5rem;
`;

const Description = styled.p`
    font-size: 16px;
    margin-top: .5rem;
    font-weight: 600;
    line-height: 1.2;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    img {
        margin: 0;
    }

    @media (max-width: 700px) {
        align-items: flex-start;
        flex-direction: column;
    }
`;

const StyledButton = styled(Button)`
    margin-top: 20px;
    max-width: 150px;
    padding: 14px 30px;
    width: 100%;

    @media (max-width: 700px) {
        max-width: none;
        margin-bottom: 20px;
    }
`;

const GetMorePlaysContainer = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 700px) {
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
    }
`;

const GetMorePlays = styled(SecondaryButton)`
    border: var(--white) 2px solid;
    color: var(--white);
    padding: 14px 30px;

    @media (max-width: 700px) {
        max-width: none;
        width: 100%;
    }
`;

const GetMorePlaysCount = styled.p`
    font-weight: 600;
    color: var(--white);
    margin-right: 20px;

    @media (max-width: 700px) {
        font-size: 14px;
        max-width: none;
        margin: 0 auto 10px;
    }
`;

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const GameTile = ({ name, benefit, description, type, color, imageUrl, disableLink, showPlayButton, customButton, ...otherProps }) => {
    const history = useHistory();
    const { state, dispatch } = useStore();
    const [textType, setTextType] = useState(null);
    const [remainingPlays, setRemainingPlays] = useState(null);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    useEffect(() => {
        const nm = new NetworkManager();
        if (!textType && name) {
            setTextType(name.toLowerCase().split(' ').join('_'));
        }
        if (showPlayButton && !remainingPlays) {
            nm.getRemainingPlays().then((res) => {
                setRemainingPlays(res);
            });
        }
    }, [remainingPlays]);

    const handlePlayClick = () => {
        if ((remainingPlays.maximumPlaysPerDay - remainingPlays.remainingPlays) !== remainingPlays.maximumPlaysPerDay) {
            mixpanel.track('Started game', { 'type': textType });
            history.push(`/new/${textType}`);
        } else {
            setShowSubscriptionModal(true);
        }
    };
    
    return (
        <Container
            style={{
                ...otherProps
            }}
            background={imageUrl}
            disableLink={disableLink}
            onClick={!disableLink ? () => history.push(`/play/${textType}`) : () => {}}
        >
            {showSubscriptionModal && ReactDOM.createPortal(
                <SubscriptionModal close={() => setShowSubscriptionModal(false)} subscriptionLevel={1} mWidth="400" />,
                document.getElementById('root')
            )}
            <ColorLayer color={hexToRgb(color)}>
                <Benefit>{benefit}</Benefit>
                <Title>{name}</Title>
                <Description>{description}</Description>
                <ButtonContainer>
                    { showPlayButton && <StyledButton onClick={handlePlayClick} disabled={!remainingPlays}>{remainingPlays ? 'Play' : <Icon icon="loading" size={23} />}</StyledButton> }
                    { showPlayButton && <GetMorePlaysContainer>
                        { (remainingPlays && state.user.subscription === 0) && <GetMorePlaysCount>Daily plays remaining: {remainingPlays.remainingPlays}/{remainingPlays.maximumPlaysPerDay}</GetMorePlaysCount> }
                        { (remainingPlays && state.user.subscription > 0) && <GetMorePlaysCount>Maximum plays per day: Unlimited</GetMorePlaysCount> }
                        { state.user.subscription === 0 && <GetMorePlays onClick={() => setShowSubscriptionModal(true)} disabled={!remainingPlays}>{remainingPlays ? 'Get more plays' : <Icon icon="loading" size={23} />}</GetMorePlays> }
                    </GetMorePlaysContainer> }
                    { customButton }
                </ButtonContainer>
            </ColorLayer>
        </Container>
    );
};

export default GameTile;
