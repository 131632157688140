import React from 'react';
import styled from 'styled-components';

import Icon from './icon';

const Button = styled.button`
    border: none;
    border-radius: 6px;
    background-color: #eee;
    color: #191919;
    padding: 8px 16px;
    font-weight: 600;
    font-family: 'Whitney', Helvetica, Arial, sans-serif;
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    width: 100%;
    font-size: 18px;
    min-height: 60px;
    margin-bottom: 20px;

    img {
        margin-right: 12px;
    }

    &:last-of-type {
        margin-right: 0;
    }
`;

const GoogleButton = ({ handleClick, text }) => <Button onClick={handleClick}><Icon icon="google" size={25} />{text ? text : 'Continue with Google'}</Button>

export default GoogleButton;