import React, { createContext, useContext, useReducer } from 'react';

const StoreContext = createContext();
const initialState = { user: null, theme: null };

const reducer = (state, action) => {
    switch(action.type) {
      case "add_user":
        localStorage.setItem('ou', JSON.stringify(action.user));
        return { user: action.user };
      case "empty_user":
        localStorage.removeItem('ou');
        return { user: null };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
  
    return (
      <StoreContext.Provider value={{ state, dispatch }}>
        {children}
      </StoreContext.Provider>
    );
};

export const useStore = () => useContext(StoreContext);
