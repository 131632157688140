import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
// import MediumEditor from 'medium-editor';
import autosize from 'autosize';
import { useHistory, useParams } from 'react-router-dom';
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';

import 'draft-js/dist/Draft.css';

import NetworkManager from '../classes/NetworkManager';
import { useStore } from '../store';
import { trunc } from '../globalFunctions';

import { Container, Button, ErrorText, SecondaryButton } from '../components/styledComponents';
import Icon from '../components/icon';
import Modal, { ModalFooter } from '../components/modal';
import NewGameTile from '../components/newGameTile';
import { SmallCondensedBook } from '../components/condensedBook';

const StyledContainer = styled(Container)`
    max-width: 850px;
`;

const Form = styled.form`
    padding-top: 60px;

    .content[placeholder]:empty:before {
        content: attr(placeholder);
        color: var(--placeholder); 
    }

    & > .DraftEditor-root {
        font-size: 18px;
        padding-bottom: 200px;
        line-height: 1.56;
        color: var(--text);
    }

    .public-DraftStyleDefault-block {
        margin-bottom: 20px;
    }
`;

const EnterTitle = styled.textarea`
    border: 0;
    width: 100%;
    padding: 0;
    font-size: 48px;
    border-radius: 0;
    padding-right: 30px;
    resize: none;
    display: block;
    margin-bottom: 20px;
    font-weight: 600;
    background: var(--background);
    color: var(--text);

    &:placeholder {
        color: var(--placeholder);
    }

    &:focus {
        outline: 0;
    }

    @media (max-width: 700px) {
        font-size: 32px;
    }
`;

const EnterContent = styled.textarea`
    resize: none;
    line-height: 38px;
    background: var(--background);
    color: var(--text);
    margin: 0 auto 0;
    padding-bottom: 200px;
    display: block;
    font-size: 18px;
    line-height: 1.56;
    text-align: left;
    cursor: text;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    width: 100%;
    outline: none;
    border: none;

    b { font-weight: 600; }
    u { text-decoration: underline; }
    i { font-style: italic; }

    &::-webkit-scrollbar {
        display: none;
    }

    &::after {
        color: var(--placeholder);
        line-height: 2.2;
        font-style: normal;
    }

    @media (max-width: 700px) {
        font-size: 16px;
    }
`;

const ControlBar = styled.aside`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;

    @media (max-width: 700px) {
        flex-direction: column;
    }
`;

const AddMetaBar = styled.div`
    display: flex;
    flex-direction: row;
    box-shadow: var(--shadow);
    border-radius: 20px;
    position: relative;

    @media (max-width: 700px) {
        flex-direction: column;
        width: 100%;
    }
`;

const CloseButton = styled.div`
    position: absolute;
    left: -70px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @media (max-width: 700px) {
        display: none;
    }

    img {
        transform: rotate(45deg);
        width: 20px;
        height: 20px;
    }
`;

const Control = styled.div`
    font-size: 14px;
    padding: 16px;
    font-weight: 600;
    color: var(--accent);
    cursor: pointer;
    display: flex;
    align-items: center;

    @media (max-width: 700px) {
        padding 10px 16px;
    }

    img {
        margin-right: 6px;
    }
`;

const ModalItem = styled.div`
    padding: 12px 24px;
    font-weight: 600;
    color: var(--accent);
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;

    &:first-of-type {
        padding-top: 24px;
    }

    &:last-of-type {
        padding-bottom: 24px;
    }

    &:hover {
        background-color: var(--background-highlight);
    }
`;

const BookModalItem = styled(ModalItem)`

`;

const ModalSelected = styled.p`
    font-size: 14px;
    font-weight: 600;
`;

const ButtonContainer = styled.div`
    @media (max-width: 700px) {
        margin-top: 20px;
        width: 100%;

        button {
            width: 100%;
        }
    }
`;

const Exit = styled.div`
    padding: 32px;

    p {
        margin-bottom: 20px;
        line-height: 1.2;
    }

    @media (max-width: 700px) {
        padding: 20px
    }
`;

const NoBooks = styled.p`
    padding: 20px 24px;
    line-height: 1.5;
`;

const editorStyleMap = {
    'BOLD': {
        fontWeight: 600
    }
};

const NewPost = () => {
    const history = useHistory();
    const { state } = useStore();
    const { gameType } = useParams();

    const [books, setBooks] = useState([]);
    const [genres, setGenres] = useState([]);
    const [types, setTypes] = useState([]);
    const [games, setGames] = useState([]);
    const [gameDetails, setGameDetails] = useState(null);
    const [typesAndGenresRequested, setTypesAndGenresRequested] = useState(false);
    const [gamesRequested, setGamesRequested] = useState(false);
    const [showGenreModal, setShowGenreModal] = useState(false);
    const [showTypeModal, setShowTypeModal] = useState(false);
    const [showBookModal, setShowBookModal] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [booksRequested, setBooksRequested] = useState(false);
    const [gameDetailsRequested, setGameDetailsRequested] = useState(false);
    const [error, setError] = useState('');
    const [disableEditing, setDisableEditing] = useState(false);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [genre, setGenre] = useState(null);
    const [type, setType] = useState(null);
    const [book, setBook] = useState(null);

    const titleRef = useRef(null);
    const contentRef = useRef(null);

    let editor;

    const handleSubmit = (e, publicPost) => {
        e.preventDefault();
        const nm = new NetworkManager();
        const editorContent = editorState.getCurrentContent();

        if (title.length === 0) {
            setError('Your writing must have a title');
        } else if (editorContent.getPlainText().length === 0) {
            setError('Your writing must have some content');
        } else if (!genre || !type) {
            setError('You must select a genre and type');
        } else if (editorContent.getPlainText().match(/[-a-zA-Z0-9@:%_\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g)) {
            setError('We do not allow you to add links in your content');
        } else {
            nm.createPost({
                title,
                content: editorContent.getPlainText(),
                richContent: convertToRaw(editorContent),
                genre,
                type,
                bookId: (book && book.id) || null,
                user: state.user,
                publicPost: publicPost
            }).then(() => {
                mixpanel.track('Submitted post', { type: gameType || 'chapter' });
                history.push('/write');
            });
        }
    };

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);



        if (newState) {
            setEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    };

    useEffect(() => {
        const nm = new NetworkManager();
        autosize(titleRef.current);
        autosize(contentRef.current);

        if ((genres.length === 0 || types.length === 0) && !typesAndGenresRequested) {
            nm.getGenres().then((genreList) => {
                setGenres(genreList);
                return nm.getTypes();
            }).then((typeList) => {
                setTypesAndGenresRequested(true);
                setTypes(typeList);
            });
        }
        if (gameType && games.length === 0 && !gamesRequested) {
            nm.getGames().then((gamesList) => {
                setGamesRequested(true);
                setGames(gamesList);
            }).then(() => {
                if (!gameDetailsRequested && !gameDetails) {
                    setGameDetailsRequested(true);
                    if (gameType === 'flash') return nm.getFlashGame();
                    if (gameType === 'photo_booth') return nm.getPhotoBoothGame();
                    if (gameType === 'character_act') return nm.getCharacterActGame();
                } else {
                    return Promise.resolve(gameDetails);
                }
            }).then((gameDetails) => {
                setGameDetails(gameDetails);
            });
        }
        if (books.length === 0 && !booksRequested) {
            nm.getBooks({ user: state.user }).then((bookList) => {
                setBooksRequested(true);
                setBooks(bookList || []);
            });
        }
    }, [games, gamesRequested]);

    return (
        <StyledContainer>
            <Helmet><title>New</title></Helmet>
            {showGenreModal && ReactDOM.createPortal(
                <Modal mWidth="350" mHeight="400" close={() => setShowGenreModal(false)}>
                    {genres.map((genre) => <ModalItem key={genre.id} onClick={() => {
                        setGenre(genre);
                        setShowGenreModal(false);
                    }}>{genre.name}</ModalItem>)}
                </Modal>,
                document.getElementById('root')
            )}
            {showTypeModal && ReactDOM.createPortal(
                <Modal mWidth="350" mHeight="400" close={() => setShowTypeModal(false)}>
                    {types.map((type) => <ModalItem key={type.id} onClick={() => {
                        setType(type);
                        setShowTypeModal(false);
                    }}>{type.singularName}</ModalItem>)}
                </Modal>,
                document.getElementById('root')
            )}
            {showBookModal && ReactDOM.createPortal(
                <Modal mWidth="400" mHeight="500" close={() => setShowBookModal(false)}>
                    {books.map((b) => <BookModalItem key={b.id} onClick={() => {
                        setBook(b);
                        setShowBookModal(false);
                    }}>
                        <SmallCondensedBook {...b} />
                    </BookModalItem>)}
                    {books.length === 0 && <NoBooks>You have no books. Create a book before adding your writing.</NoBooks>}
                </Modal>,
                document.getElementById('root')
            )}
            {showExitModal && ReactDOM.createPortal(
                <Modal mWidth="400" mHeight="450">
                    <Exit>
                        {(gameType && state.user.subscription === 0)
                            ? <p>{editorState.getCurrentContent().getPlainText().length > 0 ? "You'll lose your writing, you won't be able to get it back and y" : "Y"}ou can only play each of the Orton games once a day. You have {gameDetails && gameDetails.remainingPlays} play{gameDetails && gameDetails.remainingPlays !== 1 && 's'} remaining.</p>
                            : <p>Are you sure you want to leave without saving your content?</p>}
                        <SecondaryButton onClick={() => setShowExitModal(false)}>Continue editing</SecondaryButton>
                        <Button onClick={() => {
                            setShowExitModal(false);
                            mixpanel.track('Abandoned post', { type: gameType || 'chapter' });
                            history.goBack();
                        }}>Yes, exit</Button>
                    </Exit>
                </Modal>,
                document.getElementById('root')
            )}
            <ControlBar>
                <AddMetaBar>
                    <CloseButton onClick={() => {
                        if (editorState.getCurrentContent().getPlainText().length > 0 || gameType) {
                            setShowExitModal(true);
                        } else {
                            history.goBack();
                        }
                    }}><Icon icon="plus" /></CloseButton>
                    <Control onClick={() => setShowGenreModal(true)}><Icon icon="plusCircle" />{(genre && genre.name) || 'Add genre'}</Control>
                    <Control onClick={() => setShowTypeModal(true)}><Icon icon="plusCircle" />{(type && type.singularName) || 'Add type'}</Control>
                    <Control onClick={() => setShowBookModal(true)}><Icon icon="plusCircle" />{(book && trunc(book.title, 18)) || 'Add to book'}</Control>
                </AddMetaBar>
                <ButtonContainer>
                    <Button onClick={(e) => handleSubmit(e, true)}>Save & post</Button>
                </ButtonContainer>
            </ControlBar>
            { gameType
                && <NewGameTile
                    {...games.filter((game) => game.name.toLowerCase().split(' ').join('_') === gameType)[0]}
                    {...gameDetails} limit={['photo_booth', 'flash', 'character_act'].includes(gameType)}
                    setDisableEditing={setDisableEditing}
                /> }
            <Form onSubmit={handleSubmit}>
                { error && <ErrorText><Icon icon="warning" />{error}</ErrorText> }
                <EnterTitle
                    rows='1'
                    name="title"
                    placeholder="Enter a title"
                    onChange={(e) => setTitle(e.target.value)}
                    ref={titleRef}>
                </EnterTitle>
                <Editor
                    editorState={editorState}
                    customStyleMap={editorStyleMap}
                    onChange={setEditorState}
                    handleKeyCommand={handleKeyCommand}
                    placeholder="Write something..."
                    readOnly={disableEditing}
                />
            </Form>
        </StyledContainer>
    );
};

export default NewPost;
