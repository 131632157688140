import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import NetworkManager from '../classes/NetworkManager';

const Fullscreen = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 700px) {
        height: auto;
    }
`;

const ForceQuit = () => {
    const history = useHistory();
    const nm = new NetworkManager();

    useEffect(() => {
        setTimeout(() => {
            nm.logout().then(() => {
                localStorage.clear();
                document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                history.push('/signup');
            });
        }, 2000);
    }, []);

    return (
        <Fullscreen>
            
        </Fullscreen>
    );
};

export default ForceQuit;
