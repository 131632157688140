import React, { useState, useEffect } from 'react';
import { useTrail } from 'react-spring';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';
import { useStore } from '../store';

import { Container, HeadingContainer, Heading } from '../components/styledComponents';

import Notification from '../components/notification';
import Illustration from '../components/illustration';

const StyledContainer = styled(Container)`
    max-width: 778.4px;
`;

const StyledHeadingContainer = styled(HeadingContainer)`
    padding-left: 1.2rem;
    padding-right: 1.2rem;
`;

const Notifications = () => {
    const { state } = useStore();
    const [notifications, setNotifications] = useState([]);
    const [notificationsRequested, setNotificationsRequested] = useState(false);

    const config = { mass: 1, tension: 120, friction: 14 };
    const notificationTrail = useTrail(notifications.length, {
        config,
        from: { opacity: 0, y: '100%' },
        to: { opacity: 1, y: '0%' }
    });

    useEffect(() => {
        const nm = new NetworkManager();
        if (notifications.length === 0 && !notificationsRequested) {
            nm.getNotifications({ user: state.user }).then((notificationList) => {
                setNotificationsRequested(true);
                setNotifications(notificationList);
            });
        }
    });

    return (
        <StyledContainer>
            <Helmet><title>Notifications</title></Helmet>
            <StyledHeadingContainer>
                <Heading>Notifications</Heading>
            </StyledHeadingContainer>
            {notifications.length > 0 && notificationTrail.map(({ ...styleProps }, i) => (
                <React.Fragment key={notifications[i].id}>
                    <Notification {...notifications[i]} {...styleProps} />
                </React.Fragment>
            ))}
            {notifications.length == 0 && notificationsRequested && <Illustration illustration="collaboration" title="No notifications" text="You haven't recieved any notifications yet." />}
        </StyledContainer>
    );
};

export default Notifications;
