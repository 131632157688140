const SubscriptionTypes = {
    none: 0,
    plus: 1
};

class Author {
    id = null;
    bio = null;
    name = null;
    penName = null;
    followers = [];
    following = [];
    subscription = null;
    email = null;
    likes = [];
    bookmarks = [];
    communicationPreferences = null;
    picture = null;

    constructor({ id, bio, name, penName, followers, following, subscription, email = null, likes, picture, bookmarks, communicationPreferences }) {
        this.id = id;
        this.bio = bio;
        this.name = name;
        this.penName = penName;
        this.followers = followers;
        this.following = following;
        this.subscription = subscription;
        this.email = email;
        this.likes = likes;
        this.bookmarks = bookmarks;
        this.communicationPreferences = communicationPreferences;
        this.picture = picture;
    }
};

export default Author;