import React from 'react';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';

import { Container } from '../components/styledComponents';
import PostDetails, { MetaDataItem } from '../components/postDetails';
import Icon from '../components/icon';

const StyledContainer = styled(Container)`
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: px solid var(--primary-highlight);
`;

const Text = styled.p`
    font-size : 16px;
    margin-top: 20px;
`;

const RepliesText = styled.p`
    color: var(--accent);
    font-size: 14px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    img {
        margin-right: 10px;
    }
`;

const Comment = ({ id, creationDate, message, authorId, authorPenName, authorPicture, authorSubscription, commentCount, handleReplies }) => {
    return (
        <StyledContainer>
            <PostDetails metaItems={[
                <MetaDataItem key={creationDate}>{formatDistanceToNow(new Date(creationDate))} ago</MetaDataItem>
            ]} author={{ authorPenName, authorId, authorPicture }} />
            <Text>{message}</Text>
            <RepliesText onClick={handleReplies}><Icon icon="message" />{`${commentCount} ${commentCount.length == 1 ? 'reply' : 'replies'}`}</RepliesText>
        </StyledContainer>
    );
};

export default Comment;