import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTrail } from 'react-spring';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';

import { Container, HeadingContainer, Heading } from '../components/styledComponents';

import CondensedPost, { CondensedPostSkeleton } from '../components/condensedPost';
import Dropdown, { DropdownHolder } from '../components/dropdown';
import GameTile from '../components/gameTile';
import Illustration from '../components/illustration';

const FiltersContainer = styled.div`
    padding: 40px 0;
    display: flex;
    justify-content: flex-start;
`;

const Game = () => {
    const { gameType } = useParams();
    const [posts, setPosts] = useState([]);
    const [books, setBooks] = useState([]);
    const [genres, setGenres] = useState([]);
    const [types, setTypes] = useState([]);
    const [game, setGame] = useState({});
    const [selectedGenre, setSelectedGenre] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [postsRequested, setPostsRequested] = useState(false);
    const [typesAndGenresRequested, setTypesAndGenresRequested] = useState(false);
    const [gameRequested, setGameRequested] = useState(false);

    const nm = new NetworkManager();

    const config = { mass: 1, tension: 120, friction: 14 };
    const postTrail = useTrail(posts.length, {
        config,
        from: { opacity: 0, y: '100%' },
        to: { opacity: 1, y: '0%' }
    });

    useEffect(() => {
        if (!gameRequested) {
            nm.getGames().then((gamesList) => {
                setGameRequested(true);
                setGame(gamesList.filter((game) => game.name.toLowerCase().split(' ').join('_') === gameType)[0]);
            });
        }
        if (posts && posts.length === 0 && !postsRequested && gameRequested) {
            nm.getPosts({ gameId: game.id, genre: selectedGenre === 'All' ? null : selectedGenre, type: selectedType === 'All' ? null : selectedType }).then((postList) => {
                setPostsRequested(true);
                setPosts(postList);
            });
        }
        if ((genres.length === 0 || types.length === 0) && !typesAndGenresRequested) {
            nm.getGenres().then((genreList) => {
                setGenres(genreList);
                return nm.getTypes();
            }).then((typeList) => {
                setTypesAndGenresRequested(true);
                setTypes(typeList);
            });
        }
    });

    const handleFilterChange = (selection, changeType) => {
        if (changeType === 'type') {
            setSelectedType(selection);
            setPostsRequested(false);
            nm.getPosts({ excludeGames: true, genre: selectedGenre, type: selection }).then((postList) => {
                setPostsRequested(true);
                setPosts(postList || []);
            });
        } else if (changeType === 'genre') {
            setSelectedGenre(selection);
            setPostsRequested(false);
            nm.getPosts({ excludeGames: true, genre: selection, type: selectedType }).then((postList) => {
                setPostsRequested(true);
                setPosts(postList || []);
            });
        }
    };

    return (
        <Container>
            { game && <Helmet><title>{game.name}</title></Helmet> }
            <HeadingContainer>
                <GameTile {...game} showPlayButton disableLink />
            </HeadingContainer>
            <FiltersContainer>
                <DropdownHolder>
                    <Dropdown defaultItem="Genre" setOption={(selection) => handleFilterChange(selection, 'genre')} option={selectedGenre} options={['All', ...genres.map((genre) => genre.name)]} />
                </DropdownHolder>
                <DropdownHolder>
                    <Dropdown defaultItem="Type" setOption={(selection) => handleFilterChange(selection, 'type')} option={selectedType} options={['All', ...types.map((type) => type.pluralName)]} />
                </DropdownHolder>
            </FiltersContainer>
            {posts.length > 0 && postTrail.map(({ ...styleProps }, i) => <CondensedPost key={posts[i].id} {...posts[i]} {...styleProps} />)}
            {posts.length === 0 && postsRequested && <Illustration illustration="review" title="No writing" text="There is no writing for your specified filters." />}
            {!postsRequested && Array(3).fill().map((i, k) => <CondensedPostSkeleton key={k} />)}
        </Container>
    );
};

export default Game;
