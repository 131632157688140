import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Icon from '../components/icon';

const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
    width: 100px;
    height: 100px;
    background-color: var(--accent);
    color: var(--background);
`;

const ProfileImage = styled.img`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 25%;
    width: 100px;
    height: 100px;
    background-color: var(--accent);
    color: var(--background);
    object-fit: cover;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    position: relative;
    display: block;
`;

const Initial = styled.p`
    font-weight: 600;
    font-size: 32px;
`;

const ProSealContainer = styled.div`
    position: absolute;
    bottom: -1rem;
    right: -1rem;
`;

const LargeAvatar = ({ name, subscription, picture, id }) => (
    <StyledLink to={`/profile/${id}`}>
        { picture ?
            <ProfileImage src={picture} /> :
            <Container>
                <Initial>{name.split('')[0]}</Initial>
            </Container> }
        { subscription === 2 && <ProSealContainer>
            <Icon icon="proSeal" size={50} />
        </ProSealContainer> }
    </StyledLink>
);

export default LargeAvatar;
