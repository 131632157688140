import React from 'react';
import styled from 'styled-components';

import Heart from '../assets/heart.svg';
import Bell from '../assets/bell.svg';
import Bookmark from '../assets/bookmark.svg';
import Controller from '../assets/controller.svg';
import Search from '../assets/search.svg';
import PenTip from '../assets/pencil-tip.svg';
import Pencil from '../assets/pencil.svg';
import Book from '../assets/book.svg';
import Chevron from '../assets/chevron.svg';
import Google from '../assets/google.svg';
import Plus from '../assets/plus.svg';
import Cog from '../assets/cog.svg';
import SignOut from '../assets/signout.svg';
import PlusCircle from '../assets/plus-circle.svg';
import Menu from '../assets/menu.svg';
import CheckCircle from '../assets/check-circle.svg';
import Eye from '../assets/eye.svg';
import EyeClosed from '../assets/eye_closed.svg';
import Delete from '../assets/delete.svg';
import ExternalLink from '../assets/external-link.svg';
import Message from '../assets/message.svg';
import EditPicture from '../assets/edit-picture.svg';
import Warning from '../assets/warning.svg';
import Options from '../assets/options.svg';
import Loading from '../assets/loading.svg';
import LoadingDark from '../assets/loading_dark.svg';
import ProSeal from '../assets/icon_seal.svg';
import FacebookShare from '../assets/facebook-share.svg';
import TwitterShare from '../assets/twitter-share.svg';
import Share from '../assets/share.svg';
import Draggable from '../assets/draggable.svg';

// https://www.iconfinder.com/search?q=menu

const iconMapping = {
    heart: Heart,
    bell: Bell,
    bookmark: Bookmark,
    controller: Controller,
    search: Search,
    pentip: PenTip,
    pencil: Pencil,
    book: Book,
    chevron: Chevron,
    google: Google,
    plus: Plus,
    cog: Cog,
    signout: SignOut,
    plusCircle: PlusCircle,
    menu: Menu,
    checkCircle: CheckCircle,
    eye: Eye,
    eyeClosed: EyeClosed,
    delete: Delete,
    externalLink: ExternalLink,
    message: Message,
    editPicture: EditPicture,
    warning: Warning,
    options: Options,
    loading: Loading,
    loadingDark: LoadingDark,
    proSeal: ProSeal,
    facebookShare: FacebookShare,
    twitterShare: TwitterShare,
    share: Share,
    draggable: Draggable
};

const StyledIcon = styled.img`
    width: ${({ size }) => size ? size : '16'}px;
    min-height: 23px;
`;

const Icon = ({ icon, size, onClick }) => <StyledIcon src={iconMapping[icon]} size={size} onClick={onClick} />;

export default Icon;
