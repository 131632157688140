import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';
import { useStore } from '../store';

import { Container, HeadingContainer, Heading, Button, SecondaryButton } from '../components/styledComponents';

import GameTile from '../components/gameTile';
import Modal from '../components/modal';
import InputField from '../components/inputField';
import TextArea from '../components/textArea';
import { RToggle } from '../components/toggle';
import SubscriptionModal from '../components/subscriptionModal';
import PublishedBook from '../components/publishedBook';

import Image from '../assets/img_publishing.jpg';

const StyledHeadingContainer = styled(HeadingContainer)`
    display: block;
`;

const Subheading = styled.p`
    margin-top: 20px;
    font-size: 18px;
    line-height: 1.5;
`;

const StyledButton = styled(Button)`
    margin-top: 20px;
    min-height: 46px;
    max-width: 190px;
    padding: 6px 20px;
    background-color: var(--white);
    color: var(--accent);
    border: none;
`;

const Form = styled.form`
    padding: 20px;
`;

const PublishButton = styled(Button)`
    margin-top: 20px;
`;

const Publish = () => {
    const history = useHistory();
    const { state } = useStore();
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [title, setTitle] = useState('');
    const [synopsis, setSynopsis] = useState('');
    const [bookCover, setBookCover] = useState('');
    const [adult, setAdult] = useState(false);
    const [publishedBooks, setPublishedBooks] = useState([{ id: 1, state: 1, title: 'Longer book title - the sequel' }, { id: 2, state: 1, title: 'Book 2' }]);
    const [publishedBooksRequested, setPublishedBooksRequested] = useState(false);
    // const [genres, setGenres] = useState([]);
    // const [types, setTypes] = useState([]);
    // const [typesAndGenresRequested, setTypesAndGenresRequested] = useState(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    const nm = new NetworkManager();

    useEffect(() => {
        // if ((genres.length === 0 || types.length === 0) && !typesAndGenresRequested) {
        //     nm.getGenres().then((genreList) => {
        //         setGenres(genreList);
        //         return nm.getTypes();
        //     }).then((typeList) => {
        //         setTypesAndGenresRequested(true);
        //         setTypes(typeList);
        //     });
        // }
        if (publishedBooks && publishedBooks.length === 0 && !publishedBooksRequested) {
            nm.getPublishedBooks({ user: state.user }).then((books) => {
                setPublishedBooksRequested(true);
                setPublishedBooks(books);
            });
        }
    });

    const handlePublish = () => {
        if (state.user.subscription >= 2) {
            setShowPublishModal(true);
        } else {
            setShowSubscriptionModal(true);
        }
    };

    return (
        <Container>
            <Helmet><title>Publish</title></Helmet>
            {showPublishModal && ReactDOM.createPortal(
                <Modal mWidth="550" mHeight="450">
                    <Form>
                        <InputField label="Title" placeholder="Title" type="text" onChange={setTitle} value={title} />
                        <TextArea label="Synopsis" placeholder="Synopsis" type="text" onChange={setSynopsis} value={synopsis} />
                        <TextArea label="What would you like to see on your book's cover?" placeholder="Book cover description" type="text" onChange={setBookCover} value={bookCover} />
                        <RToggle
                            htmlFor="adult-toggle"
                            text="Does your book contain content that is for people over the age of 18?"
                            checked={adult}
                            onToggle={() => setAdult(!adult)}
                        />
                        <SecondaryButton onClick={() => setShowPublishModal(false)}>Cancel</SecondaryButton>
                        <PublishButton onClick={() => setShowPublishModal(false)}>Publish book</PublishButton>
                    </Form>
                </Modal>,
                document.getElementById('root')
            )}
            {showSubscriptionModal && ReactDOM.createPortal(
                <SubscriptionModal close={() => setShowSubscriptionModal(false)} mWidth="400" subscriptionLevel={2} />,
                document.getElementById('root')
            )}
            <StyledHeadingContainer>
                <Heading>Publish</Heading>
            </StyledHeadingContainer>
            <GameTile
                name='Publish your book'
                description='Publish your book on Amazon Kindle, Apple Books, Google Play, and in Paperback.'
                color='#1c65d2'
                customButton={<StyledButton onClick={() => handlePublish()} type="button">Publish book</StyledButton>}
                disableLink
                imageUrl={Image}
            />
            {publishedBooks && publishedBooks.length > 0 && publishedBooks.map((publishedBook) => <PublishedBook key={publishedBook.id} {...publishedBook} />)}
        </Container>
    );
};

export default Publish;
