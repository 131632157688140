class Post {
    id = null;
    title = null;
    content = null;
    preview = null;
    creationDate = null;
    genre = null;
    type = null;
    readTime = null;
    readers = [];
    annotations = [];
    authorId = null;
    authorPenName = null;
    authorSubscription = null;
    authorPicture = null;
    audioUrl = null;
    game = null;
    richContent = [];
    bookId = null;
    bookTitle = null;
    bookPosition = null;
    likes = [];
    bookmarks = [];
    version = null;
    groupId = null;
    gameType = null;
    isPublic = null;

    constructor({
        id,
        title, 
        content,
        preview,
        creationDate,
        genre,
        type,
        readTime,
        readers,
        annotations,
        authorId,
        authorPenName,
        authorSubscription,
        authorPicture,
        audioUrl,
        game,
        richContent,
        bookId,
        bookTitle,
        bookPosition,
        likes,
        bookmarks,
        version,
        groupId,
        gameType,
        public: isPublic
    }) {
        this.id = id;
        this.title = title;
        this.content = content;
        this.preview = preview;
        this.creationDate = creationDate;
        this.genre = genre;
        this.type = type;
        this.readTime = readTime;
        this.readers = readers;
        this.annotations = annotations;
        this.authorId = authorId;
        this.authorPenName = authorPenName;
        this.authorSubscription = authorSubscription;
        this.authorPicture = authorPicture;
        this.audioUrl = audioUrl;
        this.game = game;
        this.richContent = richContent;
        this.bookId = bookId;
        this.bookTitle = bookTitle;
        this.bookPosition = bookPosition;
        this.likes = likes;
        this.bookmarks = bookmarks;
        this.version = version;
        this.groupId = groupId;
        this.gameType = gameType;
        this.isPublic = isPublic;
    }
};

export default Post;