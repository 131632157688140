import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';
// import ReactGA from 'react-ga';

import { StoreProvider } from "./store";
import Router from './Router';
import { useStore } from './store';
import { setThemeFunction, setGetThemeFunction } from './globalFunctions';

import { GlobalStyle } from './globalStyle';
import { lightTheme, darkTheme } from './themes';

// ReactGA.initialize('UA-000000-01');

mixpanel.init('16e0f35d7777cf646d01def41950ea71');

function App() {
  const [theme, setTheme] = useState(window.localStorage.getItem('theme'));

  useEffect(() => {
    if (theme === undefined) {
      setTheme('light');
      window.localStorage.setItem('theme', 'light');
    } else if (theme === 'dark') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  });

  const toggleTheme = () => {
    if (theme === 'dark') {
      setTheme('light');
      window.localStorage.setItem('theme', 'light');
    } else {
      setTheme('dark');
      window.localStorage.setItem('theme', 'dark');
    }
  };

  setThemeFunction(toggleTheme);
  setGetThemeFunction(() => theme);

  return (
    <StoreProvider>
      <Helmet>
        <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v11.0&appId=294556534223481&autoLogAppEvents=1" nonce="ClBzq6Xw"></script>
        <script>
            {`window.twttr = (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0],t = window.twttr || {};if (d.getElementById(id)) return t;js = d.createElement(s);js.id = id;js.src = "https://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js, fjs);t._e = [];t.ready = function(f) { t._e.push(f);};return t;}(document, "script", "twitter-wjs"));`}
        </script>
      </Helmet>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <>
          <GlobalStyle />
          <Router key="router" />
        </>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
