class Reply {
    id = null;
    creationDate = null;
    message = null;
    authorId = null;
    authorPenName = null;
    authorSubscription = null;
    commentCount = null;
    postId = null;
    likeIds = [];
    likes = [];
    annotationId = null;

    constructor({ id, creationDate, message, authorId, authorPenName, authorSubscription, postId, likeIds, likes, annotationId }) {
        this.id = id;
        this.creationDate = creationDate;
        this.message = message;
        this.authorId = authorId;
        this.authorPenName = authorPenName;
        this.authorSubscription = authorSubscription;
        this.postId = postId;
        this.likeIds = likeIds;
        this.likes = likes;
        this.annotationId = annotationId;
    }
};

export default Reply;