class PublishedBook {
    id = null;
    creationDate = null;
    title = null;
    synopsis = null;
    genre = null;
    type = null;
    authorId = null;
    authorPenName = null;
    authorSubscription = null;
    adult = null;
    backCoverImageUrl = null;
    frontCoverImageUrl = null;
    documentUrl = null;
    state = null;
    rejectionReason = null;
    authorPicture = null;
    coverRejectionReason = null;
    amazonUrl = null;
    appleUrl = null;
    googleUrl = null;

    constructor({ id, creationDate, title, synopsis, genre, type, authorId, authorPenName, authorSubscription, authorPicture, amazonUrl, googleUrl, appleUrl, adult, backCoverImageUrl, frontCoverImageUrl, documentUrl, state, rejectionReason }) {
        this.id = id;
        this.creationDate = creationDate;
        this.title = title;
        this.synopsis = synopsis;
        this.genre = genre;
        this.type = type;
        this.authorId = authorId;
        this.authorPenName = authorPenName;
        this.authorSubscription = authorSubscription;
        this.adult = adult;
        this.backCoverImageUrl = backCoverImageUrl;
        this.frontCoverImageUrl = frontCoverImageUrl;
        this.documentUrl = documentUrl;
        this.state = state;
        this.rejectionReason = rejectionReason;
        this.authorPicture = authorPicture;
        this.googleUrl = googleUrl;
        this.appleUrl = appleUrl;
        this.amazonUrl = amazonUrl;
    }
};

export default PublishedBook;