import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useTrail } from 'react-spring';
import { Helmet } from 'react-helmet';

import NetworkManager from '../classes/NetworkManager';
import { useStore } from '../store';
import { setTheme, getTheme } from '../globalFunctions';

import { Container, HeadingContainer, Heading, Button, SecondaryButton } from '../components/styledComponents';
import LargeAvatar from '../components/largeAvatar';
import CondensedPost from '../components/condensedPost';
import CondensedBook from '../components/condensedBook';
import Toggle, { ToggleItem, RToggle } from '../components/toggle';
import Icon from '../components/icon';
import Modal from '../components/modal';
import InputField from '../components/inputField';
import TextArea from '../components/textArea';
import Follow from '../components/follow';
import Illustration from '../components/illustration';
import Avatar from '../components/avatar';

const StyledHeadingContainer = styled(HeadingContainer)`
    display: block;
    position: relative;
    margin-top: ${({ cover }) => cover ? '-6rem' : '0'};
`;

const StyledHeading = styled(Heading)`
    margin-top: 30px;
`;

const Subheading = styled.p`
    margin-top: 20px;
    font-size: 18px;
    line-height: 1.5;

    @media (max-width: 700px) {
        font-size: 16px;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    padding: 0;
    align-items: center;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const FollowersFollowing = styled.p`
    color: var(--accent);
    font-weight: 600;
    display: inline-block;
    margin-left: 30px;
    cursor: pointer;

    &:first-of-type {
        margin-left: 0;
    }

    @media (max-width: 700px) {
        &:first-of-type {
            margin-left: 0;
        }
    }
`;

const ControlContainer = styled.div`
    position: absolute;
    right: 0;
    top: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;

    img {
        margin-right: 20px;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

const SettingsForm = styled.form`
    padding: 32px;

    @media (max-width: 700px) {
        padding: 20px
    }
`;

const Logout = styled.div`
    padding: 32px;

    @media (max-width: 700px) {
        padding: 20px
    }
`;

const SettingsButton = styled(Button)`
    margin-top: 20px;
`;

const FollowingContainer = styled.div`
    display: flex;

    @media (max-width: 700px) {
        margin-top: 20px;
        margin-left: 0;
        justify-content: flex-start;
    }
`;

const FollowContainer = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;

    & > button {
        margin-right: 30px !important;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ManageSubscription = styled.p`
    font-weight: 600;
    color: var(--accent);
    text-decoration: none;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    align-items: center;

    img {
        margin-left: 10px;
    }
`;

const EditProfileAvatarContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const EditPicture = styled.p`
    font-weight: 600;
    color: var(--accent);
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;

    img {
        margin-right: 6px;
    }
`;

const RemovePicture = styled.p`
    font-weight: 600;
    color: red;
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;

    img {
        margin-right: 6px;
    }
`;

const AvatarContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

const PlusTag = styled.p`
    padding: .4rem 1rem;
    background-color: var(--subscribe);
    color: var(--white);
    font-weight: 600;
    border-radius: .5rem;
    margin-left: 1rem;
`;

const ProTag = styled.p`
    padding: .4rem 1rem;
    background-color: var(--subscribe-pro);
    color: var(--white);
    font-weight: 600;
    border-radius: .5rem;
    margin-left: 1rem;
`;

const CoverImage = styled.img`
    border-radius: 1rem;
    max-height: 10rem;
    width: 110%;
    object-fit: cover;
    margin-top: 1rem;
    margin-left: -5%;

    @media (max-width: 700px) {
        border-radius: 0;
        margin-top: 0;
    }
`;

const Profile = () => {
    const history = useHistory();
    const { authorId } = useParams();
    const { state, dispatch } = useStore();
    const [profileAuthor, setProfileAuthor] = useState({});
    const [profileChapters, setProfileChapters] = useState([]);
    const [profileBooks, setProfileBooks] = useState([]);
    const [activeToggle, setActiveToggle] = useState('chapters');
    const [profileAuthorRequested, setProfileAuthorRequested] = useState(false);
    const [profileChaptersRequested, setProfileChaptersRequested] = useState(false);
    const [profileBooksRequested, setProfileBooksRequested] = useState(false);

    const [showSettings, setShowSettings] = useState(false);
    const [showLogout, setShowLogout] = useState(false);
    const [newPenName, setNewPenName] = useState('');
    const [newBio, setNewBio] = useState('');
    const [uploadedPicture, setUploadedPicture] = useState(null);
    const [uploadedPictureUrl, setUploadedPictureUrl] = useState('');
    const [initSettings, setInitSettings] = useState(false);

    const pictureUpload = React.createRef();
    const nm = new NetworkManager();
    const reader = new FileReader();

    const config = { mass: 1, tension: 120, friction: 14 };
    const postTrail = useTrail(profileChapters.length, {
        config,
        from: { opacity: 0, y: '100%' },
        to: { opacity: 1, y: '0%' }
    });
    const bookTrail = useTrail(profileBooks.length, {
        config,
        from: { opacity: 0, y: '100%' },
        to: { opacity: 1, y: '0%' }
    });

    const handlePictureUpload = (e) => {
        setUploadedPicture(e.target.files[0]);
    };

    useEffect(() => {
        if ((!profileAuthor.id && !profileAuthorRequested) || (profileAuthor.id && profileAuthor.id != authorId)) {
            nm.getAuthor(authorId).then((author) => {
                setProfileAuthorRequested(true);
                setProfileAuthor(author);
            }).catch(() => {
                history.push('/404');
            });
        }
        if ((profileChapters.length === 0 && !profileChaptersRequested) || (profileAuthor.id && profileAuthor.id != authorId)) {
            nm.getPosts({ user: profileAuthor }).then((postList) => {
                setProfileChaptersRequested(true);
                setProfileChapters(postList || []);
            });
        }
        if ((activeToggle === 'books' && profileBooks.length === 0 && !profileBooksRequested) || (profileAuthor.id && profileAuthor.id != authorId)) {
            nm.getBooks({ user: profileAuthor }).then((bookList) => {
                setProfileBooksRequested(true);
                setProfileBooks(bookList || []);
            });
        }
        if (!initSettings && state.user) {
            setNewPenName(state.user.penName);
            setNewBio(state.user.bio);
            setInitSettings(true);
        }
        if (pictureUpload.current) {
            pictureUpload.current.addEventListener('change', handlePictureUpload);
            reader.addEventListener('load', function () {
                // convert image file to base64 string
                setUploadedPictureUrl(reader.result);
            }, false);
        }
        if (uploadedPicture) {
            reader.readAsDataURL(uploadedPicture);
        }

        return () => {
            if (pictureUpload.current) {
                pictureUpload.current.removeEventListener('change', handlePictureUpload);
            }
        };
    });

    const toggleTheme = (e) => {
        setTheme(getTheme() === 'light' ? 'dark' : 'light');
    };

    const handleEditPicture = (e) => {
        e.preventDefault();
        pictureUpload.current.click();
    }

    const handleProfileChanges = (e) => {
        e.preventDefault();
        if (profileAuthor.bio !== newBio || profileAuthor.penName !== newPenName || uploadedPicture) {
            nm.updateProfileDetails({
                bio: newBio,
                penName: newPenName,
                picture: uploadedPicture
            }, state.user)
            .then(() => {
                setProfileAuthor({});
                setProfileAuthorRequested(false);
                setShowSettings(false);
            });
        } else {
            setShowSettings(false);
        }
    };

    return (
        <Container>
            { profileAuthor && <Helmet><title>{profileAuthor.penName}</title></Helmet> }
            {state.user && showSettings && ReactDOM.createPortal(
                <Modal mWidth="500" mHeight="650">
                    <SettingsForm>
                        <EditProfileAvatarContainer>
                            <Avatar initial={state.user.penName.split('')[0]} picture={uploadedPictureUrl || state.user.picture} disableLink />
                            <input type="file" name={state.user.id} ref={pictureUpload} hidden />
                            <EditPicture onClick={handleEditPicture}><Icon icon="editPicture" size={20} />{state.user.picture || uploadedPictureUrl ? 'Change' : 'Add'} picture</EditPicture>
                            {/* { (uploadedPictureUrl || state.user.picture) && <RemovePicture onClick={() => {
                                setUploadedPicture(null);
                                setUploadedPictureUrl('');
                            }}><Icon icon="delete" />Remove picture</RemovePicture> } */}
                        </EditProfileAvatarContainer>
                        <InputField label="Pen name" placeholder="Enter a pen name" type="text" onChange={setNewPenName} value={newPenName} />
                        <TextArea label="Bio" placeholder="Enter a bio" type="text" onChange={setNewBio} value={newBio} />
                        <RToggle
                            htmlFor="dark-mode-toggle"
                            text="Dark mode"
                            checked={getTheme() === 'dark'}
                            onToggle={toggleTheme}
                        />
                        { state && state.user.subscription > 0 && <ManageSubscription onClick={nm.redirectToCustomerPortal}>Manage subscription <Icon icon="externalLink" /></ManageSubscription> }
                        <SecondaryButton onClick={() => setShowSettings(false)}>Cancel</SecondaryButton>
                        <SettingsButton onClick={handleProfileChanges}>Save changes</SettingsButton>
                    </SettingsForm>
                </Modal>,
                document.getElementById('root')
            )}
            {state.user && showLogout && ReactDOM.createPortal(
                <Modal mWidth="400" mHeight="450">
                    <Logout>
                        <p>Are you sure you want to sign out?</p>
                        <SecondaryButton onClick={() => setShowLogout(false)}>Cancel</SecondaryButton>
                        <SettingsButton onClick={() => {
                            setShowLogout(false);
                            nm.logout(state.user.id).then(() => {
                                history.push(`/signin`)
                            });
                        }}>Sign out</SettingsButton>
                    </Logout>
                </Modal>,
                document.getElementById('root')
            )}
            { profileAuthor.subscription === 2 && <CoverImage src={profileAuthor.coverImage} /> }
            { profileAuthor && profileAuthor.id && <StyledHeadingContainer cover={profileAuthor.coverImage}>
                <AvatarContainer>
                    <LargeAvatar {...profileAuthor} />
                    { profileAuthor.subscription === 1 && <PlusTag>Plus</PlusTag> }
                    { profileAuthor.subscription === 2 && <ProTag>Pro</ProTag> }
                </AvatarContainer>
                <StyledHeading>{profileAuthor.penName}</StyledHeading>
                <Subheading>{profileAuthor.bio}</Subheading>
                { state.user && state.user.id === authorId && <ControlContainer>
                    <Icon icon="cog" size={30} onClick={() => {
                        setNewPenName(profileAuthor.penName);
                        setNewBio(profileAuthor.bio);
                        setShowSettings(true);
                    }} />
                    <Icon icon="signout" size={26} onClick={() => setShowLogout(true)} />
                </ControlContainer> }
                <FollowContainer>
                    { state.user && state.user.id !== authorId && <Follow authorId={authorId} authorPenName={profileAuthor.penName} /> }
                    { profileAuthor.id && <FollowingContainer>
                        <FollowersFollowing onClick={() => history.push(`/followers/${profileAuthor.id}`)}>{profileAuthor.followers.length} followers</FollowersFollowing>
                        <FollowersFollowing onClick={() => history.push(`/following/${profileAuthor.id}`)}>{profileAuthor.following.length} following</FollowersFollowing>
                    </FollowingContainer> }
                </FollowContainer>
            </StyledHeadingContainer> }
            <FiltersContainer>
                <Toggle>
                    <ToggleItem active={activeToggle === 'chapters'} onClick={() => setActiveToggle('chapters')}>Chapters</ToggleItem>
                    <ToggleItem active={activeToggle === 'books'} onClick={() => setActiveToggle('books')}>Books</ToggleItem>
                </Toggle>
            </FiltersContainer>
            {activeToggle === 'chapters' && profileChapters.length > 0 && postTrail.map(({ ...styleProps }, i) => <CondensedPost key={profileChapters[i].id} {...profileChapters[i]} {...styleProps} />)}
            {activeToggle === 'chapters' && profileChapters && profileChapters.length === 0 && profileChaptersRequested && <Illustration illustration="review" title="No writing" text="This user hasn't added any writing yet." />}
            {activeToggle === 'books' && profileBooks.length > 0 && bookTrail.map(({ ...styleProps }, i) => <CondensedBook key={profileBooks[i].id} {...profileBooks[i]} {...styleProps} clickable />)}
            {activeToggle === 'books' && profileBooks && profileBooks.length === 0 && <Illustration illustration="bookmarks" title="No books" text="This user hasn't added any books yet." />}
        </Container>
    );
};

export default Profile;
